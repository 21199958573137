/* eslint-disable no-unused-vars */
import { Avatar, Button, Grid, Typography } from '@mui/material';
import { useFollowerEducatorsQuery } from '../../app/services/UserApi';
import DataLoader from '../../components/DataLoader';
import { CastForEducation, People, Reviews, Star } from '@mui/icons-material';

function EducatorFollower() {
  const { data, isLoading, isSuccess } = useFollowerEducatorsQuery();
  const displayEducatorAvgCourseRating = (courses) => {
    let avgRating = 5;
    let noOfRatings = 1;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        avgRating += Number(singleCourse.course_reviews_avg_rating);
        noOfRatings += Number(singleCourse.course_reviews_avg_rating) ? 1 : 0;
      });
      return Math.round((avgRating / noOfRatings) * 10) / 10;

      // return avgRating / noOfRatings;
    } else {
      return avgRating;
    }
  };

  const displayEducatorCourseEnrols = (courses) => {
    let enrols = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        enrols += Number(singleCourse.course_enrols_count);
      });
      return enrols;
    } else {
      return enrols;
    }
  };

  const displayReviewCounts = (courses) => {
    let reviewCounts = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        reviewCounts += Number(singleCourse.course_reviews_count);
      });
      return reviewCounts;
    } else {
      return reviewCounts;
    }
  };
  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  return (
    <Grid container spacing={2}>
      {isSuccess && data.followers.length >= 1 ? (
        data.followers.map((singleFollower) => (
          <Grid item xl="4" lg="4" md="6" sm="12" xs="12" key={singleFollower.id}>
            <Grid container sx={{ background: '#fff' }}>
              <>
                <Grid item xl="4" lg="4" md="12" sm="12" xs="12">
                  <Avatar
                    variant="square"
                    sx={{
                      height: '220px',
                      width: '100%',
                    }}
                    alt={
                      singleFollower.follower_educator?.educator_info?.first_name ||
                      singleFollower.follower_educator?.name
                    }
                    src={
                      singleFollower.follower_educator?.educator_info
                        ?.profile_picture_url
                        ? singleFollower.follower_educator?.educator_info?.image_url
                        : 'https://cdn4.vectorstock.com/i/1000x1000/95/28/cartoon-male-teacher-vector-16659528.jpg'
                    }
                  ></Avatar>
                </Grid>
                <Grid
                  item
                  xl="8"
                  lg="8"
                  md="12"
                  sm="12"
                  xs="12"
                  sx={{ padding: '0.5rem 1rem 0.5rem 1rem' }}
                >
                  <Typography
                    sx={{ fontSize: '28px', fontWeight: 700, color: '#727391' }}
                    className="wrap-text-ellipsis"
                    title={`${
                      singleFollower.follower_educator?.educator_info?.first_name ||
                      singleFollower.follower_educator?.name
                    }${
                      singleFollower.follower_educator?.educator_info?.last_name
                        ? ` ${singleFollower.follower_educator?.educator_info?.last_name}`
                        : null
                    }`}
                  >
                    {' '}
                    {singleFollower.follower_educator?.educator_info?.first_name ||
                      singleFollower.follower_educator?.name}
                    {singleFollower.follower_educator?.educator_info?.last_name
                      ? ` ${singleFollower.follower_educator?.educator_info?.last_name}`
                      : null}
                  </Typography>

                  {/* {singleFollower.follower_educator?.educator_info?.location && (
                    <Typography
                      sx={{
                        fontSize: '16px',
                        fontWeight: 600,
                        color: '#5e7fb1',
                        textTransform: 'capitalize',
                      }}
                    >
                      {singleFollower.follower_educator?.educator_info?.location}
                    </Typography>
                  )} */}
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: 600,
                      color: '#5e7fb1',
                    }}
                  >
                    {singleFollower.follower_educator?.educator_info?.headline ||
                      singleFollower.follower_educator?.educator_info?.subject}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1d1f',
                      marginTop: '0.5rem',
                      display: 'flex',
                      alignItems: 'unset',
                      gap: '0.5rem',
                    }}
                  >
                    <Star sx={{ fontSize: '1rem' }} />
                    {/* 4.6 Instructor Rating */}
                    {singleFollower.follower_educator
                      ?.educator_course_enrols_and_reviews
                      ? displayEducatorAvgCourseRating(
                          singleFollower.follower_educator
                            .educator_course_enrols_and_reviews
                        )
                      : 'No'}
                    &nbsp; Educator Rating
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1d1f',
                      marginTop: '0.2rem',
                      display: 'flex',
                      alignItems: 'unset',
                      gap: '0.5rem',
                    }}
                  >
                    <Reviews sx={{ fontSize: '1rem' }} />
                    {/* 18,534 Reviews */}
                    {singleFollower.follower_educator
                      ?.educator_course_enrols_and_reviews
                      ? displayReviewCounts(
                          singleFollower.follower_educator
                            ?.educator_course_enrols_and_reviews
                        )
                      : 'No'}
                    &nbsp;Reviews
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1d1f',
                      marginTop: '0.2rem',
                      display: 'flex',
                      alignItems: 'unset',
                      gap: '0.5rem',
                    }}
                  >
                    <People sx={{ fontSize: '1rem' }} />
                    {/* 115,904 Students */}
                    {singleFollower.follower_educator
                      ?.educator_course_enrols_and_reviews
                      ? displayEducatorCourseEnrols(
                          singleFollower.follower_educator
                            ?.educator_course_enrols_and_reviews
                        )
                      : 'No'}
                    &nbsp;Students
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: '#1c1d1f',
                      marginTop: '0.2rem',
                      display: 'flex',
                      alignItems: 'unset',
                      gap: '0.5rem',
                    }}
                  >
                    <CastForEducation sx={{ fontSize: '1rem' }} />
                    {/* 13 Courses */}
                    {Array.isArray(
                      singleFollower.follower_educator
                        ?.educator_course_enrols_and_reviews
                    )
                      ? singleFollower.follower_educator
                          ?.educator_course_enrols_and_reviews?.length
                      : 'No'}
                    &nbsp;Courses
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '11px',
                      fontWeight: 500,
                      color: '#1c1d1f',
                      marginTop: '0.5rem',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 3,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    title={singleFollower?.follower_educator?.educator_info?.bio}
                  >
                    {singleFollower?.follower_educator?.educator_info?.bio}
                  </Typography>
                </Grid>
              </>{' '}
            </Grid>
          </Grid>
        ))
      ) : (
        <Typography>No followers</Typography>
      )}
    </Grid>
  );
}

export default EducatorFollower;
