// /* eslint-disable no-unused-vars */
// import { Typography } from '@mui/material';
// import * as React from 'react';
// import Tabs from '@mui/material/Tabs';
// import Tab from '@mui/material/Tab';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import { useParams } from 'react-router';
// import { useEnroledCourseQuery } from '../../../app/services/StudentCoursesApi';
// import EnrolledCourseTabPanel from './EnrolledCourseTabPanel';
// import moment from 'moment';
// import DisplayCourseReview from './DisplayCourseReview';
// import CourseReview from './CourseReview';
// import DisplayEnroledCourseCurriculum from './DisplayEnroledCourseCurriculum';
// import DisplayEnroledCourseVideos from './DisplayEnroledCourseVideos';
// import ShakaPlayer from 'shaka-player-react';
// import 'shaka-player/dist/controls.css';
// import { useGetEnroledVideoUrlQuery } from '../../../app/services/StudentCoursesApi';

/* eslint-disable no-unused-vars */
import {
  DialogTitle,
  Avatar,
  Button,
  Dialog,
  IconButton,
  Paper,
  DialogContent,
  Typography,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useParams, useNavigate } from 'react-router';
import {
  useCreateVideoLikeUnlikeMutation,
  useEnroledCourseQuery,
  useFollowUserMutation,
  useGetEnroledVideoUrlQuery,
  useUnFollowCreatorUserMutation,
  useUpdateVideoLikeUnlikeMutation,
} from '../../../app/services/StudentCoursesApi';
import EnrolledCourseTabPanel from './EnrolledCourseTabPanel';
import moment from 'moment';
import DisplayCourseReview from './DisplayCourseReview';
import CourseReview from './CourseReview';
import DisplayEnroledCourseCurriculum from './DisplayEnroledCourseCurriculum';
import DisplayEnroledCourseVideos from './DisplayEnroledCourseVideos';

import DisplayVideoComments from './DisplayVideoComments';
import HlsJs from '../HlsJs';
import DataLoader from '../../../components/DataLoader';
import {
  RepeatOnSharp,
  ReportProblemSharp,
  ShareSharp,
  ThumbDown,
  ThumbUpSharp,
} from '@mui/icons-material';
import ReportVideoViolation from './ReportVideoViolation';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ShareButton from '../ShareButton';
function DisplayEnroledSingleCourseVideo() {
  const params = useParams();

  const { data, isLoading, isSuccess } = useGetEnroledVideoUrlQuery({
    id: parseInt(params.Id, 10),
    videoId: parseInt(params.videoId, 10),
  });

  const [value, setValue] = React.useState(0);
  const [followUser, followResult] = useFollowUserMutation();
  const [unFollowUser, unFollowResult] = useUnFollowCreatorUserMutation();
  const [createLikeUnlike, result] = useCreateVideoLikeUnlikeMutation();
  const [updateLikeUnlike, result1] = useUpdateVideoLikeUnlikeMutation();

  const [delayPlayer, setDelayPlayer] = React.useState(true);
  const theme = useTheme();
  const navigate = useNavigate();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  React.useEffect(() => {
    setDelayPlayer(true);
    setTimeout(() => {
      setDelayPlayer(false);
    }, 1000);
  }, [params.videoId]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const onFollowClick = (id) => {
    const res = followUser({ id });
  };

  const onUnFollowClick = (id) => {
    const res = unFollowUser({ id });
  };

  const setSelected = () => {
    if (data?.video?.subject_id) {
      return { subjectId: data.video.subject_id };
    }

    if (data?.video?.topic_id) {
      return { topicId: data.video.topic_id };
    }

    if (data?.video?.sub_topic_id) {
      return { subTopicId: data.video.sub_topic_id };
    }
  };

  const findVideoProgress = (videoId, videoProgressArr) => {
    const found =
      videoProgressArr &&
      videoProgressArr.find((singleProgress) => singleProgress.vid === videoId);
    if (found) {
      return found;
    } else {
      return { vid: videoId, timeStamp: 0 };
    }
  };

  const handleClickLikeUnlike = (like, unlike) => {
    const likedData = {
      liked: like,
      unliked: unlike,
      video_id: data?.video?.id,
    };
    createLikeUnlike(likedData);
  };

  const handleUpdateLikeUnLike = (id, like, unlike) => {
    const unlikedData = {
      id: id,
      liked: like,
      unliked: unlike,
      video_id: data?.video?.id,
    };
    updateLikeUnlike(unlikedData);
  };

  return isSuccess && data.enrol ? (
    <>
      <Grid
        container
        spacing={2}
        sx={{
          // bgcolor: 'background.paper',
          overflowWrap: 'anywhere',
        }}
      >
        <Grid item xs="12" sm="12" md="12" lg="9" xl="9" align="left">
          <Grid container>
            <Grid item xs="0" sm="0" md="1" lg="1" xl="1" align="left"></Grid>
            <Grid item xs="12" sm="12" md="10" lg="10" xl="10" align="left">
              <Box>
                {/* {data.video ? <HlsJs vidSrc={data.video.vid_url} /> : null} */}
                {data.video && !delayPlayer ? (
                  <HlsJs
                    vidSrc={data.video.vid_url}
                    enrolId={data.enrol.id}
                    vidId={data.video.id}
                    vidProgress={data.enrol.video_progress || []}
                    paramId={parseInt(params.videoId, 10)}
                    currentVidProgress={findVideoProgress(
                      data.video.id,
                      data.enrol.video_progress
                    )}
                    maxVideoHeight={'500px'}
                  />
                ) : null}
                <div>
                  <p
                    style={{
                      fontSize: '14px',
                      fontWeight: '700',
                      margin: '0.5rem 0rem 0.5rem 0rem',
                    }}
                  >
                    {data?.video?.title}
                  </p>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <div>
                      <span>
                        {data?.video?.status === 'PUBLISHED'
                          ? `Premiered at ${
                              data?.video?.created_at &&
                              new Date(data?.video?.created_at).toLocaleDateString(
                                'en-US',
                                { year: 'numeric', month: 'short', day: 'numeric' }
                              )
                            }`
                          : ''}
                      </span>
                    </div>
                    <div>
                      <IconButton
                        color={
                          data?.video?.likeUnlike?.liked ? 'primary' : 'secondary'
                        }
                        aria-label="add to shopping cart"
                        onClick={() =>
                          data?.video?.likeUnlike
                            ? handleUpdateLikeUnLike(
                                data?.video?.likeUnlike?.id,
                                true,
                                false
                              )
                            : handleClickLikeUnlike(true, false)
                        }
                        disabled={data?.video?.likeUnlike?.liked}
                      >
                        <ThumbUpSharp />
                        {data?.video?.likes_count}
                      </IconButton>
                      <span style={{ fontSize: '14px' }}>Like</span>
                      <IconButton
                        color={
                          data?.video?.likeUnlike?.unliked ? 'primary' : 'secondary'
                        }
                        aria-label="add to shopping cart"
                        onClick={() =>
                          data?.video?.likeUnlike
                            ? handleUpdateLikeUnLike(
                                data?.video?.likeUnlike?.id,
                                false,
                                true
                              )
                            : handleClickLikeUnlike(false, true)
                        }
                        disabled={data?.video?.likeUnlike?.unliked}
                      >
                        <ThumbDown />
                        {data?.video?.unlikes_count}
                      </IconButton>
                      <span style={{ fontSize: '14px' }}>DisLike</span>
                      {/* <IconButton color="primary" aria-label="add to shopping cart">
                        <ShareSharp />
                      </IconButton>
                      <span style={{ fontSize: '14px' }}>Share</span> */}
                      <ShareButton
                        course={data?.enrol?.course}
                        // url="https://example.com/your-content"
                      />
                      <IconButton
                        color="error"
                        aria-label="add to shopping cart"
                        onClick={() => handleClickOpen()}
                      >
                        <ReportProblemSharp />
                      </IconButton>
                      <span style={{ fontSize: '14px' }}>Report Abuse</span>
                    </div>
                  </div>
                </div>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                  variant={window.screen.width < 600 ? `scrollable` : `standard`}
                >
                  <Tab
                    id="one"
                    label="About Educator"
                    {...a11yProps(0)}
                    sx={{
                      justifyContent: 'flex-start',
                      fontSize: 14,
                      alignItems: 'flex-start',
                      textAlign: 'start',
                      textTransform: 'capitalize',
                    }}
                  ></Tab>
                  <Tab
                    id="two"
                    label="Course Overview"
                    {...a11yProps(1)}
                    sx={{
                      justifyContent: 'flex-start',
                      fontSize: 14,
                      alignItems: 'flex-start',
                      textAlign: 'start',
                      textTransform: 'capitalize',
                    }}
                  ></Tab>
                  <Tab
                    id="three"
                    label="Q&A"
                    {...a11yProps(2)}
                    sx={{
                      justifyContent: 'flex-start',
                      fontSize: 14,
                      alignItems: 'flex-start',
                      textAlign: 'start',
                      textTransform: 'capitalize',
                    }}
                  ></Tab>
                  <Tab
                    id="four"
                    label="Announcements"
                    {...a11yProps(3)}
                    disabled
                    sx={{
                      justifyContent: 'flex-start',
                      fontSize: 14,
                      alignItems: 'flex-start',
                      textAlign: 'start',
                      textTransform: 'capitalize',
                    }}
                  ></Tab>
                  <Tab
                    id="five"
                    label="Course Downloads"
                    {...a11yProps(4)}
                    disabled
                    sx={{
                      justifyContent: 'flex-start',
                      fontSize: 14,
                      alignItems: 'flex-start',
                      textAlign: 'start',
                      textTransform: 'capitalize',
                    }}
                  ></Tab>
                  <Tab
                    id="six"
                    label="Rate and Review"
                    {...a11yProps(4)}
                    sx={{
                      justifyContent: 'flex-start',
                      fontSize: 14,
                      alignItems: 'flex-start',
                      textAlign: 'start',
                      textTransform: 'capitalize',
                    }}
                  ></Tab>
                </Tabs>
                <EnrolledCourseTabPanel value={value} index={0}>
                  <Paper
                    sx={{
                      my: 1,
                      mx: 'auto',
                      p: 2,
                    }}
                  >
                    <Grid container wrap="nowrap" spacing={2}>
                      <Grid item>
                        <Avatar
                          sx={{ height: '80px', width: '80px' }}
                          alt={data.enrol.course.creator.name
                            .charAt(0)
                            .toUpperCase()}
                          src={
                            data.enrol.course.creator?.educator_info?.image_url
                              ? data.enrol.course.creator.educator_info?.image_url
                              : null
                          }
                        >
                          {data.enrol.course.creator.name.charAt(0).toUpperCase()}
                        </Avatar>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          sx={{ fontSize: '18px', fontWeight: 600 }}
                          className="first-letter-cap"
                        >
                          <strong
                            onClick={() => {
                              const courseCreaterId =
                                data?.enrol?.course?.creator?.id;
                              courseCreaterId &&
                                navigate(`/educator/channel/${courseCreaterId}`);
                            }}
                            style={{ cursor: 'pointer', color: '#ee3264' }}
                          >
                            {`${data?.enrol?.course?.creator?.educator_info?.first_name} ${data?.enrol?.course?.creator?.educator_info?.last_name}`}
                          </strong>
                        </Typography>{' '}
                        {data.enrol.course.creator.educator_info ? (
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: '16px', fontWeight: 600 }}
                          >
                            {data.enrol.course.creator.educator_info?.field}
                            &nbsp;|&nbsp;
                            {data.enrol.course.creator.educator_info?.subject}{' '}
                          </Typography>
                        ) : null}{' '}
                        {data.enrol.course.institution_info ? (
                          <Typography
                            variant="subtitle2"
                            sx={{ fontSize: '16px', fontWeight: 600 }}
                          >
                            {data.enrol.course.institution_info.name}{' '}
                          </Typography>
                        ) : null}
                        <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                          {data.enrol.totalFollowers} Followers
                        </Typography>
                        <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                          {data.enrol.course.creator.educator_info?.bio}
                        </Typography>
                      </Grid>
                      <Grid
                        item
                        sx={{
                          justifyContent: 'right',
                          display: 'block',
                        }}
                      >
                        {data.enrol.alreadyFollowing ? (
                          <Button
                            variant="contained"
                            onClick={() =>
                              onUnFollowClick(data.enrol.course.creator.id)
                            }
                            sx={{
                              textTransform: 'capitalize',
                              borderRadius: '30px',
                              minWidth: '93px',
                            }}
                          >
                            UnFollow
                          </Button>
                        ) : (
                          <Button
                            variant="contained"
                            onClick={() =>
                              onFollowClick(data.enrol.course.creator.id)
                            }
                            sx={{
                              textTransform: 'capitalize',
                              borderRadius: '30px',
                              minWidth: '93px',
                            }}
                          >
                            Follow
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </EnrolledCourseTabPanel>
                <EnrolledCourseTabPanel value={value} index={1}>
                  <Paper
                    sx={{
                      my: 1,
                      mx: 'auto',
                      p: 2,
                    }}
                  >
                    <Typography component="h2">
                      <strong>{data.enrol.course.title}</strong>
                    </Typography>

                    <Typography variant="subtitle2">
                      In&nbsp;
                      {data.enrol.course.language}
                    </Typography>
                    <Typography variant="subtitle1" paragraph>
                      {data.enrol.course.description}
                    </Typography>
                  </Paper>
                </EnrolledCourseTabPanel>

                <EnrolledCourseTabPanel value={value} index={2}>
                  <DisplayVideoComments
                    comments={data?.video?.comments}
                    video_id={data?.video?.id}
                  />
                </EnrolledCourseTabPanel>
                <EnrolledCourseTabPanel
                  value={value}
                  index={3}
                ></EnrolledCourseTabPanel>
                <EnrolledCourseTabPanel value={value} index={4}>
                  Course Downloads
                </EnrolledCourseTabPanel>
                <EnrolledCourseTabPanel value={value} index={5}>
                  {data.enrol.courseReview ? (
                    <DisplayCourseReview courseReview={data.enrol.courseReview} />
                  ) : (
                    <CourseReview course_id={data.enrol.course_id} />
                  )}
                </EnrolledCourseTabPanel>
              </Box>
            </Grid>
            <Grid item xs="0" sm="0" md="1" lg="1" xl="1" align="left"></Grid>
          </Grid>
        </Grid>{' '}
        <Grid
          item
          xs="12"
          sm="12"
          md="12"
          lg="3"
          xl="3"
          align="left"
          sx={{
            // boxShadow: '-5px 0 5px #ccc',
            maxHeight: '100vh',
            // ...(matches && { position: 'fixed', right: 0 }),
          }}
        >
          {/* <Box mt={2} textAlign="center"> */}
          <Box
            sx={{
              paddingRight: '0.5rem',
              overflow: 'auto',
              maxHeight: 'calc(100vh - 200px)',
              boxShadow: '-5px 0 5px #ccc',
              position: 'sticky',
              top: '100px',
            }}
          >
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ borderBottom: '1px solid', padding: '0.5rem' }}
            >
              <span style={{ fontSize: '16px', fontWeight: 700, color: '#000' }}>
                Topics{' '}
              </span>
              <span
                style={{
                  fontSize: '16px',
                  fontWeight: 700,
                  float: 'right',
                  color: '#000',
                }}
              >
                {Array.isArray(data.enrol.course.subjects)
                  ? data.enrol.course.subjects.length
                  : 0}
              </span>
            </Typography>{' '}
            {data?.enrol?.course?.subjects.length ? (
              <DisplayEnroledCourseCurriculum
                subjects={data.enrol.course.subjects}
                enrolId={data.enrol.id}
                selectedIndex={setSelected()}
                progressData={data.enrol.video_progress}
              />
            ) : null}
          </Box>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Report Abuse</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Flagged content is reviewed by {`${process.env.REACT_APP_NAME}`} staff to
            determine whether it violates Terms of Service or Community Guidelines.
            If you have a question or technical issue, please contact our Support
            team here.
          </DialogContentText>
          <ReportVideoViolation video_id={data?.video?.id} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <Typography>Enroled Course Not Found</Typography>
  );
}

export default DisplayEnroledSingleCourseVideo;
