/* eslint-disable no-unused-vars */
import { Avatar, Button, Paper, Typography } from '@mui/material';
import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useParams, useNavigate } from 'react-router';
import {
  useEnroledCourseQuery,
  useFollowUserMutation,
  useUnFollowCreatorUserMutation,
} from '../../../app/services/StudentCoursesApi';
import EnrolledCourseTabPanel from './EnrolledCourseTabPanel';
import DisplayCourseReview from './DisplayCourseReview';
import CourseReview from './CourseReview';
import DisplayEnroledCourseCurriculum from './DisplayEnroledCourseCurriculum';
import DisplayEnroledCourseVideos from './DisplayEnroledCourseVideos';
import DataLoader from '../../../components/DataLoader';
import { Navigate } from 'react-router-dom';

function EnroledCourse() {
  const params = useParams();

  //   const { user, isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess } = useEnroledCourseQuery({
    id: parseInt(params.enrolId, 10),
  });
  const [value, setValue] = React.useState(0);
  const [followUser, followResult] = useFollowUserMutation();
  const [unFollowUser, unFollowResult] = useUnFollowCreatorUserMutation();
  const navigate = useNavigate();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const onFollowClick = (id) => {
    const res = followUser({ id });
  };

  const onUnFollowClick = (id) => {
    const res = unFollowUser({ id });
  };

  if (
    isSuccess &&
    data.enrol &&
    data.enrol.video_progress &&
    data.enrol.video_progress.length >= 1
  ) {
    return (
      <Navigate
        replace
        // to={`course/video/${data.enrol.video_progress.length - 1}`}
        to={`/enrols/${data.enrol.id}/video/${
          data.enrol.video_progress[data.enrol.video_progress.length - 1].vid
        }`}
      />
    );
  }
  return isSuccess && data.enrol && !data.enrol.video_progress ? (
    <Grid
      container
      spacing={2}
      sx={{
        // bgcolor: 'background.paper',
        overflowWrap: 'anywhere',
      }}
    >
      <Grid item xs="12" sm="12" md="8" lg="8" xl="8" align="left">
        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="secondary"
            indicatorColor="secondary"
            aria-label="secondary tabs example"
            variant={window.screen.width < 600 ? `scrollable` : `standard`}
          >
            {/* <Tab
              id="one"
              label="Introduction Videos"
              {...a11yProps(0)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab> */}
            <Tab
              id="one"
              label="About Educator"
              {...a11yProps(1)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
            <Tab
              id="two"
              label="Course Overview"
              {...a11yProps(2)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
            <Tab
              id="three"
              label="Announcements"
              {...a11yProps(3)}
              disabled
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
            <Tab
              id="four"
              label="Course Downloads"
              {...a11yProps(4)}
              disabled
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
            <Tab
              id="five"
              label="Rate and Review"
              {...a11yProps(4)}
              sx={{
                justifyContent: 'flex-start',
                fontSize: 14,
                alignItems: 'flex-start',
                textAlign: 'start',
                textTransform: 'capitalize',
              }}
            ></Tab>
          </Tabs>

          {/* <EnrolledCourseTabPanel value={value} index={0}>
            <DisplayEnroledCourseVideos
              video={data.enrol.course.published_video}
              id={data.enrol.id}
              progressData={data.enrol.video_progress}
            />
          </EnrolledCourseTabPanel> */}
          <EnrolledCourseTabPanel value={value} index={0}>
            <Paper
              sx={{
                my: 1,
                mx: 'auto',
                p: 2,
              }}
            >
              <Grid container wrap="nowrap" spacing={2}>
                <Grid item>
                  <Avatar
                    sx={{ height: '80px', width: '80px' }}
                    alt={data.enrol.course.creator.name.charAt(0).toUpperCase()}
                    src={
                      data.enrol.course.creator?.educator_info?.image_url
                        ? data.enrol.course.creator.educator_info?.image_url
                        : null
                    }
                  >
                    {data.enrol.course.creator.name.charAt(0).toUpperCase()}
                  </Avatar>
                </Grid>
                <Grid item xs>
                  <Typography
                    sx={{ fontSize: '18px', fontWeight: 600 }}
                    className="first-letter-cap"
                  >
                    <strong
                      onClick={() => {
                        const courseCreaterId = data?.enrol?.course?.creator?.id;
                        courseCreaterId &&
                          navigate(`/educator/channel/${courseCreaterId}`);
                      }}
                      style={{ cursor: 'pointer', color: '#ee3264' }}
                    >
                      {`${data?.enrol?.course?.creator?.educator_info?.first_name} ${data?.enrol?.course?.creator?.educator_info?.last_name}`}
                    </strong>
                  </Typography>{' '}
                  {data?.enrol?.course?.creator?.educator_info ? (
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: '16px', fontWeight: 600 }}
                    >
                      {data?.enrol?.course?.creator?.educator_info?.field}
                      &nbsp;|&nbsp;
                      {data?.enrol?.course?.creator?.educator_info?.subject}{' '}
                    </Typography>
                  ) : null}{' '}
                  {data?.enrol?.course?.institution_info ? (
                    <Typography
                      variant="subtitle2"
                      sx={{ fontSize: '16px', fontWeight: 700 }}
                    >
                      {data?.enrol?.course?.institution_info.name}{' '}
                    </Typography>
                  ) : null}
                  <Typography sx={{ fontSize: '16px', fontWeight: 700 }}>
                    {data?.enrol?.totalFollowers} Followers
                  </Typography>
                  <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                    {data?.enrol?.course?.creator?.educator_info?.bio}
                  </Typography>
                </Grid>
                <Grid
                  item
                  sx={{
                    justifyContent: 'right',
                    display: 'block',
                  }}
                >
                  {data.enrol.alreadyFollowing ? (
                    <Button
                      variant="contained"
                      onClick={() => onUnFollowClick(data.enrol.course.creator.id)}
                      sx={{ textTransform: 'capitalize', minWidth: '93px' }}
                    >
                      UnFollow
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      onClick={() => onFollowClick(data.enrol.course.creator.id)}
                      sx={{ textTransform: 'capitalize', minWidth: '93px' }}
                    >
                      Follow
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Paper>
          </EnrolledCourseTabPanel>
          <EnrolledCourseTabPanel value={value} index={1}>
            <Paper
              sx={{
                my: 1,
                mx: 'auto',
                p: 2,
              }}
            >
              <Typography component="h2">
                <strong>{data.enrol.course.title}</strong>
              </Typography>

              <Typography variant="subtitle2">
                In&nbsp;
                {data.enrol.course.language}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {data.enrol.course.description}
              </Typography>
            </Paper>
          </EnrolledCourseTabPanel>

          <EnrolledCourseTabPanel value={value} index={2}></EnrolledCourseTabPanel>
          <EnrolledCourseTabPanel value={value} index={3}>
            Course Downloads
          </EnrolledCourseTabPanel>
          <EnrolledCourseTabPanel value={value} index={4}>
            {data.enrol.courseReview ? (
              <DisplayCourseReview courseReview={data.enrol.courseReview} />
            ) : (
              <CourseReview course_id={data.enrol.course_id} />
            )}
          </EnrolledCourseTabPanel>
        </Box>
      </Grid>
      <Grid item xs="12" sm="12" md="4" lg="4" xl="4" align="left">
        <Box sx={{ margin: '20px' }}>
          <Typography variant="subtitle1" color="text.secondary">
            Course Content
          </Typography>{' '}
          {data.enrol.course.subjects.length ? (
            <DisplayEnroledCourseCurriculum
              subjects={data.enrol.course.subjects}
              enrolId={data.enrol.id}
              progressData={data.enrol.video_progress}
            />
          ) : null}
        </Box>
      </Grid>
    </Grid>
  ) : (
    <Typography>Enroled Course Not Found</Typography>
  );
}

export default EnroledCourse;
