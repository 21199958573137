/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BaseApi } from './BaseApi';

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

export const coursesApi = BaseApi.injectEndpoints({
  reducerPath: 'coursesApi',
  endpoints: (builder) => ({
    courses: builder.query({
      query: () => ({
        url: '/courses',
        method: 'GET',
      }),
      providesTags: ['Course'],
    }),
    createCourse: builder.mutation({
      query: (details) => ({
        url: '/course/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          sub_category_id: details.sub_category_id,
          language: details.language,
          // start_date: details.start_date,
          // end_date: details.end_date,
          cost: details.cost,
          grade_id: details.grade_id,
          grade_subject_id: details.grade_subject_id,
          publication_id: details.publication_id,
          publication_edition_id: details.publication_edition_id,
          level: details.level,
        },
      }),
      invalidatesTags: ['Course'],
    }),
    course: builder.query({
      query: (details) => ({
        url: `/course/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Course'],
    }),
    updateCourse: builder.mutation({
      query: (details) => ({
        url: `/course/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          sub_category_id: details.sub_category_id,
          language: details.language,
          // start_date: details.start_date,
          // end_date: details.end_date,
          educator_id: details.educator_id,
          institution_id: details.institution_id,
          cost: details.cost,
          level: details.level,
          grade_id: details.grade_id,
          grade_subject_id: details.grade_subject_id,
          publication_id: details.publication_id,
          publication_edition_id: details.publication_edition_id,
        },
      }),
      invalidatesTags: ['Course'],
    }),
    updateCourseSatus: builder.mutation({
      query: (details) => ({
        url: '/course/status/update',
        method: 'POST',

        body: {
          status: details.status,
          course_id: details.id,
          published_at: details.published_at,
        },
      }),
      invalidatesTags: ['Course'],
    }),

    deleteCourse: builder.mutation({
      query: (details) => ({
        url: `/course/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Course'],
    }),
    // Subjects
    subjects: builder.query({
      query: () => ({
        url: '/subjects',
        method: 'GET',
      }),
      providesTags: ['Subject'],
    }),
    createSubject: builder.mutation({
      query: (details) => ({
        url: '/course/subject/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          // start_time: details.start_time,
          // end_time: details.end_time,
          course_id: details.course_id,
        },
      }),
      invalidatesTags: ['Course'],
    }),

    subject: builder.query({
      query: (details) => ({
        url: `/course/subject/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Subject'],
    }),
    updateSubject: builder.mutation({
      query: (details) => ({
        url: `/course/subject/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          // start_time: details.start_time,
          // end_time: details.end_time,
          course_id: details.course_id,
        },
      }),
      invalidatesTags: ['Subject'],
    }),

    deleteSubject: builder.mutation({
      query: (details) => ({
        url: `/course/subject/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Course'],
    }),

    // Topics

    topics: builder.query({
      query: () => ({
        url: '/courses/subject/topics',
        method: 'GET',
      }),
      providesTags: ['Topic'],
    }),
    createTopic: builder.mutation({
      query: (details) => ({
        url: '/course/subject/topic/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          subject_id: details.subject_id,
        },
      }),
      invalidatesTags: ['Subject', 'Course'],
    }),
    topic: builder.query({
      query: (details) => ({
        url: `/course/subject/topic/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Topic'],
    }),
    updateTopic: builder.mutation({
      query: (details) => ({
        url: `/course/subject/topic/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          subject_id: details.subject_id,
        },
      }),
      invalidatesTags: ['Topic'],
    }),

    deleteTopic: builder.mutation({
      query: (details) => ({
        url: `/course/subject/topic/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Subject', 'Course'],
    }),
    // Sub Topics

    subTopics: builder.query({
      query: () => ({
        url: '/courses/subject/topic/subTopics',
        method: 'GET',
      }),
      providesTags: ['Sub Topic'],
    }),
    createSubTopic: builder.mutation({
      query: (details) => ({
        url: '/course/subject/topic/subTopic/create',
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          topic_id: details.topic_id,
        },
      }),
      invalidatesTags: ['Topic'],
    }),
    subTopic: builder.query({
      query: (details) => ({
        url: `/course/subject/topic/subTopic/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Sub Topic'],
    }),
    updateSubTopic: builder.mutation({
      query: (details) => ({
        url: `/course/subject/topic/subTopic/update/${details.id}`,
        method: 'POST',

        body: {
          title: details.title,
          description: details.description,
          topic_id: details.topic_id,
        },
      }),
      invalidatesTags: ['Sub Topic'],
    }),
    deleteSubTopic: builder.mutation({
      query: (details) => ({
        url: `/course/subject/topic/subTopic/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Topic'],
    }),
    getVideoJobStatus: builder.mutation({
      query: (details) => ({
        url: `/course/video/upload/status/${details.id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Course'],
    }),
    getSubTopicVideoJobStatus: builder.mutation({
      query: (details) => ({
        url: `/course/subTopic/video/upload/status/${details.id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Sub Topic'],
    }),
    getVideoUrl: builder.query({
      query: (details) => ({
        url: `/course/video/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Video'],
    }),
    getSubTopicVideoUrl: builder.query({
      query: (details) => ({
        url: `/course/subTopic/video/show/${details.id}`,
        method: 'GET',
      }),
    }),
    videos: builder.query({
      query: (token) => ({
        url: '/videos',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ['Videos'],
    }),
    getVideo: builder.query({
      query: (details) => ({
        url: `/video/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Videos'],
    }),
    updateVideoStatus: builder.mutation({
      query: (details) => ({
        url: '/video/status/update',
        method: 'POST',

        body: {
          video_status: details.status,
          video_id: details.videoId,
        },
      }),
      invalidatesTags: ['Videos'],
    }),
    getSubjectVideoJobStatus: builder.mutation({
      query: (details) => ({
        url: `/course/subject/video/upload/status/${details.id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Subject'],
    }),
    getTopicVideoJobStatus: builder.mutation({
      query: (details) => ({
        url: `/course/topic/video/upload/status/${details.id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Topic'],
    }),
    getSubjectVideoUrl: builder.query({
      query: (details) => ({
        url: `/course/subject/video/show/${details.id}`,
        method: 'GET',
      }),
    }),
    getTopicVideoUrl: builder.query({
      query: (details) => ({
        url: `/course/topic/video/show/${details.id}`,
        method: 'GET',
      }),
    }),
    getAdminVideoJobStatus: builder.mutation({
      query: (details) => ({
        url: `/video/status/${details.id}`,
        method: 'POST',
      }),
      invalidatesTags: ['Videos'],
    }),
    updateVideoDuration: builder.mutation({
      query: (details) => ({
        url: '/video/duration/update',
        method: 'POST',

        body: {
          duration: details.duration,
          video_id: details.videoId,
        },
      }),
      invalidatesTags: [
        'Course',
        'Subject',
        'Topic',
        'Sub Topic',
        'Video',
        'Videos',
      ],
    }),
    videoViolations: builder.query({
      query: (token) => ({
        url: '/video/violations',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ['Video Violations'],
    }),
    courseViolations: builder.query({
      query: (token) => ({
        url: '/course/violations',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ['Course Violations'],
    }),
    getVideoViolation: builder.query({
      query: (details) => ({
        url: `/video/violation/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Video Violation'],
    }),
    getCourseViolation: builder.query({
      query: (details) => ({
        url: `/course/violation/show/${details.id}`,
        method: 'GET',
      }),
      providesTags: ['Course Violation'],
    }),
    deleteVideo: builder.mutation({
      query: (details) => ({
        url: `/video/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Videos'],
    }),
    documents: builder.query({
      query: (token) => ({
        url: '/documents',
        method: 'GET',
        headers: { Authorization: `Bearer ${token}` },
      }),
      providesTags: ['Documents'],
    }),
    updateTopicExternalLink: builder.mutation({
      query: (details) => ({
        url: `/topic/external/link/update/${details.id}`,
        method: 'POST',

        body: {
          external_link_title: details.external_link_title,
          external_video_link: details.external_video_link,
        },
      }),
      invalidatesTags: ['Topic', 'Course'],
    }),
    deleteDocument: builder.mutation({
      query: (details) => ({
        url: `/document/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Documents'],
    }),
    updateDocumentStatus: builder.mutation({
      query: (details) => ({
        url: '/document/status/update',
        method: 'POST',

        body: {
          document_status: details.status,
          document_id: details.documentId,
        },
      }),
      invalidatesTags: ['Documents'],
    }),
    deleteTopicDocument: builder.mutation({
      query: (details) => ({
        url: `/document/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Topic'],
    }),
    deleteTopicVideo: builder.mutation({
      query: (details) => ({
        url: `/video/delete/${details.id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Topic'],
    }),
  }),
});

export const {
  useCoursesQuery,
  useCreateCourseMutation,
  useUpdateCourseSatusMutation,
  useDeleteCourseMutation,
  useCourseQuery,
  useUpdateCourseMutation,
  useCreateSubjectMutation,
  useSubjectQuery,
  useUpdateSubjectMutation,
  useCreateTopicMutation,
  useUpdateTopicMutation,
  useTopicQuery,
  useCreateSubTopicMutation,
  useSubTopicQuery,
  useDeleteSubjectMutation,
  useDeleteTopicMutation,
  useUpdateSubTopicMutation,
  useDeleteSubTopicMutation,
  useGetVideoJobStatusMutation,
  useGetVideoUrlQuery,
  useGetSubTopicVideoUrlQuery,
  useGetSubTopicVideoJobStatusMutation,
  useVideosQuery,
  useGetVideoQuery,
  useUpdateVideoStatusMutation,
  useGetSubjectVideoJobStatusMutation,
  useGetTopicVideoJobStatusMutation,
  useGetSubjectVideoUrlQuery,
  useGetTopicVideoUrlQuery,
  useGetAdminVideoJobStatusMutation,
  useUpdateVideoDurationMutation,
  useVideoViolationsQuery,
  useCourseViolationsQuery,
  useGetVideoViolationQuery,
  useGetCourseViolationQuery,
  useDeleteVideoMutation,
  useDocumentsQuery,
  useUpdateTopicExternalLinkMutation,
  useDeleteDocumentMutation,
  useUpdateDocumentStatusMutation,
  useDeleteTopicDocumentMutation,
  useDeleteTopicVideoMutation,
} = coursesApi;
