/* eslint-disable no-unused-vars */
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useState } from 'react';
import { CheckCircleOutline } from '@mui/icons-material';
import { Alert, Backdrop, CircularProgress } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  useCreateStudentPaymentOrderIdMutation,
  useCreateStudentSubscriptionWithPaymentMutation,
  usePublicStudentSubscriptionPlanQuery,
} from '../../../app/services/StudentSubscriptionPlansApi';
import moment from 'moment';

const RAZORPAKEY = process.env.REACT_APP_RAZORPAY_KEY;

function DisplaySingleStudentSubscriptionPlan() {
  const params = useParams();
  const { user, isSignedIn } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const { data, isLoading, isSuccess } = usePublicStudentSubscriptionPlanQuery({
    id: parseInt(params.Id, 10),
  });
  const [createSubscriptionPlan, res] = useCreateStudentPaymentOrderIdMutation();

  const [selectedSubPlans, setSelectedSubPlans] = useState([]);
  const [selectedGradePlans, setSelectedGradePlans] = useState([]);
  const [selectedGradeSubjectPlans, setSelectedGradeSubjectPlans] = useState([]);
  const [totalCost, setTotalCost] = useState(0);

  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  const [createSubscriptionPlanPayment, result1] =
    useCreateStudentSubscriptionWithPaymentMutation();

  const handleSubChange = (event, cost) => {
    if (!event.target.checked) {
      const filteredGrades = selectedSubPlans.filter(
        (single) => single != event.target.id
      );
      setSelectedSubPlans(filteredGrades);
      setTotalCost(totalCost - cost);
    } else {
      if (!selectedSubPlans.includes(event.target.id)) {
        setSelectedSubPlans([...selectedSubPlans, parseInt(event.target.id)]);
        setTotalCost(totalCost + cost);
      }
    }
  };
  const handleGradeChange = (event, cost) => {
    if (!event.target.checked) {
      const filteredGrades = selectedGradePlans.filter(
        (single) => single != event.target.id
      );
      setSelectedGradePlans(filteredGrades);
      setTotalCost(totalCost - cost);
    } else {
      if (!selectedGradePlans.includes(event.target.id)) {
        setSelectedGradePlans([...selectedGradePlans, parseInt(event.target.id)]);
        setTotalCost(totalCost + cost);
      }
    }
  };
  const handleGradeSubjectChange = (event, cost) => {
    if (!event.target.checked) {
      const filteredGrades = selectedGradeSubjectPlans.filter(
        (single) => single != event.target.id
      );
      setSelectedGradeSubjectPlans(filteredGrades);
      setTotalCost(totalCost - cost);
    } else {
      if (!selectedGradeSubjectPlans.includes(event.target.id)) {
        setSelectedGradeSubjectPlans([
          ...selectedGradeSubjectPlans,
          parseInt(event.target.id),
        ]);
        setTotalCost(totalCost + cost);
      }
    }
  };

  const createOrderId = async () => {
    // createSubscriptionPlan(subscriptionPlanData);

    const createOrderIdRequest = await createSubscriptionPlan({
      amount: totalCost,
      currency: 'INR',
    });

    return createOrderIdRequest.data.orderId;
  };

  const razorPayPoUp = (orderId) => {
    const options = {
      key: RAZORPAKEY, // Enter the Key ID generated from the Dashboard
      amount: totalCost * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: `${process.env.REACT_APP_NAME}`,
      description: 'Transaction',
      image: '{ logo }',
      order_id: orderId,
      handler: async (response) => {
        if (!response.razorpay_signature) {
          showModal();
        } else {
          setLoading(true);

          const subscriptionRes = await createSubscriptionPlanPayment({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            order_id: orderId,
            amount: totalCost,
            student_subscription_plan_id:
              isSuccess && data ? data.studentSubscriptionPlan.id : null,
            email: user.email,
            subCategories: selectedSubPlans,
            grades: selectedGradePlans,
            gradeSubjects: selectedGradeSubjectPlans,
          });
          setSuccess(true);
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone_number,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#a544a5',
      },
    };
    if (typeof Razorpay !== 'undefined') {
      // eslint-disable-next-line no-undef
      const rzp1 = new Razorpay(options);
      // eslint-disable-next-line func-names
      rzp1.on('payment.failed', function (response) {
        // eslint-disable-next-line no-console
        console.log(response, 'failed');
      });

      rzp1.open();
    }
  };
  const handleRazorPayPayment = async () => {
    const orderId = await createOrderId();
    razorPayPoUp(orderId);
  };

  if (visible) {
    return (
      <>
        <Alert severity="error">Error Subscribing.</Alert>

        <Grid container align="center">
          <Grid item md={12}>
            <Typography variant="h4">
              {data.studentSubscriptionPlan.title}
            </Typography>
            <Typography>
              <pre>{data.studentSubscriptionPlan.description}</pre>
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  if (success && result1?.data?.response?.id) {
    return (
      <>
        <Card>
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
              Successfully Subscribed.
            </Typography>
            <Alert
              icon={<CheckCircleOutline fontSize="inherit" />}
              severity="success"
            >
              Transaction Id: {result1?.data?.response?.payment?.transaction_id}
            </Alert>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              {data.studentSubscriptionPlan.title}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Status: {result1?.data?.response?.status}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
              Vatlid from &nbsp;
              {moment(result1?.data?.response?.start_date).format(
                'Do MMM YY hh:mm A'
              )}
              &nbsp; till &nbsp;
              {moment(result1?.data?.response?.end_date).format('Do MMM YY hh:mm A')}
            </Typography>{' '}
            <Typography variant="body2">
              <pre>{data.studentSubscriptionPlan.description}</pre>
            </Typography>
            <Typography variant="body2">
              Click below on your subscriptions to start learning
            </Typography>
          </CardContent>
          <CardActions>
            {result1?.data?.response?.sub_categories.length >= 1
              ? result1?.data?.response?.sub_categories.map((singleSub, index) => (
                  <Button key={index}>{singleSub.title}</Button>
                ))
              : null}
            <br />
            {result1?.data?.response?.grades.length >= 1
              ? result1?.data?.response?.grades.map((singleGrade, index) => (
                  <Button key={index}>{singleGrade.title}</Button>
                ))
              : null}
            <br />
            {result1?.data?.response?.grade_subjects.length >= 1
              ? result1?.data?.response?.grade_subjects.map(
                  (singleGradeSubject, index) => (
                    <Button key={index}>{singleGradeSubject.title}</Button>
                  )
                )
              : null}
          </CardActions>
        </Card>

        <Grid container align="center">
          <Grid item md={12}>
            <Typography variant="h4">
              {data.studentSubscriptionPlan.title}
            </Typography>
            <Typography>
              <pre>{data.studentSubscriptionPlan.description}</pre>
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }

  return data && data.studentSubscriptionPlan ? (
    <>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js" />
      </Helmet>
      <Grid container>
        <Grid item md={2}></Grid>
        <Grid item md={5}>
          <Card sx={{ maxWidth: 345 }}>
            <CardHeader title={data.studentSubscriptionPlan.title} />

            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                {data.studentSubscriptionPlan.published_sub_category_plans.length >=
                1
                  ? data.studentSubscriptionPlan.published_sub_category_plans.map(
                      (singleSubPlan, index) => (
                        <FormControlLabel
                          // value={singleSubPlan}
                          control={
                            <Checkbox
                              name={singleSubPlan.title}
                              id={singleSubPlan.id}
                              onChange={(e) =>
                                handleSubChange(e, singleSubPlan.cost)
                              }
                              checked={selectedSubPlans.includes(singleSubPlan.id)}
                            />
                          }
                          label={`${singleSubPlan.title} - ₹${singleSubPlan.cost}`}
                          labelPlacement="end"
                          key={index}
                        />
                      )
                    )
                  : null}
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                {data.studentSubscriptionPlan.published_grade_plans.length >= 1
                  ? data.studentSubscriptionPlan.published_grade_plans.map(
                      (singleGradePlan, index) => (
                        <FormControlLabel
                          // value={singleGradePlan}
                          control={
                            <Checkbox
                              name={singleGradePlan.title}
                              id={singleGradePlan.id}
                              onChange={(e) =>
                                handleGradeChange(e, singleGradePlan.cost)
                              }
                              checked={selectedGradePlans.includes(
                                singleGradePlan.id
                              )}
                            />
                          }
                          label={`${singleGradePlan.title} - ₹${singleGradePlan.cost}`}
                          labelPlacement="end"
                          key={index}
                        />
                      )
                    )
                  : null}
              </FormGroup>
            </FormControl>
            <FormControl component="fieldset">
              <FormGroup aria-label="position">
                {data.studentSubscriptionPlan.published_grade_subject_plans.length >=
                1
                  ? data.studentSubscriptionPlan.published_grade_subject_plans.map(
                      (singleGradeSubjectPlan, index) => (
                        <FormControlLabel
                          // value={singleGradeSubjectPlan}
                          control={
                            <Checkbox
                              name={singleGradeSubjectPlan.title}
                              id={singleGradeSubjectPlan.id}
                              onChange={(e) =>
                                handleGradeSubjectChange(
                                  e,
                                  singleGradeSubjectPlan.cost
                                )
                              }
                              checked={selectedGradeSubjectPlans.includes(
                                singleGradeSubjectPlan.id
                              )}
                            />
                          }
                          label={`${singleGradeSubjectPlan.title} - ${singleGradeSubjectPlan.cost}`}
                          labelPlacement="end"
                          key={index}
                        />
                      )
                    )
                  : null}
              </FormGroup>
            </FormControl>
          </Card>
        </Grid>
        <Grid item md={5}>
          <Card sx={{ maxWidth: 345 }}>
            <CardHeader title="Invoice Details" />
            {selectedSubPlans.length >= 1
              ? data.studentSubscriptionPlan.published_sub_category_plans.map(
                  (singleSubPlan, index) => {
                    if (selectedSubPlans.includes(singleSubPlan.id)) {
                      return (
                        <Typography key={singleSubPlan.id}>
                          {singleSubPlan.title} - {singleSubPlan.cost}
                        </Typography>
                      );
                    }
                  }
                )
              : null}
            {selectedGradePlans.length >= 1
              ? data.studentSubscriptionPlan.published_grade_plans.map(
                  (singleSubPlan, index) => {
                    if (selectedGradePlans.includes(singleSubPlan.id)) {
                      return (
                        <Typography key={singleSubPlan.id}>
                          {singleSubPlan.title} - {singleSubPlan.cost}
                        </Typography>
                      );
                    }
                  }
                )
              : null}
            {selectedGradeSubjectPlans.length >= 1
              ? data.studentSubscriptionPlan.published_grade_subject_plans.map(
                  (singleSubPlan, index) => {
                    if (selectedGradeSubjectPlans.includes(singleSubPlan.id)) {
                      return (
                        <Typography key={singleSubPlan.id}>
                          {singleSubPlan.title} - {singleSubPlan.cost}
                        </Typography>
                      );
                    }
                  }
                )
              : null}
            <Divider />
            <Typography>Total - {totalCost}</Typography>

            {isSignedIn && user.role !== 'student' ? (
              <Alert severity="info">
                Please register as an student to subscribe
              </Alert>
            ) : (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  isSignedIn
                    ? handleRazorPayPayment()
                    : navigate('/student-account', {
                        state: location.pathname,
                      });
                }}
                disabled={!totalCost > 0}
              >
                Pay
              </Button>
            )}
          </Card>
        </Grid>
      </Grid>
    </>
  ) : (
    <p>No Plan Found</p>
  );
}

export default DisplaySingleStudentSubscriptionPlan;
