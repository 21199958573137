/* eslint-disable no-unused-vars */
import { Accordion, AccordionSummary, Button, Divider } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';

function DisplayEnroledTopicVideos(props) {
  const { video, id } = props;
  const navigate = useNavigate();

  const viewVideo = (videoId) => {
    navigate(`/enrols/${id}/video/${videoId}`);
  };

  return (
    <>
      <>
        <Button
          variant="contained"
          onClick={() => viewVideo(video.id)}
          key={video.id}
          sx={{ margin: '10px' }}
          endIcon={<PlayCircleOutlineIcon />}
        >
          {video.title}
        </Button>
        <Divider />
      </>
    </>
  );
}

export default DisplayEnroledTopicVideos;
