/* eslint-disable no-unused-vars */
import {
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Rating,
  styled,
  Tooltip,
  tooltipClasses,
  Typography,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Avatar,
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useHomeDataQuery } from '../app/services/HomePageApi';
import { useSelector } from 'react-redux';
import { Slides } from './Slides';
import { CastForEducation, People, Reviews, Star } from '@mui/icons-material';
const StyledCardActionArea = styled(CardActionArea)(
  ({ theme }) => `
    .MuiCardActionArea-focusHighlight {
        background: transparent;
    }
`
);

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

function HomePage() {
  const { data, isSuccess, isLoading } = useHomeDataQuery();
  const navigate = useNavigate();
  const { isSignedIn } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
    localStorage.setItem('hasVisitedBefore', true);
  };
  const getReducedText = (text) => {
    const finalText = text.length > 118 ? text.substring(0, 118) + '...' : text;
    return finalText;
  };
  const onTestClick = (selected) => {
    navigate(
      `/${selected.sub_category.category.title}/${selected.sub_category.title}/test/${selected.id}`
    );
  };
  const onCourseClick = (selected) => {
    navigate(
      `/${selected.sub_category.category.title}/${selected.sub_category.title}/course/${selected.id}`
    );
  };
  const onExamClick = (selected) => {
    navigate(
      `/${selected.sub_category.category.title}/${selected.sub_category.title}/exam/${selected.id}`
    );
  };
  useEffect(() => {
    // Check local storage to see if the user has visited the site before
    const hasVisitedBefore = localStorage.getItem('hasVisitedBefore');

    if (hasVisitedBefore && isSignedIn) {
      // User has visited before, hide the welcome page and signedIn
      setOpen(false);
    } else if (!hasVisitedBefore && !isSignedIn) {
      setOpen(true);
    } else if (hasVisitedBefore || isSignedIn) {
      setOpen(true);
    } else {
      // First-time visit, show the welcome page and mark as visited
      localStorage.setItem('hasVisitedBefore', true);
    }
  }, [isSignedIn]);

  const displayEducatorAvgCourseRating = (courses) => {
    let totalRating = 0;
    let ratingCount = 0;

    if (courses.length > 0) {
      courses.forEach((singleCourse) => {
        const rating = Number(singleCourse.course_reviews_avg_rating);
        if (!isNaN(rating) && rating > 0) {
          totalRating += rating;
          ratingCount += 1;
        }
      });

      // If there are valid ratings, calculate the average
      if (ratingCount > 0) {
        return Math.round((totalRating / ratingCount) * 10) / 10;
      }
    }

    // If no valid ratings, return a default value
    return 'No'; // Change to any default value you prefer (e.g., 5)
  };

  const displayEducatorCourseEnrols = (courses) => {
    let enrols = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        enrols += Number(singleCourse.course_enrols_count);
      });
      return enrols;
    } else {
      return enrols;
    }
  };

  const displayReviewCounts = (courses) => {
    let reviewCounts = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        reviewCounts += Number(singleCourse.course_reviews_count);
      });
      return reviewCounts;
    } else {
      return reviewCounts;
    }
  };

  const viewEducator = (id) => {
    navigate(`/educator/channel/${id}`);
  };

  return (
    <>
      <Grid container justifyContent={'center'} style={{ backgroundColor: '#fff' }}>
        {/* <Dialog
          open={open}
          onClose={handleClose}
          fullWidth={true}
          maxWidth="lg"
          scroll="paper"
          // sx={{ height: '800px' }}
        >
          <DialogContent style={{ minHeight: '80vh', height: 'auto' }}>
            <Slides />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
          </DialogActions>
        </Dialog> */}
        <Grid item xs={11} sm={12} md={12} lg={11} xl={11}>
          <Grid container spacing={2} sx={{ backgroundColor: '#fff' }}>
            {/* <CssBaseline /> */}
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                height: '450px',
              }}
            >
              <img
                src="/HomepageAds/GMpost1349.svg"
                style={{
                  height: '100%',
                  width: '100%',
                  boxShadow: '1px 1px 10px 1px #dcdcdc',
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}
              sx={{
                height: '350px',
              }}
            >
              <img
                src="/HomepageAds/GMpost2.svg"
                style={{ height: '100%', width: '100%' }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}
              sx={{
                height: '350px',
              }}
            >
              <img
                src="/HomepageAds/GMpost5.svg"
                style={{
                  height: '100%',
                  width: '100%',
                  boxShadow: '1px 1px 10px 1px #dcdcdc',
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}
              sx={{
                height: '350px',
              }}
            >
              <img
                src="/HomepageAds/GMpost3.svg"
                style={{
                  height: '100%',
                  width: '100%',
                  boxShadow: '1px 1px 10px 1px #dcdcdc',
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={3}
              xl={3}
              sx={{
                height: '350px',
              }}
            >
              <img
                src="/HomepageAds/GMpost4.svg"
                style={{
                  height: '100%',
                  width: '100%',
                  boxShadow: '1px 1px 10px 1px #dcdcdc',
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{
                height: '400px',
              }}
            >
              <img
                src="/HomepageAds/GMpost6882.svg"
                style={{
                  height: '100%',
                  width: '100%',
                  boxShadow: '1px 1px 10px 1px #dcdcdc',
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              xl={6}
              sx={{
                height: '400px',
              }}
            >
              <img
                src="/HomepageAds/GMpost688.svg"
                style={{
                  height: '100%',
                  width: '100%',
                  boxShadow: '1px 1px 10px 1px #dcdcdc',
                }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{
                height: '300px',
              }}
            >
              <img
                src="/HomepageAds/FreshersPlaced.svg"
                style={{
                  height: '100%',
                  width: '100%',
                  boxShadow: '1px 1px 10px 1px #dcdcdc',
                }}
              />
            </Grid>
          </Grid>
          <Paper
            elevation="10"
            sx={{
              padding: '20px 0px 0px 0px',
              background: 'none',
              boxShadow: 'none',
            }}
          >
            <Typography>
              <strong style={{ fontSize: '20px' }}>New Courses </strong>
            </Typography>
            <Grid container spacing={1}>
              {isSuccess && data.courses.length
                ? data.courses.map((singleCourse, index) => (
                    <Grid item xs={12} sm={12} md={4} lg={2} xl={2} key={index}>
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit" gutterBottom>
                        //       <em>{singleCourse.title}</em>
                        //     </Typography>
                        //     <Typography
                        //       variant="subtitle2"
                        //       color="text.secondary"
                        //       gutterBottom
                        //     >
                        //       By {singleCourse.creator.name}
                        //     </Typography>
                        //     <Typography paragraph variant="body1" gutterBottom>
                        //       {singleCourse.description}
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        arrow
                        placement="right"
                      >
                        <StyledCardActionArea
                          component="a"
                          onClick={() => onCourseClick(singleCourse)}
                          sx={{
                            padding: '6px',
                            border: '1.5px solid #a0a5a7',
                            borderRadius: '10px',
                            position: 'relative',
                            height: '300px',
                            background: '#fff',
                            boxShadow: '1px 1px 10px 1px #dcdcdc',
                          }}
                        >
                          <Card sx={{ boxShadow: 'none' }}>
                            <CardMedia
                              image={
                                singleCourse.url ||
                                `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                              }
                              title={singleCourse?.title}
                              sx={{
                                height: 130,
                                boxShadow: 'none',
                                border: '1.5px solid #a0a5a7',
                                borderRadius: '10px 10px 0px 0px',
                              }}
                            />
                            <CardContent
                              sx={{
                                overflowWrap: 'anywhere',
                                padding: '10px 0px 0px 0px',
                              }}
                            >
                              <Typography
                                component="h2"
                                sx={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  width: '100%',
                                }}
                                title={singleCourse?.title}
                              >
                                <strong
                                  style={{
                                    fontSize: '16px',
                                  }}
                                >
                                  {singleCourse.title}
                                </strong>
                              </Typography>
                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                sx={{
                                  padding: '0px 10px 0px 10px',
                                }}
                                className="wrap-text-ellipsis"
                                title={`${
                                  singleCourse?.creator?.educator_info?.first_name
                                    ? singleCourse?.creator?.educator_info
                                        ?.first_name
                                    : singleCourse?.creator?.name
                                }${
                                  singleCourse?.creator?.educator_info?.last_name
                                    ? ` ${singleCourse?.creator?.educator_info?.last_name}`
                                    : null
                                }`}
                              >
                                {singleCourse?.creator?.educator_info?.first_name
                                  ? singleCourse?.creator?.educator_info?.first_name
                                  : singleCourse?.creator?.name}
                                {singleCourse?.creator?.educator_info?.last_name
                                  ? ` ${singleCourse?.creator?.educator_info?.last_name}`
                                  : null}
                              </Typography>

                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '0px 10px 0px 10px',
                                }}
                              >
                                <Box>
                                  <Typography variant="subtitle2" color="#faaf00">
                                    <strong>
                                      {' '}
                                      {Math.round(
                                        singleCourse.course_reviews_avg_rating * 10
                                      ) / 10}
                                    </strong>
                                    &nbsp;
                                  </Typography>
                                </Box>
                                <Rating
                                  name="read-only"
                                  value={
                                    Math.round(
                                      singleCourse.course_reviews_avg_rating * 10
                                    ) / 10
                                  }
                                  readOnly
                                  size="small"
                                  precision={0.5}
                                />

                                {singleCourse.course_reviews_count ? (
                                  <Typography variant="subtitle2">
                                    &#40;{singleCourse.course_reviews_count}
                                    &nbsp;reviews&#41;
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle2">
                                    &#40;no reviews&#41;
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '0px 10px 20px 10px',
                                  justifyContent: 'space-between',
                                  position: 'absolute',
                                  bottom: '0px',
                                  width: '-webkit-fill-available',
                                }}
                              >
                                {singleCourse.cost ? (
                                  <Typography component="h2" color="text.primary">
                                    <strong>₹{singleCourse.cost}</strong>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '12px' }}
                                  >
                                    Free
                                  </Typography>
                                )}
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      fontSize: '8px',
                                      padding: '0.1rem',
                                      height: '20px',
                                      background: '#3ec6ff',
                                    }}
                                  >
                                    Enroll Now
                                  </Button>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      fontSize: '8px',
                                      padding: '0.1rem',
                                      height: '20px',
                                      background: '#6a17b2',
                                    }}
                                  >
                                    Watch Later
                                  </Button>
                                </div>
                              </Box>
                            </CardContent>
                          </Card>
                        </StyledCardActionArea>
                      </HtmlTooltip>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Paper>
          <Paper
            elevation="10"
            sx={{
              padding: '20px 0px 0px 0px',
              background: 'none',
              boxShadow: 'none',
            }}
          >
            <Typography>
              <strong style={{ fontSize: '20px' }}>Popular & Trending</strong>
            </Typography>
            <Grid container spacing={1}>
              {isSuccess && data.courses.length
                ? data.popularCourses.map((singlePopular, index) => (
                    <Grid item xs={12} sm={12} md={4} lg={2} xl={2} key={index}>
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit" gutterBottom>
                        //       <em>{singleCourse.title}</em>
                        //     </Typography>
                        //     <Typography
                        //       variant="subtitle2"
                        //       color="text.secondary"
                        //       gutterBottom
                        //     >
                        //       By {singleCourse.creator.name}
                        //     </Typography>
                        //     <Typography paragraph variant="body1" gutterBottom>
                        //       {singleCourse.description}
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        arrow
                        placement="right"
                      >
                        <StyledCardActionArea
                          component="a"
                          onClick={() => onCourseClick(singlePopular)}
                          sx={{
                            padding: '6px',
                            border: '1.5px solid #a0a5a7',
                            borderRadius: '10px',
                            position: 'relative',
                            height: '300px',
                            background: '#fff',
                            boxShadow: '1px 1px 10px 1px #dcdcdc',
                          }}
                        >
                          <Card sx={{ boxShadow: 'none' }}>
                            <CardMedia
                              image={
                                singlePopular.url ||
                                `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                              }
                              title={singlePopular?.title}
                              sx={{
                                height: 130,
                                boxShadow: 'none',
                                border: '1.5px solid #a0a5a7',
                                borderRadius: '10px 10px 0px 0px',
                              }}
                            />
                            <CardContent
                              sx={{
                                overflowWrap: 'anywhere',
                                padding: '10px 0px 0px 0px',
                              }}
                            >
                              <Typography
                                component="h2"
                                sx={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  width: '100%',
                                }}
                                title={singlePopular.title}
                              >
                                <strong
                                  style={{
                                    fontSize: '16px',
                                  }}
                                >
                                  {singlePopular.title}
                                </strong>
                              </Typography>

                              <Typography
                                variant="subtitle2"
                                color="text.secondary"
                                sx={{
                                  padding: '0px 10px 0px 10px',
                                }}
                                className="wrap-text-ellipsis"
                                title={`${
                                  singlePopular?.creator?.educator_info?.first_name
                                    ? singlePopular?.creator?.educator_info
                                        ?.first_name
                                    : singlePopular?.creator?.name
                                }${
                                  singlePopular?.creator?.educator_info?.last_name
                                    ? ` ${singlePopular?.creator?.educator_info?.last_name}`
                                    : null
                                }`}
                              >
                                {singlePopular?.creator?.educator_info?.first_name
                                  ? singlePopular?.creator?.educator_info?.first_name
                                  : singlePopular?.creator?.name}
                                {singlePopular?.creator?.educator_info?.last_name
                                  ? ` ${singlePopular?.creator?.educator_info?.last_name}`
                                  : null}
                              </Typography>

                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '0px 10px 0px 10px',
                                }}
                              >
                                <Box>
                                  <Typography variant="subtitle2" color="#faaf00">
                                    <strong>
                                      {' '}
                                      {Math.round(
                                        singlePopular.course_reviews_avg_rating * 10
                                      ) / 10}
                                    </strong>
                                    &nbsp;
                                  </Typography>
                                </Box>
                                <Rating
                                  name="read-only"
                                  value={
                                    Math.round(
                                      singlePopular.course_reviews_avg_rating * 10
                                    ) / 10
                                  }
                                  readOnly
                                  size="small"
                                  precision={0.5}
                                />

                                {singlePopular.course_reviews_count ? (
                                  <Typography variant="subtitle2">
                                    &#40;{singlePopular.course_reviews_count}
                                    &nbsp;reviews&#41;
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle2">
                                    &#40;no reviews&#41;
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '0px 10px 20px 10px',
                                  justifyContent: 'space-between',
                                  position: 'absolute',
                                  bottom: '0px',
                                  width: '-webkit-fill-available',
                                }}
                              >
                                {singlePopular.cost ? (
                                  <Typography component="h2" color="text.primary">
                                    <strong>₹{singlePopular.cost}</strong>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '12px' }}
                                  >
                                    Free
                                  </Typography>
                                )}
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      fontSize: '8px',
                                      padding: '0.1rem',
                                      height: '20px',
                                      background: '#3ec6ff',
                                    }}
                                  >
                                    Enroll Now
                                  </Button>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      fontSize: '8px',
                                      padding: '0.1rem',
                                      height: '20px',
                                      background: '#6a17b2',
                                    }}
                                  >
                                    Watch Later
                                  </Button>
                                </div>
                              </Box>
                            </CardContent>
                          </Card>
                        </StyledCardActionArea>
                      </HtmlTooltip>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Paper>
          <Paper
            elevation="10"
            sx={{
              padding: '20px 0px 0px 0px',
              background: 'none',
              boxShadow: 'none',
            }}
          >
            <Typography>
              <strong style={{ fontSize: '20px' }}>Latest Exams</strong>
            </Typography>
            <Grid container spacing={2}>
              {isSuccess && data.exams.length
                ? data.exams.map((singleExam) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={2}
                      xl={2}
                      key={singleExam.id}
                    >
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit" gutterBottom>
                        //       <em>{singleExam.title}</em>
                        //     </Typography>
                        //     <Typography
                        //       variant="subtitle2"
                        //       color="text.secondary"
                        //       gutterBottom
                        //     >
                        //       By {singleExam.creator.name}
                        //     </Typography>
                        //     <Typography paragraph variant="body1" gutterBottom>
                        //       {singleExam.description}
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        arrow
                        placement="right"
                      >
                        <StyledCardActionArea
                          component="a"
                          onClick={() => onExamClick(singleExam)}
                          sx={{
                            padding: '6px',
                            border: '1.5px solid #a0a5a7',
                            borderRadius: '10px',
                            position: 'relative',
                            height: '300px',
                            background: '#fff',
                            boxShadow: '1px 1px 10px 1px #dcdcdc',
                          }}
                        >
                          <Card sx={{ boxShadow: 'none' }}>
                            <CardMedia
                              image={
                                singleExam.url ||
                                `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                              }
                              sx={{
                                height: 130,
                                boxShadow: 'none',
                                border: '1.5px solid #a0a5a7',
                                borderRadius: '10px 10px 0px 0px',
                              }}
                              title={singleExam?.title}
                            />
                            <CardContent
                              sx={{
                                flex: 1,
                                overflowWrap: 'anywhere',
                                padding: '10px 0px 0px 0px',
                              }}
                            >
                              <Typography
                                component="h2"
                                sx={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  width: '100%',
                                }}
                                title={singleExam.title}
                              >
                                <strong
                                  style={{
                                    fontSize: '16px',
                                  }}
                                >
                                  {singleExam.title}
                                </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  padding: '0px 10px 0px 10px',
                                }}
                                variant="subtitle2"
                                color="text.secondary"
                                className="wrap-text-ellipsis"
                                title={`${
                                  singleExam?.creator?.educator_info?.first_name
                                    ? singleExam?.creator?.educator_info?.first_name
                                    : singleExam?.creator?.name
                                } ${
                                  singleExam?.creator?.educator_info?.last_name
                                    ? ` ${singleExam?.creator?.educator_info?.last_name}`
                                    : null
                                }`}
                              >
                                {singleExam?.creator?.educator_info?.first_name
                                  ? singleExam?.creator?.educator_info?.first_name
                                  : singleExam?.creator?.name}
                                {singleExam?.creator?.educator_info?.last_name
                                  ? ` ${singleExam?.creator?.educator_info?.last_name}`
                                  : null}
                              </Typography>

                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '0px 10px 0px 10px',
                                }}
                              >
                                <Box>
                                  <Typography variant="subtitle2" color="#faaf00">
                                    <strong>
                                      {' '}
                                      {Math.round(
                                        singleExam.exam_reviews_avg_rating * 10
                                      ) / 10}
                                    </strong>
                                    &nbsp;
                                  </Typography>
                                </Box>
                                <Rating
                                  name="read-only"
                                  value={
                                    Math.round(
                                      singleExam.exam_reviews_avg_rating * 10
                                    ) / 10
                                  }
                                  readOnly
                                  size="small"
                                  precision={0.5}
                                />

                                {singleExam.exam_reviews_count ? (
                                  <Typography variant="subtitle2">
                                    &#40;{singleExam.exam_reviews_count}
                                    &nbsp;reviews&#41;
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle2">
                                    &#40;no reviews&#41;
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '0px 10px 20px 10px',
                                  justifyContent: 'space-between',
                                  position: 'absolute',
                                  bottom: '0px',
                                  width: '-webkit-fill-available',
                                }}
                              >
                                {singleExam.cost ? (
                                  <Typography component="h2" color="text.primary">
                                    <strong>₹{singleExam.cost}</strong>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '12px' }}
                                  >
                                    Free
                                  </Typography>
                                )}
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      fontSize: '8px',
                                      padding: '0.1rem',
                                      height: '20px',
                                      background: '#3ec6ff',
                                    }}
                                  >
                                    Enroll Now
                                  </Button>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      fontSize: '8px',
                                      padding: '0.1rem',
                                      height: '20px',
                                      background: '#6a17b2',
                                    }}
                                  >
                                    Watch Later
                                  </Button>
                                </div>
                              </Box>
                            </CardContent>
                          </Card>
                        </StyledCardActionArea>
                      </HtmlTooltip>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Paper>
          <Paper
            elevation="10"
            sx={{
              padding: '20px 0px 0px 0px',
              background: 'none',
              boxShadow: 'none',
            }}
          >
            <Typography>
              {' '}
              <strong style={{ fontSize: '20px' }}>Latest Practice Tests</strong>
            </Typography>
            <Grid container spacing={2}>
              {isSuccess && data.tests.length
                ? data.tests.map((singleTest) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={2}
                      xl={2}
                      key={singleTest.id}
                    >
                      <HtmlTooltip
                        // title={
                        //   <React.Fragment>
                        //     <Typography color="inherit" gutterBottom>
                        //       <em>{singleTest.title}</em>
                        //     </Typography>
                        //     <Typography
                        //       variant="subtitle2"
                        //       color="text.secondary"
                        //       gutterBottom
                        //     >
                        //       By {singleTest.creator.name}
                        //     </Typography>
                        //     <Typography paragraph variant="body1" gutterBottom>
                        //       {singleTest.description}
                        //     </Typography>
                        //   </React.Fragment>
                        // }
                        arrow
                        placement="right"
                      >
                        <StyledCardActionArea
                          component="a"
                          onClick={() => onTestClick(singleTest)}
                          sx={{
                            padding: '6px',
                            border: '1.5px solid #a0a5a7',
                            borderRadius: '10px',
                            position: 'relative',
                            height: '300px',
                            background: '#fff',
                            boxShadow: '1px 1px 10px 1px #dcdcdc',
                          }}
                        >
                          <Card sx={{ boxShadow: 'none' }}>
                            <CardMedia
                              sx={{
                                height: 130,
                                boxShadow: 'none',
                                border: '1.5px solid #a0a5a7',
                                borderRadius: '10px 10px 0px 0px',
                              }}
                              image={
                                singleTest.url ||
                                `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                              }
                              title={singleTest?.title}
                            />
                            <CardContent
                              sx={{
                                flex: 1,
                                overflowWrap: 'anywhere',
                                padding: '10px 0px 0px 0px',
                              }}
                            >
                              <Typography
                                component="h2"
                                sx={{
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  whiteSpace: 'nowrap',
                                  width: '100%',
                                }}
                                title={singleTest.title}
                              >
                                <strong
                                  style={{
                                    fontSize: '16px',
                                  }}
                                >
                                  {singleTest.title}
                                </strong>
                              </Typography>
                              <Typography
                                sx={{
                                  padding: '0px 10px 0px 10px',
                                }}
                                variant="subtitle2"
                                color="text.secondary"
                                className="wrap-text-ellipsis"
                                title={`${
                                  singleTest?.creator?.educator_info?.first_name
                                    ? singleTest?.creator?.educator_info?.first_name
                                    : singleTest?.creator?.name
                                } ${
                                  singleTest?.creator?.educator_info?.last_name
                                    ? ` ${singleTest?.creator?.educator_info?.last_name}`
                                    : null
                                }`}
                              >
                                {singleTest?.creator?.educator_info?.first_name
                                  ? singleTest?.creator?.educator_info?.first_name
                                  : singleTest?.creator?.name}
                                {singleTest?.creator?.educator_info?.last_name
                                  ? ` ${singleTest?.creator?.educator_info?.last_name}`
                                  : null}
                              </Typography>

                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '0px 10px 0px 10px',
                                }}
                              >
                                <Box>
                                  <Typography variant="subtitle2" color="#faaf00">
                                    <strong>
                                      {' '}
                                      {Math.round(
                                        singleTest.test_reviews_avg_rating * 10
                                      ) / 10}
                                    </strong>
                                    &nbsp;
                                  </Typography>
                                </Box>
                                <Rating
                                  name="read-only"
                                  value={
                                    Math.round(
                                      singleTest.test_reviews_avg_rating * 10
                                    ) / 10
                                  }
                                  readOnly
                                  size="small"
                                  precision={0.5}
                                />

                                {singleTest.test_reviews_count ? (
                                  <Typography variant="subtitle2">
                                    &#40;{singleTest.test_reviews_count}
                                    &nbsp;reviews&#41;
                                  </Typography>
                                ) : (
                                  <Typography variant="subtitle2">
                                    &#40;no reviews&#41;
                                  </Typography>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '0px 10px 20px 10px',
                                  justifyContent: 'space-between',
                                  position: 'absolute',
                                  bottom: '0px',
                                  width: '-webkit-fill-available',
                                }}
                              >
                                {singleTest.cost ? (
                                  <Typography component="h2" color="text.primary">
                                    <strong>₹{singleTest.cost}</strong>
                                  </Typography>
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '12px' }}
                                  >
                                    Free
                                  </Typography>
                                )}
                                <div style={{ display: 'flex', gap: '0.5rem' }}>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      fontSize: '8px',
                                      padding: '0.1rem',
                                      height: '20px',
                                      background: '#3ec6ff',
                                    }}
                                  >
                                    Enroll Now
                                  </Button>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      fontSize: '8px',
                                      padding: '0.1rem',
                                      height: '20px',
                                      background: '#6a17b2',
                                    }}
                                  >
                                    Watch Later
                                  </Button>
                                </div>
                              </Box>
                            </CardContent>
                          </Card>
                        </StyledCardActionArea>
                      </HtmlTooltip>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Paper>
          <Paper
            elevation="10"
            sx={{
              padding: '20px 0px 0px 0px',
              background: 'none',
              boxShadow: 'none',
            }}
          >
            <Typography>
              <strong style={{ fontSize: '20px' }}>
                Popular Educators On {process.env.REACT_APP_NAME}
              </strong>
            </Typography>
            <Grid
              container
              spacing={2}
              style={{ marginTop: '0.3rem', marginBottom: '2rem' }}
            >
              {isSuccess && data.popularEducators.length
                ? data.popularEducators.map((singleFollowing) => (
                    <Grid
                      item
                      xl="4"
                      lg="4"
                      md="6"
                      sm="12"
                      xs="12"
                      key={singleFollowing.id}
                      onClick={() => viewEducator(singleFollowing.id)}
                    >
                      <Grid
                        container
                        sx={{
                          background: '#fff',
                          boxShadow: '1px 1px 10px 1px #dcdcdc',
                          cursor: 'pointer',
                        }}
                      >
                        <>
                          <Grid item xl="4" lg="4" md="12" sm="12" xs="12">
                            {!singleFollowing?.educator_info?.image_url ? (
                              <img
                                style={{
                                  height: '220px',
                                  width: '100%',
                                  objectFit: 'contain',
                                }}
                                src="https://cdn4.vectorstock.com/i/1000x1000/95/28/cartoon-male-teacher-vector-16659528.jpg"
                              />
                            ) : (
                              <Avatar
                                variant="square"
                                sx={{
                                  height: '220px',
                                  width: '100%',
                                  border: '1px solid',
                                }}
                                alt={singleFollowing?.name.charAt(0).toUpperCase()}
                                src={
                                  singleFollowing?.educator_info?.image_url
                                    ? singleFollowing?.educator_info.image_url
                                    : null
                                }
                              ></Avatar>
                            )}
                          </Grid>
                          <Grid
                            item
                            xl="8"
                            lg="8"
                            md="12"
                            sm="12"
                            xs="12"
                            sx={{ padding: '1rem 1rem 1rem 1rem' }}
                          >
                            <Typography
                              sx={{
                                fontSize: '24px',
                                fontWeight: 700,
                                color: '#727391',
                                lineHeight: '0.8',
                              }}
                              className="wrap-text-ellipsis"
                              title={`${
                                singleFollowing?.educator_info?.first_name ||
                                singleFollowing?.name
                              } ${
                                singleFollowing?.educator_info?.last_name &&
                                `${singleFollowing?.educator_info?.last_name}`
                              }`}
                            >
                              {singleFollowing?.educator_info?.first_name ||
                                singleFollowing?.name}{' '}
                              {singleFollowing?.educator_info?.last_name &&
                                `${singleFollowing?.educator_info?.last_name}`}
                            </Typography>

                            <Typography
                              sx={{
                                fontSize: '14px',
                                fontWeight: 600,
                                color: '#5e7fb1',
                              }}
                              className="wrap-text-ellipsis"
                              title={`${
                                singleFollowing?.educator_info?.headline ||
                                singleFollowing?.educator_info?.subject
                              }`}
                            >
                              {singleFollowing?.educator_info?.headline ||
                                singleFollowing?.educator_info?.subject}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#1c1d1f',
                                marginTop: '0.5rem',
                                display: 'flex',
                                alignItems: 'unset',
                                gap: '0.5rem',
                              }}
                            >
                              <Star sx={{ fontSize: '1rem' }} />
                              {/* 4.6 Instructor Rating */}
                              {singleFollowing?.educator_course_enrols_and_reviews &&
                              Array.isArray(
                                singleFollowing?.educator_course_enrols_and_reviews
                              ) &&
                              singleFollowing?.educator_course_enrols_and_reviews
                                .length > 0
                                ? displayEducatorAvgCourseRating(
                                    singleFollowing.educator_course_enrols_and_reviews
                                  )
                                : 'No'}
                              &nbsp; Educator Rating
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#1c1d1f',
                                marginTop: '0.2rem',
                                display: 'flex',
                                alignItems: 'unset',
                                gap: '0.5rem',
                              }}
                            >
                              <Reviews sx={{ fontSize: '1rem' }} />
                              {/* 18,534 Reviews */}
                              {singleFollowing?.educator_course_enrols_and_reviews
                                ? displayReviewCounts(
                                    singleFollowing?.educator_course_enrols_and_reviews
                                  )
                                : 'No'}
                              &nbsp;Reviews
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#1c1d1f',
                                marginTop: '0.2rem',
                                display: 'flex',
                                alignItems: 'unset',
                                gap: '0.5rem',
                              }}
                            >
                              <People sx={{ fontSize: '1rem' }} />
                              {/* 115,904 Students */}
                              {singleFollowing?.educator_course_enrols_and_reviews
                                ? displayEducatorCourseEnrols(
                                    singleFollowing?.educator_course_enrols_and_reviews
                                  )
                                : 'No'}
                              &nbsp;Students
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '12px',
                                fontWeight: 500,
                                color: '#1c1d1f',
                                marginTop: '0.2rem',
                                display: 'flex',
                                alignItems: 'unset',
                                gap: '0.5rem',
                              }}
                            >
                              <CastForEducation sx={{ fontSize: '1rem' }} />
                              {/* 13 Courses */}
                              {singleFollowing?.educator_course_enrols_and_reviews
                                ? singleFollowing?.educator_course_enrols_and_reviews
                                    ?.length
                                : 'No'}
                              &nbsp;Courses
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: '11px',
                                fontWeight: 500,
                                color: '#1c1d1f',
                                marginTop: '0.5rem',
                              }}
                              title={
                                getReducedText(
                                  singleFollowing?.educator_info?.bio ||
                                    singleFollowing?.educator_info?.field
                                ).length > 115 &&
                                (singleFollowing?.educator_info?.bio ||
                                  singleFollowing?.educator_info?.field)
                              }
                            >
                              {getReducedText(
                                singleFollowing?.educator_info?.bio ||
                                  singleFollowing?.educator_info?.field
                              )}
                            </Typography>
                          </Grid>
                        </>{' '}
                      </Grid>
                    </Grid>
                  ))
                : null}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default HomePage;
