import { Box, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';

export const TermsConditions = () => {
  const pageData = [
    {
      title: 'Account Registration',
      list: [
        {
          title: 'Students',
          data: `Students must provide accurate information during registration and keep their
          account information updated`,
        },
        {
          title: 'Teachers and Institutes',
          data: `Teachers and Institutes must provide accurate information
          during registration. Institutes can register teachers and manage courses on their behalf`,
        },
      ],
    },
    {
      title: 'Use of Service',
      list: [
        {
          title: '',
          data: `Users must not misuse our Service, engage in any illegal activities, or infringe on the
          rights of others`,
        },
        {
          title: '',
          data: `Teachers and Institutes must ensure that the content they upload is original and does
          not violate any third-party rights`,
        },
      ],
    },
    {
      title: 'Payments and Revenue Sharing',
      list: [
        {
          title: '',
          data: `Our revenue-sharing model includes subscription-based and course buy options`,
        },
        {
          title: '',
          data: `Payment terms and conditions are subject to our agreement with Razor Pay`,
        },
      ],
    },
    {
      title: 'Cancellation and Refunds',
      list: [
        {
          title: '',
          data: `Cancellation and refund policies are outlined in the respective sections below`,
        },
      ],
    },
    {
      title: 'Intellectual Property',
      list: [
        {
          title: '',
          data: `All content on our platform, except for the content uploaded by teachers, is owned by the
          Company. Users are granted a limited license to use the content for personal,
          educational purposes`,
        },
      ],
    },
    {
      title: 'Limitation of Liability',
      list: [
        {
          title: '',
          data: `The Company is not liable for any direct, indirect, incidental, or consequential damages
          arising out of your use of the Service`,
        },
      ],
    },
    {
      title: 'Changes to Terms',
      list: [
        {
          title: '',
          data: `We may update these Terms from time to time. Users will be notified of significant
          changes, and continued use of the Service constitutes acceptance of the new Terms`,
        },
      ],
    },
    {
      title: 'Contact Information',
      list: [
        {
          title: '',
          data: `For any questions about these Terms, please contact us at support@gurumavin.in`,
        },
      ],
    },
  ];
  return (
    <Fragment>
      <Grid container justifyContent={'center'}>
        <Grid item xl={10} lg={10} md={11} sm={11}>
          <Typography
            sx={{
              color: '#000',
              fontSize: '24px',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Terms & Conditions
          </Typography>
          <Box sx={{ marginTop: '1rem' }}>
            <Typography
              sx={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 600,
              }}
            >
              Welcome to DCENTMASS. These Terms & Conditions govern your use of our
              website and services as a student, teac
            </Typography>
            <Typography
              sx={{
                color: '#000',
                fontSize: '16px',
                fontWeight: 500,
                marginTop: '0.5rem',
              }}
            >
              This Privacy Policy explains how DCENTMASS collects, uses, discloses,
              and protects your information when you use our Service.
            </Typography>
          </Box>
          {pageData.map((singleData, ind) => (
            <Box sx={{ marginTop: '1.5rem' }} key={ind + singleData?.title}>
              <Typography
                sx={{
                  color: '#000',
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                {singleData?.title}
              </Typography>
              {Array.isArray(singleData?.list) &&
                singleData?.list.map((singleList, singleIndex) => (
                  <li
                    style={{
                      color: '#000',
                      fontSize: '16px',
                      fontWeight: 500,
                      marginTop: '0.5rem',
                      marginLeft: '1rem',
                    }}
                    key={singleIndex + ' list'}
                  >
                    {singleList?.title ? (
                      <span
                        style={{ fontWeight: 700 }}
                      >{`${singleList?.title} : `}</span>
                    ) : null}
                    {`${singleList?.data} .`}
                  </li>
                ))}
            </Box>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};
