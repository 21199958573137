/* eslint-disable no-unused-vars */
import {
  ArrowDownward,
  Check,
  CheckCircleOutline,
  Close,
  CurrencyRupee,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useCreateEducatorOnboardPaymentOrderIdMutation,
  useCreateEducatorOnboardWithPaymentMutation,
} from '../../../app/services/EducatorSubscriptionPlansApi';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const RAZORPAKEY = process.env.REACT_APP_RAZORPAY_KEY;
export const EducatorSubscriptionPlan = ({ selectedPaymentDuration }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  let [totalCost, setTotalCost] = useState();
  const [createOnboardingId, res] = useCreateEducatorOnboardPaymentOrderIdMutation();

  const [createSubscriptionPlanPayment, result1] =
    useCreateEducatorOnboardWithPaymentMutation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  function checkIfBoolean(value) {
    return typeof value === 'boolean';
  }
  const plan = {
    plans: [
      {
        name: 'Trail Plan',
        price: {
          annual_monthly: 0,
          annual: 0,
          monthly: 0,
        },
        discount: {
          annual: 0,
        },
        deatils: `Ideal for new instructors
      testing the platform's
      capabilities.`,
      },
      {
        name: 'Starter Plan',
        price: {
          annual_monthly: 900,
          annual: 10800,
          monthly: 1000,
        },
        discount: {
          annual: 10,
        },
        deatils: `Ideal for: Freelance
        teachers and YouTubers
        starting to monetize their
        content.`,
      },
      {
        name: 'Professional Plan',
        price: {
          annual_monthly: 2250,
          annual: 27000,
          monthly: 2500,
        },
        discount: {
          annual: 10,
        },
        deatils: `Ideal for: Established
        educators and trainers
        looking to expand their
        reach.`,
      },
      {
        name: 'Executive Plan',
        price: {
          annual_monthly: 4500,
          annual: 5000,
          monthly: 54000,
        },
        discount: {
          annual: 10,
        },
        deatils: `Ideal for: High-profile
        institutes and teachers
        with a significant online
        presence.`,
        buttonText: 'Advanced',
      },
      {
        name: 'Enterprise Plan',
        price: {
          annual_monthly: 'Custom',
          annual: 'Custom',
          monthly: 'Custom',
        },
        discount: {
          annual: 0,
        },
        deatils: `Ideal for: Large
        educational organizations
        requiring a white-labeled
        solution.`,
        buttonText: 'Contact Sales',
      },
    ],
    features: [
      {
        title: 'Core Feature',
        metaFeature: [
          {
            title: 'Number of Trainer Accounts',
            free: '1',
            staterPlan: 'Up to 1',
            professionalPlan: 'Up to 3',
            executivePlan: 'Up to 5',
            enterprisePlan: 'Unlimited',
          },
          {
            title: 'Number of Attendees',
            free: '1',
            staterPlan: 'Up to 20',
            professionalPlan: 'Up to 250',
            executivePlan: 'Up to 500',
            enterprisePlan: 'No Limit',
          },
          {
            title: 'Storage',
            free: '50 GB',
            staterPlan: '200 GB',
            professionalPlan: '500 GB',
            executivePlan: '1 TB',
            enterprisePlan: 'Custom',
          },
          {
            title: 'Bandwidth',
            free: '500 GB',
            staterPlan: '2 TB',
            professionalPlan: '5 TB',
            executivePlan: '10 TB',
            enterprisePlan: 'Custom',
          },
          {
            title: 'Video Resolution',
            free: '1',
            staterPlan: 'Up to 1',
            professionalPlan: 'Up to 3',
            executivePlan: 'Up to 5',
            enterprisePlan: 'Unlimited',
          },
        ],
      },
      {
        title: 'AI Feature',
        metaFeature: [
          {
            title: 'Audio to Text Generator',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Text to Audio Generator',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Multi-Language Converter',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Video Closed Captioning',
            free: true,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
        ],
      },
      {
        title: 'Video Management',
        metaFeature: [
          {
            title: 'Search by Course ID',
            free: true,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Search by Trainer ID',
            free: true,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Course Recommendation',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
        ],
      },
      {
        title: 'Marketing & Analytics',
        metaFeature: [
          {
            title: 'Viewer Analytics',
            free: 'Basic',
            staterPlan: 'Enhanced',
            professionalPlan: 'Enhanced',
            executivePlan: 'Enhanced',
            enterprisePlan: 'Advanced',
          },
          {
            title: 'Engagement Analytics',
            free: 'Basic',
            staterPlan: 'Enhanced',
            professionalPlan: 'Enhanced',
            executivePlan: 'Enhanced',
            enterprisePlan: 'Advanced',
          },
          {
            title: 'Team-Level Analytics',
            free: false,
            staterPlan: false,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
        ],
      },
      {
        title: 'Marketing and Distribution',
        metaFeature: [
          {
            title: 'Internal Promotion',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Custom Video Link',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Sell Videos as Subscription Service',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Video SEO',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Social Media Marketing',
            free: false,
            staterPlan: 'Basic',
            professionalPlan: 'Enhanced',
            executivePlan: 'Enhanced',
            enterprisePlan: 'Custom',
          },
          {
            title: 'Lead Generation',
            free: false,
            staterPlan: 'Basic',
            professionalPlan: 'Enhanced',
            executivePlan: 'Enhanced',
            enterprisePlan: 'Custom',
          },
        ],
      },
      {
        title: 'Support',
        metaFeature: [
          {
            title: 'Self-Serve Knowledge Base',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Email Support',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Chat Support',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Phone Support',
            free: false,
            staterPlan: true,
            professionalPlan: true,
            executivePlan: true,
            enterprisePlan: true,
          },
          {
            title: 'Account Manager',
            free: false,
            staterPlan: 'Basic',
            professionalPlan: 'Enhanced',
            executivePlan: 'Enhanced',
            enterprisePlan: 'Custom',
          },
          {
            title: 'Training',
            free: false,
            staterPlan: 'Basic',
            professionalPlan: 'Enhanced',
            executivePlan: 'Enhanced',
            enterprisePlan: 'Custom',
          },
        ],
      },
    ],
  };

  const onDashboardClick = () => {
    navigate('/educator/dashboard');
  };
  const showModal = () => {
    setVisible(true);
  };
  const createOrderId = async () => {
    // createSubscriptionPlan(subscriptionPlanData);

    const createOrderIdRequest = await createOnboardingId({
      amount: totalCost,
      currency: 'INR',
    });

    return createOrderIdRequest.data.orderId;
  };

  const razorPayPoUp = (orderId) => {
    const options = {
      key: RAZORPAKEY, // Enter the Key ID generated from the Dashboard
      amount: totalCost * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: `${process.env.REACT_APP_NAME}`,
      description: 'Transaction',
      image: '{ logo }',
      order_id: orderId,
      handler: async (response) => {
        if (!response.razorpay_signature) {
          showModal();
        } else {
          setLoading(true);

          const subscriptionRes = await createSubscriptionPlanPayment({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            order_id: orderId,
            amount: totalCost,
            email: user.email,
          });
          setSuccess(true);
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone_number,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#a544a5',
      },
    };
    if (typeof Razorpay !== 'undefined') {
      // eslint-disable-next-line no-undef
      const rzp1 = new Razorpay(options);
      // eslint-disable-next-line func-names
      rzp1.on('payment.failed', function (response) {
        // eslint-disable-next-line no-console
        console.log(response, 'failed');
      });

      rzp1.open();
    }
  };
  const handleRazorPayPayment = async () => {
    const orderId = await createOrderId();
    razorPayPoUp(orderId);
  };

  if (success && result1?.data?.response?.id) {
    return (
      <>
        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
          Successfully Onboarded.
        </Alert>
        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
          Amount Paid: {result1?.data?.response?.paid_amount}
        </Alert>{' '}
        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
          Status: {result1?.data?.response?.status}
        </Alert>
        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
          Transaction Id: {result1?.data?.response?.transaction_id}
        </Alert>
        <Button variant="contained" color="secondary" onClick={onDashboardClick}>
          Go To Dashboard{' '}
        </Button>
      </>
    );
  }

  if (res?.isError) {
    enqueueSnackbar(res.error?.data?.message, {
      variant: 'error',
    });
  }
  return (
    <Fragment>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js" />
      </Helmet>
      <Grid
        container
        spacing={2}
        sx={{ position: 'sticky', top: 0, zIndex: 1000, background: '#fff' }}
      >
        <Grid item xl={2} lg={2}></Grid>
        {plan?.plans.map((singlePlan) => (
          <Grid item xl={2} lg={2} key={singlePlan?.name}>
            <Box
              sx={{
                boxShadow: '1px 1px 10px 1px #dcdcdc',
                padding: '1rem',
                borderRadius: '20px',
                background: '#f2fdff',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
              component={'div'}
            >
              <div>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    color: '#000',
                    textAlign: 'center',
                  }}
                >
                  {singlePlan?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#000',
                    textAlign: 'center',
                  }}
                >
                  {singlePlan?.deatils}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    color: '#000',
                    textAlign: 'center',
                    marginTop: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CurrencyRupee />{' '}
                  {selectedPaymentDuration == 'Year'
                    ? singlePlan?.price?.annual_monthly
                    : singlePlan?.price?.monthly}
                </Typography>

                <Button
                  variant="contained"
                  sx={{
                    background: '#20b0c5',
                    width: '100%',
                    marginTop: '1rem',
                    ':hover': {
                      background: '#20b0c5',
                    },
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (singlePlan?.name == 'Trail Plan') {
                      isSignedIn
                        ? navigate('/', {
                            state: location.pathname,
                          })
                        : navigate('/educator-account', {
                            state: location.pathname,
                          });
                    } else if (
                      selectedPaymentDuration === 'Year' &&
                      singlePlan?.price?.annual_monthly > 0
                    ) {
                      totalCost = singlePlan?.price?.annual_monthly;
                      setTotalCost(totalCost);
                      isSignedIn
                        ? handleRazorPayPayment()
                        : navigate('/educator-account', {
                            state: location.pathname,
                          });
                    } else if (
                      selectedPaymentDuration === 'Month' &&
                      singlePlan?.price?.monthly > 0
                    ) {
                      totalCost = singlePlan?.price?.monthly;
                      setTotalCost(totalCost);
                      isSignedIn
                        ? handleRazorPayPayment()
                        : navigate('/educator-account', {
                            state: location.pathname,
                          });
                    }
                  }}
                  type="button"
                >
                  {singlePlan?.buttonText ? singlePlan?.buttonText : 'Get Sarted'}
                </Button>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
        {Array.isArray(plan?.features) &&
          plan?.features.length > 0 &&
          plan?.features.map((singleFeature) => (
            <Accordion
              key={singleFeature?.title}
              sx={{ width: '100%' }}
              expanded={true}
            >
              <AccordionSummary
                expandIcon={<ArrowDownward />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>
                  {singleFeature?.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                {Array.isArray(singleFeature.metaFeature) &&
                  singleFeature.metaFeature.length > 0 &&
                  singleFeature.metaFeature.map((singleMetaFeature) => (
                    <Grid container key={singleMetaFeature?.title}>
                      <Grid item xl={2} lg={2}>
                        <Typography
                          sx={{
                            textAlign: 'start',
                            marginLeft: '1rem',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                          title={singleMetaFeature?.title}
                        >
                          {singleMetaFeature?.title}
                        </Typography>
                      </Grid>
                      <Grid item xl={2} lg={2}>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            background: '#f2fdff',
                            marginLeft: '1rem',
                            lineHeight: '30px',
                          }}
                        >
                          {checkIfBoolean(singleMetaFeature?.free) ? (
                            <span>
                              {singleMetaFeature?.free === true ? (
                                <Check />
                              ) : (
                                <Close />
                              )}
                            </span>
                          ) : (
                            singleMetaFeature?.free
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xl={2} lg={2}>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            background: '#f2fdff',
                            marginLeft: '1rem',
                            lineHeight: '30px',
                          }}
                        >
                          {checkIfBoolean(singleMetaFeature?.staterPlan) ? (
                            <span>
                              {singleMetaFeature?.staterPlan === true ? (
                                <Check />
                              ) : (
                                <Close />
                              )}
                            </span>
                          ) : (
                            singleMetaFeature?.staterPlan
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xl={2} lg={2}>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            background: '#f2fdff',
                            marginLeft: '1rem',
                            lineHeight: '30px',
                          }}
                        >
                          {checkIfBoolean(singleMetaFeature?.professionalPlan) ? (
                            <span>
                              {singleMetaFeature?.professionalPlan === true ? (
                                <Check />
                              ) : (
                                <Close />
                              )}
                            </span>
                          ) : (
                            singleMetaFeature?.professionalPlan
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xl={2} lg={2}>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            background: '#f2fdff',
                            marginLeft: '1rem',
                            lineHeight: '30px',
                          }}
                        >
                          {checkIfBoolean(singleMetaFeature?.executivePlan) ? (
                            <span>
                              {singleMetaFeature?.executivePlan === true ? (
                                <Check />
                              ) : (
                                <Close />
                              )}
                            </span>
                          ) : (
                            singleMetaFeature?.executivePlan
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xl={2} lg={2}>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            background: '#f2fdff',
                            marginLeft: '1rem',
                            lineHeight: '30px',
                          }}
                        >
                          {checkIfBoolean(singleMetaFeature?.enterprisePlan) ? (
                            <span>
                              {singleMetaFeature?.enterprisePlan === true ? (
                                <Check />
                              ) : (
                                <Close />
                              )}
                            </span>
                          ) : (
                            singleMetaFeature?.enterprisePlan
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </Grid>
    </Fragment>
  );
};
