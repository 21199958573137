/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Typography,
  Grid,
  Box,
  CardActions,
  Paper,
  Alert,
  AlertTitle,
  Rating,
  Breadcrumbs,
  Avatar,
  CardActionArea,
  styled,
  Tooltip,
  tooltipClasses,
  CardMedia,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from '@mui/material';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { Link as BreadCrumbLinks } from '@mui/material/Link';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  useFollowCreatorMutation,
  usePublicCourseQuery,
} from '../../../app/services/PublicCoursesApi';

import moment from 'moment';

import { usePublishedSubscriptionPlansQuery } from '../../../app/services/SunscriptionPlansApi';
import HlsJs from '../HlsJs';
import DataLoader from '../../../components/DataLoader';
import { blue, deepOrange, grey } from '@mui/material/colors';
import { CastForEducation, People, Reviews, Star } from '@mui/icons-material';
import DisplayCourseCurriculum from '../public/DisplayCourseCurriculum';
import {
  useCourseEnrolMutation,
  useFollowUserMutation,
} from '../../../app/services/StudentCoursesApi';
import ReportCourseViolation from '../student/ReportCourseViolation';
function DisplayCoursePreview(props) {
  const { courseId, subject } = props;
  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { data, isLoading, isSuccess } = usePublicCourseQuery({
    id: parseInt(courseId, 10),
  });
  const [followUser, followResult] = useFollowCreatorMutation();

  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const queryRes = usePublishedSubscriptionPlansQuery();

  const [courseEnrol, result] = useCourseEnrolMutation();

  const StyledCardActionArea = styled(CardActionArea)(
    ({ theme }) => `
      .MuiCardActionArea-focusHighlight {
          background: transparent;
      }
  `
  );

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  if (isLoading) {
    return <DataLoader thickness={5} size={50} />;
  }

  const displayTopicCount = (subjects) => {
    let topicsCount = 0;
    if (subjects.length >= 1) {
      subjects.forEach(
        (singleSubject) => (topicsCount += singleSubject.topics.length)
      );
      return topicsCount;
    } else {
      return topicsCount;
    }
  };

  const displayEducatorAvgCourseRating = (courses) => {
    let avgRating = 5;
    let noOfRatings = 1;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        avgRating += Number(singleCourse.course_reviews_avg_rating);
        noOfRatings += Number(singleCourse.course_reviews_avg_rating) ? 1 : 0;
      });
      return Math.round((avgRating / noOfRatings) * 10) / 10;

      // return avgRating / noOfRatings;
    } else {
      return avgRating;
    }
  };

  const displayEducatorCourseEnrols = (courses) => {
    let enrols = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        enrols += Number(singleCourse.course_enrols_count);
      });
      return enrols;
    } else {
      return enrols;
    }
  };

  const displayReviewCounts = (courses) => {
    let reviewCounts = 0;
    if (courses.length >= 1) {
      courses.forEach((singleCourse) => {
        reviewCounts += Number(singleCourse.course_reviews_count);
      });
      return reviewCounts;
    } else {
      return reviewCounts;
    }
  };

  return isSuccess && data.course ? (
    <>
      <Grid
        container
        sx={{
          backgroundColor: '#dfecef',
          // display: 'grid',
          // justifyContent: 'center',
        }}
      >
        {/* <Grid item xs="10" sm="10" md="10" lg="10" xl="10">
          <Grid container> */}
        <Grid item xs="0" sm="0" md="12" lg="1" xl="1"></Grid>
        <Grid item xs="12" sm="12" md="12" lg="5" xl="5">
          <Breadcrumbs aria-label="breadcrumb">
            <Typography underline="hover" color="inherit" href="/">
              {data?.course?.grade_subject?.title}
            </Typography>
          </Breadcrumbs>
          <div style={{ width: '100%' }}>
            <Typography
              component="h2"
              variant="h3"
              sx={{
                fontSize: '24px',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
                textOverflow: 'ellipsis',
                WebkitLineClamp: '1',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
              // title={data.course.title}
            >
              <HtmlTooltip title={data.course.title}>
                {data.course.title}
              </HtmlTooltip>
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              sx={{
                fontSize: '18px',
              }}
            >
              {data?.course?.grade?.title
                ? `${data?.course?.grade?.title} | `
                : null}
              {data?.course?.grade_subject?.title
                ? `${data?.course?.grade_subject?.title} | `
                : null}
              {data?.course?.publication?.title
                ? `${data?.course?.publication?.title} | `
                : null}
              {`${
                data?.course?.publication_edition?.edition
                  ? data?.course?.publication_edition?.edition + ' Edition'
                  : ''
              }`}
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              className="font-16"
              sx={{
                marginTop: '1rem',
                textOverflow: 'ellipsis',
                WebkitLineClamp: '3',
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
              }}
            >
              {data.course.description}
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              className="font-16 first-letter-cap"
              sx={{ marginTop: '1rem' }}
            >
              {data?.course?.creator?.educator_info?.first_name
                ? `${data?.course?.creator?.educator_info?.first_name} ${
                    data?.course?.creator?.educator_info?.last_name ? '' : '|'
                  }`
                : null}
              {data?.course?.creator?.educator_info?.last_name
                ? `${data?.course?.creator?.educator_info?.last_name} | `
                : null}
              {data?.course?.creator?.educator_info?.location
                ? `${data?.course?.creator?.educator_info?.location} | `
                : null}
              <span>{`Explained in ${data.course.language}`}</span>
            </Typography>
            <Typography
              component="h2"
              variant="h4"
              className="font-16 first-letter-cap"
              sx={{
                marginTop: '1rem',
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                gap: '0.5rem',
              }}
            >
              <span className="first-letter-cap">
                {data.course.status === 'published'
                  ? `published on ${moment(data.course?.published_at).format(
                      'Do MMM YY '
                    )}`
                  : `created on ${moment(data.course?.created_at).format(
                      'Do MMM YY '
                    )}`}
                {/* {new Date(data.course.created_at).toLocaleDateString('en-US', {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })}{' '} */}
              </span>
              <span>
                {data?.course?.course_enrols_count
                  ? ` | ${data?.course?.course_enrols_count} Enrollments`
                  : ` | 0 Enrollments`}
              </span>

              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '0.5rem',
                }}
              >
                <span>
                  {' '}
                  {` | ${parseFloat(data?.course?.course_reviews_avg_rating).toFixed(
                    1
                  )}`}{' '}
                </span>
                <Rating
                  name="read-only"
                  value={Math.round(data.course.course_reviews_avg_rating * 10) / 10}
                  readOnly
                  precision={0.5}
                />
                {data.course.course_reviews_count ? (
                  <Typography>
                    &#40;{data.course.course_reviews_count}
                    &nbsp;reviews&#41;
                  </Typography>
                ) : (
                  <Typography>&#40;no reviews&#41;</Typography>
                )}
              </span>
            </Typography>
          </div>
        </Grid>
        <Grid item xs="12" sm="12" md="12" lg="5" xl="5">
          {data.course.promo_url ? (
            <HlsJs vidSrc={data.course.promo_url} />
          ) : data.course.video && data.course.video?.vid_url ? (
            <HlsJs vidSrc={data.course.video.vid_url} />
          ) : data.course?.published_video ? (
            <HlsJs vidSrc={data.course.published_video.vid_url} />
          ) : (
            <HlsJs vidSrc={''} />
          )}
        </Grid>
        <Grid item xs="0" sm="0" md="12" lg="1" xl="1"></Grid>
        {/* </Grid>
        </Grid> */}
      </Grid>
      <Grid
        container
        // sx={{ display: 'grid', justifyItems: 'center', background: '#fff' }}
        justifyContent={'center'}
      >
        <Grid item xs="10" sm="10" md="10" lg="10" xl="10">
          <Grid container>
            <Grid item xs="12" sm="12" md="12" lg="6" xl="6">
              <DisplayCourseCurriculum
                subjects={data.course.subjects}
                height={'570px'}
              />
            </Grid>
            <Grid item xs="12" sm="12" md="12" lg="6" xl="6">
              <Grid container>
                <Grid
                  item
                  xs="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  sx={{ padding: '0.5rem' }}
                >
                  <div>
                    <img
                      src={
                        data.course.url
                          ? data.course.url
                          : 'https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80'
                      }
                      style={{ width: '100%', height: '500px' }}
                    />
                  </div>
                </Grid>
                <Grid item xs="12" sm="12" md="12" lg="6" xl="6">
                  <div style={{ padding: '1rem' }}>
                    <Typography className="font-19 font-700">
                      This Course Includes
                    </Typography>
                    <Typography
                      className="font-17 font-700"
                      sx={{ marginTop: '1rem' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                        }}
                      >
                        <img
                          style={{ height: '30px', width: '30px' }}
                          src="/courseinformation/lessonIcon.png"
                        />
                        {`Lessons ${
                          data.course?.subjects?.length
                            ? data.course?.subjects?.length
                            : 0
                        }`}{' '}
                      </div>
                    </Typography>
                    <Typography
                      className="font-17 font-700"
                      sx={{ marginTop: '1rem' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                        }}
                      >
                        <img
                          style={{ height: '30px', width: '30px' }}
                          src="/courseinformation/lessonIcon.png"
                        />
                        {`Topics ${displayTopicCount(data.course.subjects)}`}
                      </div>
                    </Typography>
                    <Typography
                      className="font-17 font-700"
                      sx={{ marginTop: '1rem' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                        }}
                      >
                        <img
                          style={{ height: '30px', width: '30px' }}
                          src="/courseinformation/totalVideo.png"
                        />
                        {`Total Videos ${
                          data.course.published_video_count +
                          data.course.published_subject_video_count +
                          data.course.published_subject_video_count +
                          data.course.published_topic_video_count +
                          data.course.published_sub_topic_video_count
                        }`}
                      </div>
                    </Typography>
                    <Typography
                      className="font-17 font-700"
                      sx={{ marginTop: '1rem' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '1rem',
                        }}
                      >
                        <img
                          style={{ height: '30px', width: '30px' }}
                          src="/courseinformation/duration.png"
                        />
                        {`Duration ${moment
                          .utc(
                            (Number(data.course.published_video_sum_duration) +
                              Number(
                                data.course.published_subject_video_sum_duration
                              ) +
                              Number(
                                data.course.published_topic_video_sum_duration
                              ) +
                              Number(
                                data.course.published_sub_topic_video_sum_duration
                              )) *
                              1000
                          )
                          .format('HH:mm:ss')} `}
                      </div>
                    </Typography>
                  </div>
                </Grid>
                <Grid
                  item
                  xs="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  sx={{ padding: '0.5rem' }}
                >
                  <CardActions sx={{ padding: 0 }}>
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{
                        width: '100%',
                        height: '50px',
                        background: '#09768d',
                        fontSize: '1.5rem',
                      }}
                    >
                      Enroll
                    </Button>
                  </CardActions>
                </Grid>
                <Grid
                  item
                  xs="12"
                  sm="12"
                  md="12"
                  lg="6"
                  xl="6"
                  sx={{ padding: '0.5rem' }}
                >
                  <Button
                    variant="contained"
                    sx={{
                      width: '100%',
                      height: '50px',
                      background: '#ffbb02',
                      fontSize: '1.5rem',
                    }}
                  >
                    Watch Later
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: '2rem' }}>
            <Grid item xs="12" sm="12" md="12" lg="12" xl="12">
              <Typography
                sx={{ fontSize: '28px', fontWeight: 600, marginLeft: '0.5rem' }}
              >
                Instructor
              </Typography>
            </Grid>
            <Grid
              item
              xs="12"
              sm="12"
              md="12"
              lg="2"
              xl="2"
              sx={{ padding: '0.5rem' }}
            >
              {!data.course.creator.educator_info?.image_url ? (
                <IconButton variant="contained">
                  <img src="/instructor.png" style={{ width: '100%' }} />
                </IconButton>
              ) : (
                <>
                  <IconButton variant="contained">
                    <Avatar
                      variant="square"
                      sx={{
                        height: '150px',
                        width: '150px',
                        border: '1px solid',
                      }}
                      alt={data.course.creator.name.charAt(0).toUpperCase()}
                      src={
                        data.course.creator.educator_info?.image_url
                          ? data.course.creator.educator_info.image_url
                          : null
                      }
                    ></Avatar>
                  </IconButton>
                </>
              )}
              <Button
                variant="contained"
                sx={{ width: '100%', marginTop: '0.5rem', borderRadius: '20px' }}
                disabled={data.course.alreadyFollowed}
              >
                {'Follow'}
              </Button>

              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 500,
                  color: '#1c1d1f',
                  marginTop: '0.5rem',
                  display: 'flex',
                  alignItems: 'unset',
                  gap: '0.5rem',
                }}
              >
                <Star sx={{ fontSize: '1rem' }} />
                {/* 4.6 Instructor Rating */}
                {data.course.creator?.educator_course_enrols_and_reviews
                  ? displayEducatorAvgCourseRating(
                      data.course.creator.educator_course_enrols_and_reviews
                    )
                  : 'No'}
                &nbsp; Educator Rating
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 500,
                  color: '#1c1d1f',
                  marginTop: '0.2rem',
                  display: 'flex',
                  alignItems: 'unset',
                  gap: '0.5rem',
                }}
              >
                <Reviews sx={{ fontSize: '1rem' }} />
                {/* 18,534 Reviews */}
                {data.course.creator?.educator_course_enrols_and_reviews
                  ? displayReviewCounts(
                      data.course.creator?.educator_course_enrols_and_reviews
                    )
                  : 'No'}
                &nbsp;Reviews
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 500,
                  color: '#1c1d1f',
                  marginTop: '0.2rem',
                  display: 'flex',
                  alignItems: 'unset',
                  gap: '0.5rem',
                }}
              >
                <People sx={{ fontSize: '1rem' }} />
                {/* 115,904 Students */}
                {data.course.creator?.educator_course_enrols_and_reviews
                  ? displayEducatorCourseEnrols(
                      data.course.creator?.educator_course_enrols_and_reviews
                    )
                  : 'No'}
                &nbsp;Students
              </Typography>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 500,
                  color: '#1c1d1f',
                  marginTop: '0.2rem',
                  display: 'flex',
                  alignItems: 'unset',
                  gap: '0.5rem',
                }}
              >
                <CastForEducation sx={{ fontSize: '1rem' }} />
                {/* 13 Courses */}
                {data.course.creator?.educator_course_enrols_and_reviews
                  ? data.course.creator?.educator_course_enrols_and_reviews?.length
                  : 'No'}
                &nbsp;Courses
              </Typography>
            </Grid>
            <Grid
              item
              xs="12"
              sm="12"
              md="12"
              lg="10"
              xl="10"
              sx={{ padding: '0.5rem' }}
            >
              <Typography
                className="font-19 font-700 first-letter-cap"
                sx={{ color: '#ee3264' }}
              >
                {data?.course?.creator.educator_info
                  ? data?.course?.creator.educator_info?.first_name ||
                    data?.course?.creator.name
                  : null}
                {data?.course?.creator.educator_info
                  ? ` ${data?.course?.creator.educator_info?.last_name}` || ''
                  : null}
                {data?.course?.institution_info
                  ? data?.course?.institution_info?.first_name ||
                    data?.course?.creator.name
                  : null}{' '}
                {!data?.course?.creator.educator_info &&
                !data?.course?.institution_info
                  ? data?.course?.creator.name
                  : null}
              </Typography>
              <Typography className="font-17 font-500">
                {data?.course?.creator.educator_info
                  ? `${data?.course?.creator.educator_info?.subject}`
                  : ''}
                {data?.course?.creator?.educator_info?.qualification
                  ? ` | ${data?.course?.creator.educator_info?.qualification}`
                  : ''}
              </Typography>
              <Typography className="font-17 font-500">
                {data.course.creator.followers} Followers
              </Typography>
              <Typography className="font-14 font-500" sx={{ marginTop: '1rem' }}>
                {data?.course?.creator.educator_info
                  ? data?.course?.creator.educator_info?.bio ||
                    data?.course?.creator.educator_info?.subject
                  : null}

                {data?.course?.institution_info
                  ? data?.course?.institution_info?.name ||
                    data?.course?.institution_info.name
                  : null}
              </Typography>
            </Grid>
          </Grid>
          <Grid container sx={{ marginTop: '4rem' }}>
            {data?.course?.course_reviews?.slice(0, 4).map((item, i) => (
              <Grid key={i} item xs="12" sm="12" md="6" lg="3" xl="3">
                <div style={{ padding: '0.5rem' }}>
                  <hr />
                  <Grid container>
                    <Grid item xs="3" sm="3" md="3" lg="3" xl="3">
                      <Avatar sx={{ bgcolor: deepOrange[500] }}>
                        {item?.review_creator?.name?.charAt()?.toUpperCase()}
                      </Avatar>
                    </Grid>
                    <Grid
                      style={{ display: 'grid' }}
                      xs="6"
                      sm="6"
                      md="6"
                      lg="6"
                      xl="6"
                    >
                      <span style={{}} className="font-14 font-700">
                        {item?.review_creator?.name}
                      </span>
                      <Rating value={Math.round(item.rating * 10) / 10} readOnly />
                    </Grid>
                    <Grid xs="3" sm="3" md="3" lg="3" xl="3"></Grid>
                  </Grid>
                  <div>
                    <Typography
                      className="font-14 font-500"
                      sx={{ marginTop: '2rem' }}
                    >
                      {item.review_text}
                    </Typography>
                  </div>
                </div>
              </Grid>
            ))}
          </Grid>
          <Grid container sx={{ marginTop: '4rem' }} spacing={2}>
            <Grid item xs="12" sm="12" md="6" lg="12" xl="12">
              <Typography className="font-19 font-700">
                {`More Courses By `}
                {data?.course?.creator.educator_info
                  ? data?.course?.creator.educator_info?.first_name ||
                    data?.course?.creator.name
                  : null}
                {data?.course?.institution_info
                  ? data?.course?.institution_info?.name ||
                    data?.course?.creator.name
                  : null}{' '}
                {!data?.course?.creator.educator_info &&
                !data?.course?.institution_info
                  ? data?.course?.creator.name
                  : null}
              </Typography>
            </Grid>
            {data.course.moreCourses?.length >= 1
              ? data.course.moreCourses
                  .filter((singleCourse) => singleCourse.id != data.course.id)
                  .map((singleCourse) => (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={3}
                      xl={3}
                      key={singleCourse.id}
                    >
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              <em>{singleCourse.title}</em>
                            </Typography>
                            <Typography color="inherit">
                              <em>
                                {singleCourse.sub_category?.title} |{' '}
                                {singleCourse.grade?.title}|{' '}
                                {singleCourse.grade_subject?.title} |
                              </em>
                            </Typography>
                            <Typography color="inherit">
                              <em>
                                {singleCourse.publication?.title} |{' '}
                                {singleCourse.publication_edition?.edition} Edition
                              </em>
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              color="text.secondary"
                              gutterBottom
                            >
                              By{' '}
                              {singleCourse.creator.educator_info
                                ? singleCourse.creator.educator_info?.first_name ||
                                  singleCourse.creator.name
                                : null}
                              {singleCourse.institution_info
                                ? singleCourse.institution_info?.name ||
                                  singleCourse.creator.name
                                : null}{' '}
                              {!singleCourse.creator.educator_info &&
                              !singleCourse.institution_info
                                ? singleCourse.creator.name
                                : null}
                            </Typography>
                            <Typography
                              paragraph
                              variant="body1"
                              gutterBottom
                            ></Typography>
                          </React.Fragment>
                        }
                        arrow
                        placement="right"
                      >
                        <StyledCardActionArea
                          component="a"
                          // onClick={() => onCourseClick(singleCourse.id)}
                          sx={{
                            padding: '6px',
                            border: '1.5px solid #a0a5a7',
                            borderRadius: '10px',
                            position: 'relative',
                            height: '360px',
                            background: '#fff',
                          }}
                        >
                          <Card sx={{ boxShadow: 'none' }}>
                            <CardMedia
                              sx={{
                                height: 130,
                                boxShadow: 'none',
                                border: '1.5px solid #a0a5a7',
                                borderRadius: '10px 10px 0px 0px',
                              }}
                              image={`https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`}
                              title={singleCourse?.title}
                            />
                            <CardContent
                              sx={{
                                flex: 1,
                                overflowWrap: 'anywhere',
                                padding: '10px 0px 0px 0px',
                              }}
                            >
                              <>
                                <Typography
                                  color="inherit"
                                  sx={{ lineHeight: '1.2' }}
                                >
                                  {singleCourse.title
                                    ? ` ${singleCourse.title}`
                                    : null}
                                  {singleCourse.sub_category?.title
                                    ? ` | ${singleCourse.sub_category?.title}`
                                    : null}
                                  {singleCourse.grade?.title
                                    ? ` | ${singleCourse.grade?.title}`
                                    : null}
                                  {singleCourse.grade_subject?.title
                                    ? ` | ${singleCourse.grade_subject?.title}`
                                    : null}
                                  {singleCourse.publication?.title
                                    ? ` | ${singleCourse.publication?.title}`
                                    : null}
                                  {singleCourse.publication_edition?.edition
                                    ? ` | ${singleCourse.publication_edition?.edition} Edition`
                                    : null}
                                </Typography>
                                {/* <Typography
                                  color="inherit"
                                  sx={{ lineHeight: '1.2' }}
                                >
                                  {singleCourse.sub_category?.title} |{' '}
                                  {singleCourse.grade?.title}|{' '}
                                  {singleCourse.grade_subject?.title} |
                                </Typography>
                                <Typography
                                  color="inherit"
                                  sx={{ lineHeight: '1.2' }}
                                >
                                  {singleCourse.publication?.title} |{' '}
                                  {singleCourse.publication_edition?.edition} Edition
                                </Typography> */}
                              </>

                              <Typography
                                sx={{
                                  padding: '10px 10px 0px 10px',
                                }}
                                variant="subtitle2"
                                color="text.secondary"
                              >
                                {singleCourse.creator.educator_info
                                  ? singleCourse.creator.educator_info?.first_name ||
                                    singleCourse.creator.name
                                  : null}
                                {singleCourse.institution_info
                                  ? singleCourse.institution_info?.name ||
                                    singleCourse.creator.name
                                  : null}{' '}
                                {!singleCourse.creator.educator_info &&
                                !singleCourse.institution_info
                                  ? singleCourse.creator.name
                                  : null}
                              </Typography>

                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '0px 10px 0px 10px',
                                }}
                              >
                                <span>
                                  <Rating
                                    name="read-only"
                                    value={
                                      Math.round(
                                        singleCourse.course_reviews_avg_rating * 10
                                      ) / 10
                                    }
                                    readOnly
                                    precision={0.5}
                                  />
                                </span>
                                {singleCourse.course_reviews_count ? (
                                  <Typography>
                                    &#40;{singleCourse.course_reviews_count}
                                    &nbsp;reviews&#41;
                                  </Typography>
                                ) : (
                                  <Typography>&#40;no reviews&#41;</Typography>
                                )}
                              </Box>
                              <Box
                                sx={{
                                  display: 'flex',
                                  padding: '0px 10px 20px 10px',
                                  justifyContent: 'space-between',
                                  position: 'absolute',
                                  bottom: '0px',
                                  width: '-webkit-fill-available',
                                }}
                              >
                                <div>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '12px' }}
                                  >
                                    {`Lessons ${
                                      singleCourse?.subjects?.length
                                        ? singleCourse?.subjects?.length
                                        : 0
                                    }`}{' '}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '12px' }}
                                  >
                                    {`Total Videos ${
                                      singleCourse.published_video_count +
                                      singleCourse.published_subject_video_count +
                                      singleCourse.published_subject_video_count +
                                      singleCourse.published_topic_video_count +
                                      singleCourse.published_sub_topic_video_count
                                    }`}{' '}
                                  </Typography>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{ fontSize: '12px' }}
                                  >
                                    {`Duration ${moment
                                      .utc(
                                        (Number(
                                          singleCourse.published_video_sum_duration
                                        ) +
                                          Number(
                                            singleCourse.published_subject_video_sum_duration
                                          ) +
                                          Number(
                                            singleCourse.published_topic_video_sum_duration
                                          ) +
                                          Number(
                                            singleCourse.published_sub_topic_video_sum_duration
                                          )) *
                                          1000
                                      )
                                      .format('HH:mm:ss')} `}
                                  </Typography>
                                </div>
                                <div style={{ display: 'grid', gap: '0.5rem' }}>
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{
                                      fontSize: '8px',
                                      padding: '0.1rem',
                                      height: '20px',
                                      background: '#3ec6ff',
                                    }}
                                  >
                                    Enroll
                                  </Button>
                                  <Button
                                    variant="contained"
                                    sx={{
                                      fontSize: '8px',
                                      padding: '0.1rem',
                                      height: '20px',
                                      background: '#6a17b2',
                                    }}
                                  >
                                    Watch Later
                                  </Button>
                                </div>
                              </Box>
                            </CardContent>
                          </Card>
                        </StyledCardActionArea>
                      </HtmlTooltip>
                    </Grid>
                  ))
              : null}
          </Grid>
          <Button
            variant="contained"
            sx={{
              width: '100%',
              marginTop: '1rem',
              fontSize: '1.5rem',
              background: '#d4dcde',
              color: '#444444',
              fontWeight: 700,
              border: '1px solid',
            }}
          >
            Report Abuse
          </Button>
        </Grid>
      </Grid>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Report Abuse</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Flagged content is reviewed by {`${process.env.REACT_APP_NAME}`} staff to
            determine whether it violates Terms of Service or Community Guidelines.
            If you have a question or technical issue, please contact our Support
            team here.
          </DialogContentText>
          <ReportCourseViolation />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
        </DialogActions>
      </Dialog>
    </>
  ) : (
    <p>No course found</p>
  );
}

export default DisplayCoursePreview;
