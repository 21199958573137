/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import { Clear, ExpandMore, Info } from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  AlertTitle,
  Tooltip,
  tooltipClasses,
  TextField,
  InputAdornment,
  IconButton,
  styled,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { groupBy, toUpper } from 'lodash';

import { TableVirtuoso } from 'react-virtuoso';
import { useDeleteDocumentMutation } from '../../../../app/services/CoursesApi';
import DeleteIcon from '@mui/icons-material/Delete';
import DisplayAdminDocument from './DisplayAdminDocument';
import ChangeDocumentStatus from './ChangeDocumentStatus';
import { useSnackbar } from 'notistack';
import SearchIcon from '@mui/icons-material/Search';
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    minWidth: 520,
  },
}));
const columns = [
  {
    width: 20,
    label: '#',
  },
  {
    width: 120,
    label: 'Uploader Name',
  },
  {
    width: 200,
    label: 'Email',
  },
  {
    width: 120,
    label: 'Phone Number',
  },
  {
    width: 120,
    label: 'Created On',
  },
  {
    width: 120,
    label: 'Document Name',
  },
  {
    width: 200,
    label: 'Status',
  },
  {
    width: 120,
    label: 'Course',
  },
  {
    width: 120,
    label: 'Subject',
  },
  {
    width: 120,
    label: 'Topic',
  },

  {
    width: 120,
    label: 'View',
  },

  {
    width: 120,
    label: 'Delete',
  },
];

const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function generateGroupedDocuments(documents) {
  const groupedUsers = groupBy(documents, (document) => document.title[0]);
  const groupCounts = Object.values(groupedUsers).map((users) => users.length);
  const groups = Object.keys(groupedUsers);

  return { groupCounts, groups };
}

function DocumentsList(props) {
  const { documents } = props;

  const navigate = useNavigate();
  const virtuoso = useRef(null);
  const [deleteDocument, deletionResult] = useDeleteDocumentMutation();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const [selectedDocument, setSelectedDocument] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [filteredDocuments, setFilteredDocuments] = useState(
    documents ? documents : []
  );

  const { enqueueSnackbar } = useSnackbar();
  const handleClose = () => {
    setIsOpen(false);
  };

  const changeStatus = (e, document) => {
    e.stopPropagation();
    setSelectedDocument(document);
    setIsOpen(true);
  };

  const viewDocument = (e, document) => {
    e.stopPropagation();
    setSelectedDocument(document);
    setIsOpen1(true);
  };

  const handleClose1 = () => {
    setIsOpen1(false);
  };

  const { groups, groupCounts } = generateGroupedDocuments(documents);

  const onDelete = (id) => {
    const res = deleteDocument({ id });
  };

  const onSearchClick = (inputSearchValue) => {
    if (inputSearchValue.trim().length === 0) {
      enqueueSnackbar('Value cannot be empty or contain only spaces', {
        variant: 'error',
      });

      return;
    } else {
      let finalFilterCourseDocuments = documents.filter((elem) =>
        elem?.topic?.subject?.course?.title
          .toLowerCase()
          .includes(inputSearchValue.toLowerCase())
      );
      setFilteredDocuments([...finalFilterCourseDocuments]);
    }
  };

  const onClearSearchClick = () => {
    setInputValue('');

    setFilteredDocuments([...documents]);
  };
  return (
    <>
      <ul
        style={{
          marginLeft: '0.5rem',
          paddingLeft: '0',
          listStyle: 'none',
          fontSize: '0.8rem',
          display: 'flex',
        }}
      >
        {groupCounts
          .reduce(
            ({ firstItemsIndexes, offset }, count) => {
              return {
                firstItemsIndexes: [...firstItemsIndexes, offset],
                offset: offset + count,
              };
            },
            { firstItemsIndexes: [], offset: 0 }
          )
          .firstItemsIndexes.map((itemIndex, index) => (
            <li key={index}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  virtuoso.current.scrollToIndex({
                    index: itemIndex,
                  });
                }}
              >
                {toUpper(groups[index])}
              </a>
              &nbsp;
            </li>
          ))}
      </ul>{' '}
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <TextField
          placeholder="Search documents by course name"
          required
          onChange={(_event) => {
            setInputValue(_event.target.value);
          }}
          value={inputValue}
          InputProps={{
            type: '',
            endAdornment: (
              <InputAdornment position="end">
                {inputValue && (
                  <IconButton
                    onClick={() => onClearSearchClick()}
                    // disabled={!inputValue.length}
                  >
                    <Clear />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => onSearchClick(inputValue)}
                  // disabled={!inputValue.length}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          size="small"
          sx={{ marginRight: '0.5rem' }}
        />
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography
                component="div"
                variant="h5"
                sx={{ padding: 4, color: '#233c8d' }}
              >
                All uploaded documents
              </Typography>
              <Alert severity="info">
                <>
                  <AlertTitle>
                    All uploaded documents are visible here with their upload status
                    only to admin.
                  </AlertTitle>
                </>
              </Alert>
              <Alert severity="info">
                <>
                  <AlertTitle>Status:</AlertTitle>
                  <AlertTitle>
                    DRAFT - Document upload completed but is in not published.
                  </AlertTitle>
                  <AlertTitle>
                    COMPLETED - Document upload completed but document only visible
                    to admin and uploader(Educator or Institution).
                  </AlertTitle>
                  <AlertTitle>
                    PUBLISHED - Document Visible to Enroled Student,
                    uploader(Educator or Institution) and admin.
                  </AlertTitle>
                  <AlertTitle>
                    BLOCKED - Document blocked for some reason(like wrong document
                    for the topic,, inappropriate document etc). Document only
                    visible to admin and uploader(Educator or Institution).
                  </AlertTitle>
                </>
              </Alert>
              <Alert severity="info">
                <>
                  <AlertTitle>
                    Click on the document status button(displayed with current
                    status) under status column to change the respective document
                    status.
                  </AlertTitle>
                </>
              </Alert>
            </React.Fragment>
          }
        >
          <Info sx={{ marginRight: '0.5rem' }} />
        </HtmlTooltip>
      </div>
      <Paper style={{ height: 400, width: '100%' }}>
        <TableVirtuoso
          data={filteredDocuments}
          ref={virtuoso}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={(index, singleDocument) => (
            <>
              <TableCell>{index + 1}</TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip title={singleDocument.creator.name}>
                  {singleDocument.creator.name}
                </Tooltip>
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip title={singleDocument.creator.email}>
                  {singleDocument.creator.email}
                </Tooltip>
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                {singleDocument.creator.phone_number}
              </TableCell>
              <TableCell>
                {moment(singleDocument.created_at).format('Do MMM YY hh:mm A')}
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip title={singleDocument.title}>
                  {singleDocument.title}
                </Tooltip>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={(e) => changeStatus(e, singleDocument)}
                >
                  {singleDocument.status}
                </Button>
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip
                  title={
                    singleDocument.topic
                      ? singleDocument.topic.subject.course.title
                      : null
                  }
                >
                  {singleDocument.topic
                    ? singleDocument.topic.subject.course.title
                    : null}
                </Tooltip>
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip
                  title={
                    singleDocument.topic ? singleDocument.topic.subject.title : null
                  }
                >
                  {singleDocument.topic ? singleDocument.topic.subject.title : null}
                </Tooltip>
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip
                  title={singleDocument.topic ? singleDocument.topic.title : null}
                >
                  {singleDocument.topic ? singleDocument.topic.title : null}
                </Tooltip>
              </TableCell>

              <TableCell>
                <Button
                  // sx={{ pl: 4 }}
                  onClick={(e) => viewDocument(e, singleDocument)}
                  variant="contained"
                >
                  View
                </Button>
              </TableCell>

              <TableCell>
                <Button
                  // sx={{ pl: 4 }}
                  onClick={() => onDelete(singleDocument.id)}
                  endIcon={<DeleteIcon />}
                  variant="contained"
                >
                  Delete
                </Button>
              </TableCell>
            </>
          )}
        />
      </Paper>
      <>
        {selectedDocument ? (
          <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle> Change {selectedDocument.title} Status</DialogTitle>
            <DialogContent>
              <ChangeDocumentStatus document={selectedDocument} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        ) : null}

        {selectedDocument && isOpen1 ? (
          <Dialog open={isOpen1} onClose={handleClose1} maxWidth="md">
            <DialogTitle> {selectedDocument.title} </DialogTitle>
            <DialogContent>
              <DisplayAdminDocument doc={selectedDocument} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose1}>Close</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    </>
  );
}

export default DocumentsList;
