import {
  Button,
  Checkbox,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  Box,
  Avatar,
  InputLabel,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useSelector } from 'react-redux';
import { Link, Navigate } from 'react-router-dom';
import { useLocation } from 'react-router';
import { useSnackbar } from 'notistack';
import { useCreateStudentUserMutation } from '../../../app/services/AuthApi';
import { useState } from 'react';
import { PasswordEye } from './PasswordEye';
import { validatePhoneNumber } from '../../../Uitils';

const theme = createTheme();

// Sign Up Page with ui form for new user
function StudentSignUpPage() {
  // const { changeView } = props;
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [createStudentUser, result] = useCreateStudentUserMutation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const { isSignedIn } = useSelector((state) => state.auth);
  const location = useLocation();

  const textFieldStyling = {
    '& .MuiOutlinedInput-root': {
      backgroundColor: 'rgba(255, 255, 255, 1)', // Background color
      borderRadius: '6px',
      '&:hover fieldset': {
        borderColor: 'white', // Change the border color on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: 'white', // Change the border color when focused
      },
      '& fieldset': {
        borderColor: 'white', // Default border color
      },
    },
    '& .MuiInputLabel-root.Mui-focused': {
      color: 'black', // Label color when focused
    },
    '& input[type=number]': {
      '-moz-appearance': 'textfield', // Firefox
    },
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
      {
        '-webkit-appearance': 'none', // Chrome, Safari, Edge, Opera
        margin: 0,
      },
  };
  const ValidateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.match(mailFormat)) {
      return true;
    }
    return false;
  };
  const validateData = (authData) => {
    let err = true;

    if (
      !authData.name ||
      !authData.email ||
      !authData.phoneNumber ||
      !authData.password ||
      !authData.confirmPassword
    ) {
      enqueueSnackbar('All fields are required', { variant: 'error' });
    } else if (termsAndConditions !== true) {
      enqueueSnackbar('You must agree to terms and Conditions.', {
        variant: 'error',
      });
    } else if (authData.password !== authData.confirmPassword) {
      enqueueSnackbar('Confirm password did not match.', { variant: 'error' });
    } else if (authData.password.length < 8) {
      enqueueSnackbar('Password length must be at least 8 chars', {
        variant: 'error',
      });
    } else if (authData.phoneNumber.length !== 10) {
      enqueueSnackbar(' Please provide a valid Phone Number ', {
        variant: 'error',
      });
    } else if (!validatePhoneNumber(authData.phoneNumber)) {
      enqueueSnackbar(' Please provide a valid Phone Number ', {
        variant: 'error',
      });
    } else if (!ValidateEmail(authData.email)) {
      enqueueSnackbar('Please enter a valid Email address', { variant: 'error' });
    } else if (/\s/.test(authData.password)) {
      enqueueSnackbar('Password should not contain spaces.', { variant: 'error' });
    } else {
      err = false;
    }

    return err;
  };

  // Hande submit of the form
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const authData = {
      name: data.get('name'),
      email: data.get('email'),
      phoneNumber: data.get('phone'),
      password: data.get('password'),
      confirmPassword: data.get('confirmPassword'),
    };
    const res = validateData(authData);
    if (!res) {
      createStudentUser(authData)
        .unwrap()
        // eslint-disable-next-line no-unused-vars
        .then((response) => {
          enqueueSnackbar('Registration Success. Please Verify Your Email.', {
            variant: 'success',
          });
        })
        .catch((err) => {
          if (err.data.errors.email) {
            enqueueSnackbar('Email already exists', {
              variant: 'error',
            });
          } else if (err.data.errors.phone_number) {
            enqueueSnackbar('Phone Number already exists', {
              variant: 'error',
            });
          } else {
            enqueueSnackbar('SignUp Error', {
              variant: 'error',
            });
          }
        });
    }
  };

  if (isSignedIn) {
    return location.state ? (
      <Navigate replace to={location.state} />
    ) : (
      <Navigate replace to="/" />
    );
  }
  // const openLoginTab = (e) => changeView(e, 0);

  const handleChange = () => {
    setTermsAndConditions(!termsAndConditions);
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            border: '2px solid #60696e',
            borderRadius: '10px',
            marginTop: '5rem',
          }}
        >
          <CssBaseline />
          <Typography
            style={{
              font: 800,
              position: 'relative',
              top: '-3rem',
              color: '#f6b605',
              fontSize: '20px',
            }}
            align="center"
          >
            Transforming Education In To Digital
          </Typography>
          <Box
            sx={{
              marginTop: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <>
              <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                style={{
                  color: '#f6b605',
                }}
              >
                Create Your Student Account
              </Typography>
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                sx={{ mt: 3 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel sx={{ color: '#fff' }}>Name</InputLabel>
                    <TextField
                      autoComplete="given-name"
                      name="name"
                      required
                      fullWidth
                      id="name"
                      autoFocus
                      size="small"
                      sx={{ ...textFieldStyling }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel sx={{ color: '#fff' }}>Email Address</InputLabel>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      name="email"
                      autoComplete="email"
                      size="small"
                      sx={{ ...textFieldStyling }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel sx={{ color: '#fff' }}>Phone Number</InputLabel>
                    <TextField
                      required
                      fullWidth
                      id="phone"
                      name="phone"
                      autoComplete="phone"
                      size="small"
                      sx={{ ...textFieldStyling }}
                      InputProps={{
                        inputMode: 'numeric',
                        maxLength: 10,
                      }}
                      type="number"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel sx={{ color: '#fff' }}>Password</InputLabel>
                    <div style={{ position: 'relative' }}>
                      <TextField
                        required
                        fullWidth
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="new-password"
                        size="small"
                        sx={{ ...textFieldStyling }}
                      />
                      <PasswordEye
                        stylingEye={{
                          position: 'absolute',
                          right: '1rem',
                          top: '1.3rem',
                        }}
                        showPassword={showPassword}
                        setShowPassword={setShowPassword}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <InputLabel sx={{ color: '#fff' }}>Confirm Password</InputLabel>
                    <div style={{ position: 'relative' }}>
                      <TextField
                        required
                        fullWidth
                        name="confirmPassword"
                        type={showConfirmPassword ? 'text' : 'password'}
                        id="confirmPassword"
                        autoComplete="new-password"
                        size="small"
                        sx={{ ...textFieldStyling }}
                      />
                      <PasswordEye
                        stylingEye={{
                          position: 'absolute',
                          right: '1rem',
                          top: '1.3rem',
                        }}
                        showPassword={showConfirmPassword}
                        setShowPassword={setShowConfirmPassword}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <Checkbox
                      id="termsAndConditions"
                      color="primary"
                      onChange={handleChange}
                    />
                    <span>
                      I agree with&nbsp;
                      <Link
                        to="/terms-conditions"
                        style={{
                          color: '#fff',
                          textDecoration: 'underline !important',
                        }}
                        className="decoration-underline"
                      >
                        Terms and Conditions
                      </Link>
                    </span>
                  </Grid>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                  disabled={result.isLoading || result.isSuccess}
                >
                  Sign Up
                </Button>

                {/* <Grid container justify="flex-end">
                  <Grid item>
                    <Button onClick={openLoginTab}>Go to Sign In</Button>
                  </Grid>
                </Grid> */}
              </Box>
            </>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
}

export default StudentSignUpPage;
