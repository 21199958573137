import { Box, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';

export const SubscriptionCancellationPolicy = () => {
  const pageData = [
    {
      title: 'Cancellation by User',
      list: [
        {
          title: '',
          data: `Users can cancel their subscription at any time through their account settings`,
        },
        {
          title: '',
          data: `Upon cancellation, access to subscription content will continue until the end of the
          current billing period`,
        },
      ],
    },
    {
      title: 'Cancellation by Company',
      list: [
        {
          title: '',
          data: `We reserve the right to cancel subscriptions for users who violate our Terms &
          Conditions or engage in fraudulent activities`,
        },
        {
          title: '',
          data: `In such cases, access will be terminated immediately, and no refunds will be provided`,
        },
      ],
    },
    {
      title: 'Contact Information',
      list: [
        {
          title: '',
          data: `For any questions about this Subscription Cancellation Policy, please contact us at`,
        },
        {
          title: 'Grievance Officer',
          data: `Jameel Ahamad Mujawar`,
        },
        {
          title: 'Email',
          data: `support@gurumavin.in`,
        },
        {
          title: 'Tel',
          data: `81779 00557`,
        },
      ],
    },
  ];
  return (
    <Fragment>
      <Grid container justifyContent={'center'}>
        <Grid item xl={10} lg={10} md={11} sm={11}>
          <Typography
            sx={{
              color: '#000',
              fontSize: '24px',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Subscription Cancellation Policy
          </Typography>
          <Box sx={{ marginTop: '1rem' }}>
            <Typography
              sx={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 600,
              }}
            >
              Introduction
            </Typography>
            <Typography
              sx={{
                color: '#000',
                fontSize: '16px',
                fontWeight: 500,
                marginTop: '0.5rem',
              }}
            >
              This Subscription Cancellation Policy outlines the terms and conditions
              for canceling your subscription with DCENTMASS.
            </Typography>
          </Box>
          {pageData.map((singleData, ind) => (
            <Box sx={{ marginTop: '1.5rem' }} key={ind + singleData?.title}>
              <Typography
                sx={{
                  color: '#000',
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                {singleData?.title}
              </Typography>
              {Array.isArray(singleData?.list) &&
                singleData?.list.map((singleList, singleIndex) => (
                  <li
                    style={{
                      color: '#000',
                      fontSize: '16px',
                      fontWeight: 500,
                      marginTop: '0.5rem',
                      marginLeft: '1rem',
                    }}
                    key={singleIndex + ' list'}
                  >
                    {singleList?.title ? (
                      <span
                        style={{ fontWeight: 700 }}
                      >{`${singleList?.title} : `}</span>
                    ) : null}
                    {`${singleList?.data} .`}
                  </li>
                ))}
            </Box>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};
