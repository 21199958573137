import React, { useEffect, useState } from 'react';
import { Alert, Box, CssBaseline, Grid, Link } from '@mui/material';
import Copyright from '../../components/Copyright';
import { useLocation } from 'react-router-dom';
import { googleLoginUserRequest } from '../../app/services/AuthRequests';

function AccountPage() {
  const location = useLocation();

  const [message, setMessage] = useState(null);

  useEffect(() => {
    if (location.search === '?error=access_denied') {
      setMessage('Access Denied By User');
    } else {
      googleLoginUserRequest(location.search).then((res) => {
        if (res.type === 'error') {
          setMessage('Error Signing Up');
        }
        if (res.access_token && res.user) {
          setMessage('Login Success');
        }
      });
    }
  }, []);
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <title>Account | {`${process.env.REACT_APP_NAME}`}</title>
      <CssBaseline />
      <Grid container align="center" sx={{ padding: '1rem' }}>
        <Grid item xs={12}>
          {message ? <Alert severity="info">{message}</Alert> : null}
          <Box>
            <Link color="inherit" href="/" style={{ fontWeight: 700 }}>
              Go To Home Page
            </Link>
          </Box>
        </Grid>
      </Grid>
      <Copyright />
    </Grid>
  );
}

export default AccountPage;
