import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  Grid,
} from '@mui/material';
import {
  CastForEducation,
  Language,
  LocationOn,
  PersonalVideo,
  Star,
  Timelapse,
  Topic,
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function DisplaySearchCourseResults(props) {
  const { courses } = props;

  const navigate = useNavigate();

  const onCourseClick = (selected) => {
    navigate(
      `/${selected.sub_category.category.title}/${selected.sub_category.title}/course/${selected.id}`
    );
  };

  return Array.isArray(courses) && courses.length >= 1 ? (
    <Grid container spacing={2}>
      {courses.map((singleCourse, index) => (
        <Grid item xs="12" sm="12" md="12" lg="6" xl="6" key={index}>
          <CardActionArea component="a" onClick={() => onCourseClick(singleCourse)}>
            <Card>
              <Grid container>
                <Grid item xs="3" sm="3" md="3" lg="3" xl="3">
                  <CardMedia
                    component="img"
                    sx={{
                      maxWidth: 140,
                      minWidth: '100%',
                      height: 220,
                      display: { xs: 'none', sm: 'block' },
                    }}
                    image={
                      singleCourse.url ||
                      `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
                    }
                  />
                </Grid>
                <Grid item xs="6" sm="6" md="6" lg="6" xl="6">
                  <CardContent
                    sx={{
                      flex: 1,
                      overflowWrap: 'anywhere',
                    }}
                  >
                    <Typography
                      component="h2"
                      variant="h5"
                      sx={{
                        fontSize: '14px',
                        fontWeight: 700,
                        marginBottom: '0.5rem',
                      }}
                    >
                      <strong>
                        {singleCourse.title}
                        {singleCourse.sub_category
                          ? ` | ${singleCourse.sub_category.title}`
                          : null}
                        {singleCourse.grade
                          ? ` | ${singleCourse.grade.title}`
                          : null}
                        {singleCourse.grade_subject
                          ? ` | ${singleCourse.grade_subject.title}`
                          : null}

                        {singleCourse.publication
                          ? ` | ${singleCourse.publication.title}`
                          : null}
                        {singleCourse.publication_edition
                          ? ` | ${singleCourse.publication_edition.edition} Edition`
                          : null}
                        {singleCourse.status === 'published' ? (
                          <span
                            style={{
                              fontSize: '13px',
                              fontWeight: 500,
                            }}
                          >
                            {' '}
                            {/* {` Published On ${new Date(
                              singleCourse?.created_at
                            ).toLocaleDateString('en-US', {
                              year: 'numeric',
                              month: 'short',
                              day: 'numeric',
                            })}`} */}
                            {/* {` Published On ${moment(singleCourse?.created_at)
                              .local()
                              .format('Do MMM YY')}`} */}
                            {singleCourse?.status === 'published'
                              ? `published on ${moment(
                                  singleCourse?.published_at
                                ).format('Do MMM YY ')}`
                              : `created on ${moment(
                                  singleCourse?.created_at
                                ).format('Do MMM YY ')}`}
                          </span>
                        ) : null}
                      </strong>
                    </Typography>
                    <div
                      style={{
                        display: 'flex',
                        gap: '0.5rem',
                        marginBottom: '0.5rem',
                        alignItems: 'center',
                      }}
                    >
                      <span
                        style={{
                          background: '#09768d',
                          color: '#fff',
                          padding: '0.1rem 0.2rem',
                          borderRadius: '20px',
                          fontSize: '12px',
                          fontWeight: 500,
                          display: 'flex',
                          alignItems: 'center',
                          width: '45px',
                          justifyContent: 'center',
                        }}
                      >
                        {singleCourse.course_reviews_avg_rating
                          ? Math.round(singleCourse.course_reviews_avg_rating * 10) /
                            10
                          : 0}
                        &nbsp;
                        <Star sx={{ color: '#fff', fontSize: '1rem' }} />
                      </span>
                      <span
                        style={{
                          color: '#09768d',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        {`
                      ${
                        singleCourse?.course_reviews_count
                          ? singleCourse?.course_reviews_count
                          : 0
                      } ratings
                      `}
                      </span>
                      <span
                        style={{
                          color: '#09768d',
                          fontWeight: 500,
                          fontSize: '12px',
                        }}
                      >
                        {singleCourse?.course_enrols_count
                          ? singleCourse?.course_enrols_count
                          : 'No'}
                        &nbsp;Enrollments
                        {/* {`
                      ${
                        singleCourse?.course_reviews_count
                          ? singleCourse?.course_reviews_count
                          : 0
                      } ratings
                      `} */}
                      </span>
                    </div>
                    <Typography
                      variant="subtitle1"
                      paragraph
                      sx={{
                        fontSize: '12px',
                        margin: '0rem',
                        lineHeight: '1.3',
                      }}
                      title={
                        singleCourse.description &&
                        singleCourse.description.length > 118 &&
                        singleCourse.description
                      }
                    >
                      {singleCourse.description
                        ? singleCourse.description.substring(0, 118)
                        : null}
                      {singleCourse.description &&
                      singleCourse.description.length > 118
                        ? '...'
                        : null}
                    </Typography>
                    {/* {singleCourse.cost ? (
                      <Typography component="h2" color="text.primary">
                        <strong>₹{singleCourse.cost}</strong>
                      </Typography>
                    ) : (
                      <Typography>Free Course</Typography>
                    )} */}
                  </CardContent>
                </Grid>
                <Grid item xs="3" sm="3" md="3" lg="3" xl="3">
                  <div
                    style={{
                      paddingTop: '0.5rem',
                      paddingBottom: '0.5rem',
                      display: 'grid',
                      gap: '0.7rem',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.3rem',
                      }}
                    >
                      <img
                        src="/profileIcon.png"
                        style={{
                          height: '20px',
                          width: '20px',
                          borderRadius: '50%',
                          background: '#fdb714',
                        }}
                      />{' '}
                      {singleCourse.creator.educator_info
                        ? singleCourse.creator.educator_info?.first_name ||
                          singleCourse.creator.name
                        : null}
                      {singleCourse.creator.educator_info?.last_name
                        ? ` ${singleCourse.creator.educator_info?.last_name}`
                        : null}
                      {singleCourse.institution_info
                        ? singleCourse.institution_info?.first_name ||
                          singleCourse.creator.name
                        : null}{' '}
                      {!singleCourse.creator.educator_info &&
                      !singleCourse.institution_info
                        ? singleCourse.creator.name
                        : null}{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.3rem',
                      }}
                    >
                      <LocationOn
                        sx={{
                          height: '20px',
                          width: '20px',
                          color: '#4b86c7',
                        }}
                      />{' '}
                      {singleCourse.creator.educator_info
                        ? singleCourse.creator.educator_info?.location
                        : null}{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.3rem',
                      }}
                    >
                      <CastForEducation
                        sx={{
                          height: '20px',
                          width: '20px',
                          color: '#4b86c7',
                        }}
                      />
                      {`Lessons ${
                        singleCourse.subjects?.length
                          ? singleCourse.subjects?.length
                          : 0
                      }`}{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.3rem',
                      }}
                    >
                      <PersonalVideo
                        sx={{
                          height: '20px',
                          width: '20px',
                          color: '#4b86c7',
                        }}
                      />
                      {`Videos ${
                        singleCourse.published_video_count +
                        singleCourse.published_subject_video_count +
                        singleCourse.published_subject_video_count +
                        singleCourse.published_topic_video_count +
                        singleCourse.published_sub_topic_video_count
                      }`}{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.3rem',
                      }}
                    >
                      <Topic
                        sx={{
                          height: '20px',
                          width: '20px',
                          color: '#4b86c7',
                        }}
                      />
                      {`Topics ${singleCourse?.published_topic_video_count}`}{' '}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.3rem',
                      }}
                    >
                      <Timelapse
                        sx={{
                          height: '20px',
                          width: '20px',
                          color: '#4b86c7',
                        }}
                      />
                      {moment
                        .utc(
                          (Number(singleCourse.published_video_sum_duration) +
                            Number(
                              singleCourse.published_subject_video_sum_duration
                            ) +
                            Number(singleCourse.published_topic_video_sum_duration) +
                            Number(
                              singleCourse.published_sub_topic_video_sum_duration
                            )) *
                            1000
                        )
                        .format('HH:mm:ss')}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '12px',
                        fontWeight: 700,
                        display: 'flex',
                        alignItems: 'center',
                        gap: '0.3rem',
                      }}
                    >
                      <Language
                        sx={{
                          height: '20px',
                          width: '20px',
                          color: '#4b86c7',
                        }}
                      />
                      {singleCourse.language}
                    </Typography>
                  </div>
                </Grid>
              </Grid>
            </Card>
          </CardActionArea>
        </Grid>
        // <CardActionArea
        //   component="a"
        //   onClick={() => onCourseClick(singleCourse)}
        //   key={index}
        //   sx={{ mt: 1 }}
        // >
        //   <Card sx={{ display: 'flex' }}>
        //     <CardMedia
        //       component="img"
        //       sx={{ width: 340, display: { xs: 'none', sm: 'block' } }}
        //       image={
        //         singleCourse.url ||
        //         `https://images.unsplash.com/photo-1516397281156-ca07cf9746fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80`
        //       }
        //     />
        //     <CardContent sx={{ flex: 1, overflowWrap: 'anywhere' }}>
        //       <Typography component="h2" variant="h5">
        //         <strong>
        //           {singleCourse.title}
        //           {singleCourse.grade ? ` | ${singleCourse.grade.title}` : null}
        //           {singleCourse.grade_subject
        //             ? ` | ${singleCourse.grade_subject.title}`
        //             : null}

        //           {singleCourse.publication
        //             ? ` | ${singleCourse.publication.title}`
        //             : null}
        //           {singleCourse.publication_edition
        //             ? ` | ${singleCourse.publication_edition.edition} Edition`
        //             : null}
        //         </strong>
        //       </Typography>
        //       {/* <Typography variant="subtitle1" color="text.secondary">
        //         Starts from&nbsp;
        //         {moment(singleCourse.start_date).format('Do MMM YY hh:mm A')}
        //       </Typography> */}
        //       <Typography variant="subtitle1" color="text.secondary">
        //         In&nbsp; {singleCourse.language}
        //       </Typography>
        //       <Typography variant="subtitle1" paragraph>
        //         {singleCourse.description
        //           ? singleCourse.description.substring(0, 118)
        //           : null}
        //       </Typography>
        //       <Box sx={{ display: 'flex' }}>
        //         <Box>
        //           <Typography>
        //             {Math.round(singleCourse.course_reviews_avg_rating * 10) / 10}
        //             &nbsp;
        //           </Typography>
        //         </Box>
        //         <Rating
        //           name="read-only"
        //           value={Math.round(singleCourse.course_reviews_avg_rating * 10) / 10}
        //           readOnly
        //           precision={0.5}
        //         />

        //         {singleCourse.course_reviews_count ? (
        //           <Typography>
        //             &#40;{singleCourse.course_reviews_count}
        //             &nbsp;reviews&#41;
        //           </Typography>
        //         ) : (
        //           <Typography>&#40;no reviews&#41;</Typography>
        //         )}
        //       </Box>
        //       {singleCourse.cost ? (
        //         <Typography component="h2" color="text.primary">
        //           <strong>₹{singleCourse.cost}</strong>
        //         </Typography>
        //       ) : (
        //         <Typography>Free Course</Typography>
        //       )}
        //       <Typography variant="subtitle1" color="primary">
        //         View Course ...
        //       </Typography>
        //     </CardContent>
        //   </Card>
        // </CardActionArea>
      ))}
    </Grid>
  ) : (
    <p>No results</p>
  );
}
export default DisplaySearchCourseResults;
