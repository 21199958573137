import axios from 'axios';

// Server URL with api appended
// eslint-disable-next-line no-undef
const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

// Application specific key for localstorage
// eslint-disable-next-line no-undef
const LOCALSTORAGE_APP_KEY = `${process.env.REACT_APP_LOCALSTORAGE_KEY}`;

// Function to update User Sign In status from localstorage in redux store. This function is called from auth reducer.
export const updateSignInStatusRequest = () => {
  const token = localStorage.getItem(`${LOCALSTORAGE_APP_KEY}-token`);
  const free = localStorage.getItem(`${LOCALSTORAGE_APP_KEY}-free`);
  const userData = localStorage.getItem(`${LOCALSTORAGE_APP_KEY}-user`);
  const user = JSON.parse(userData);
  const isSignedIn = localStorage.getItem(`${LOCALSTORAGE_APP_KEY}-isSignedIn`);

  return {
    token,
    user,
    isSignedIn,
    free,
  };
};

// Function to sign in user. This function is called from auth reducer.
export const signInUserWithEmailAndPasswordRequest = async (AuthDetails) => {
  try {
    const response = await axios.post(`${API_URL}/user/login`, {
      email: AuthDetails.email,
      password: AuthDetails.password,
    });
    if (response.data.access_token && response.data.user) {
      localStorage.setItem(`${LOCALSTORAGE_APP_KEY}-isSignedIn`, true);
      localStorage.setItem(
        `${LOCALSTORAGE_APP_KEY}-user`,
        JSON.stringify(response.data.user)
      );
      localStorage.setItem(
        `${LOCALSTORAGE_APP_KEY}-token`,
        response.data.access_token
      );

      localStorage.setItem(`${LOCALSTORAGE_APP_KEY}-free`, response.data.free);
    }
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return { type: 'error', data: { errors: [error.response.data.message] } };
      }

      if (error.response.data.message === 'EMAIL_NOT_VERIFIED') {
        return { type: 'error', data: { errors: [error.response.data.message] } };
      }
    }

    return { type: 'error', data: error.response.data.errors };
  }
};

// Function to sign out user. This function is called from auth reducer.
export const signOutUserRequest = async (token) => {
  localStorage.removeItem(`${LOCALSTORAGE_APP_KEY}-user`);
  localStorage.removeItem(`${LOCALSTORAGE_APP_KEY}-token`);
  localStorage.removeItem(`${LOCALSTORAGE_APP_KEY}-isSignedIn`);
  localStorage.removeItem(`${LOCALSTORAGE_APP_KEY}-free`);

  const response = await axios({
    method: 'get',
    url: `${API_URL}/user/logout`,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

// Function to sign in user with Google. This function is called from auth reducer.
export const signInUserWithGoogleRequest = async (AuthDetails) => {
  try {
    const response = await axios.post(`${API_URL}/user/google/login`, {
      name: AuthDetails.name,
      email: AuthDetails.email,
    });
    if (response.data.access_token && response.data.user) {
      localStorage.setItem(`${LOCALSTORAGE_APP_KEY}-isSignedIn`, true);
      localStorage.setItem(`${LOCALSTORAGE_APP_KEY}-free`, response.data.free);
      localStorage.setItem(
        `${LOCALSTORAGE_APP_KEY}-user`,
        JSON.stringify(response.data.user)
      );
      localStorage.setItem(
        `${LOCALSTORAGE_APP_KEY}-token`,
        JSON.stringify(response.data.access_token)
      );
    }
    return response.data;
  } catch (error) {
    if (error.response) {
      if (error.response.status === 401) {
        return { type: 'error', data: { errors: [error.response.data.message] } };
      }
    }

    return { type: 'error', data: { error: ['Unknown error'] } };
  }
};

// Function to get email verification link. This function is called from auth reducer.
export const resendEmailVerificationRequest = async (AuthDetails) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${API_URL}/user/email/resend/${AuthDetails.email}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    return { type: 'error', data: { errors: [error.response.data.message] } };
  }
};

// Function to verify user email. This function is called from auth reducer.
export const verifyEmailRequest = async (authData) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${API_URL}/user/email/verify/${authData.id}?expires=${authData.expire}&signature=${authData.signature}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    return { type: 'error', data: { errors: [error.response.data.message] } };
  }
};

export const googleLoginUrlRequest = async () => {
  try {
    const response = await axios({
      method: 'get',
      url: `${API_URL}/user/login/google/url`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error) {
    return { type: 'error', data: { errors: [error.response.data.message] } };
  }
};

export const googleLoginUserRequest = async (responseCode) => {
  try {
    const response = await axios({
      method: 'get',
      url: `${API_URL}/user/login/google/callback${responseCode}`,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    if (response.data.access_token && response.data.user) {
      localStorage.setItem(`${LOCALSTORAGE_APP_KEY}-isSignedIn`, true);
      localStorage.setItem(`${LOCALSTORAGE_APP_KEY}-free`, response.data.free);
      localStorage.setItem(
        `${LOCALSTORAGE_APP_KEY}-user`,
        JSON.stringify(response.data.user)
      );
      localStorage.setItem(
        `${LOCALSTORAGE_APP_KEY}-token`,
        response.data.access_token
      );
    }
    return response.data;
  } catch (error) {
    return { type: 'error', data: { errors: [error.response.data.message] } };
  }
};
