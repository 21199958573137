/* eslint-disable no-unused-vars */
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  // AccordionSummary,
  Divider,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import { Paper, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DisplayEnroledSubjectVideos from './DisplayEnroledSubjectVideos';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import DisplayEnroledSubTopicVideos from './DisplayEnroledSubTopicVideos';
import DisplayEnroledTopicVideos from './DisplayEnroledTopicVideos';
import moment from 'moment';
import { ExpandMore } from '@mui/icons-material';
import DocumentScannerOutlinedIcon from '@mui/icons-material/DocumentScannerOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { useEffect, useState } from 'react';

const style = {
  width: '100%',
  // maxWidth: 360,
  bgcolor: 'background.paper',
};

function DisplayEnroledCourseCurriculum(props) {
  const { subjects, enrolId, selectedIndex, progressData } = props;
  const navigate = useNavigate();

  const [selectedSubject, setSelectedSubject] = useState({});

  const [expandedAccordions, setExpandedAccordions] = useState({});
  const viewVideo = (videoId) => {
    navigate(`/enrols/${enrolId}/video/${videoId}`);
  };

  const viewDocument = (documentId) => {
    navigate(`/enrols/${enrolId}/document/${documentId}`);
  };

  const viewExternalVideo = (topicId) => {
    navigate(`/enrols/${enrolId}/topic/${topicId}/external-video`);
  };

  const findVideoProgress = (videoId, duration, videoProgressArr) => {
    const found =
      videoProgressArr &&
      videoProgressArr.find((singleProgress) => singleProgress.vid === videoId);
    if (found) {
      return (found.timeStamp * 100) / duration;
    } else {
      return 0;
    }
  };
  const calculateTotalDuration = (data) => {
    return data.reduce((total, item) => {
      return total + (item?.published_video?.duration || 0);
    }, 0);
  };

  const getSelecteSubject = () => {
    let finalSubjects = subjects || [];
    let selectedSubject = finalSubjects.find((singleSubject) =>
      singleSubject?.topics?.find(
        (singleTopic) => singleTopic?.id == selectedIndex?.topicId
      )
    );
    if (selectedSubject) {
      setSelectedSubject({ ...selectedSubject });
      setExpandedAccordions({
        [selectedSubject.id]: true,
      });
    }
  };
  useEffect(() => {
    getSelecteSubject();
  }, [selectedIndex]);

  useEffect(() => {
    getSelecteSubject();
  }, [selectedIndex]);
  const handleAccordionToggle = (id) => {
    setExpandedAccordions((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };
  return (
    <>
      {subjects.length >= 1
        ? subjects.map((singleSubject, index) => (
            // <List
            //   sx={style}
            //   component="nav"
            //   aria-label="mailbox folders"
            //   key={singleSubject.id}
            // >
            <Accordion
              // defaultExpanded={false}
              aria-label="mailbox folders"
              key={singleSubject.id}
              sx={{ maxWidth: '100%', justifyContent: 'center' }}
              expanded={expandedAccordions[singleSubject.id] || false}
              onChange={() => handleAccordionToggle(singleSubject.id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="accordion-Header"
                defaultExpanded={selectedSubject?.id == singleSubject?.id}
                // style={{ marginTop: '5px', marginBottom: '5px' }}
              >
                <ListItem
                  sx={{
                    display: 'block',
                    paddingTop: '0rem',
                    paddingBottom: '0rem',
                    marginTop: '5px',
                    marginBottom: '5px',
                  }}
                >
                  {singleSubject.published_video ? (
                    <>
                      <ListItemButton
                        selected={
                          selectedIndex &&
                          selectedIndex.subjectId === singleSubject.id
                        }
                        onClick={() => viewVideo(singleSubject.published_video.id)}
                        sx={{ display: 'block' }}
                      >
                        <ListItemText
                          primary={`${index + 1}. ${singleSubject.title}`}
                          sx={{
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                          }}
                          title={`${index + 1}. ${singleSubject.title}`}
                          className="custom-list-text"
                        />
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem',
                          }}
                        >
                          <ListItemIcon>
                            <OndemandVideoIcon />
                            &nbsp;
                            {moment
                              .utc(singleSubject.published_video?.duration * 1000)
                              .format('HH:mm:ss')}
                          </ListItemIcon>
                          <LinearProgress
                            value={findVideoProgress(
                              singleSubject.published_video.id,
                              singleSubject.published_video.duration,
                              progressData
                            )}
                            color="secondary"
                            variant="determinate"
                            sx={{ width: '100%' }}
                          />
                        </Box>
                      </ListItemButton>
                    </>
                  ) : (
                    <>
                      <ListItemText
                        primary={`${index + 1}. ${singleSubject.title} `}
                        title={`${index + 1}. ${singleSubject.title}`}
                        sx={
                          {
                            // textOverflow: 'ellipsis',
                            // overflow: 'hidden',
                            // whiteSpace: 'nowrap',
                            // maxWidth: 'calc(100% - 30px)',
                          }
                        }
                        className="custom-list-text"
                      />
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <OndemandVideoIcon />
                        &nbsp;
                        {Array.isArray(singleSubject.topics) &&
                          moment
                            .utc(calculateTotalDuration(singleSubject.topics) * 1000)
                            .format('HH:mm:ss')}{' '}
                        min
                      </span>
                    </>
                  )}
                </ListItem>
              </AccordionSummary>
              <Divider />
              <AccordionDetails sx={{ p: 0 }}>
                {singleSubject.topics.length >= 1
                  ? singleSubject.topics.map((singleTopic, i) => (
                      <List
                        // sx={style}
                        component="nav"
                        aria-label="mailbox folders"
                        key={singleTopic.id}
                        sx={{ p: 0 }}
                      >
                        <ListItem sx={{ display: 'block', p: 0 }}>
                          {singleTopic.published_video ? (
                            <>
                              <ListItemButton
                                selected={
                                  selectedIndex &&
                                  selectedIndex.topicId === singleTopic.id
                                }
                                onClick={() =>
                                  viewVideo(singleTopic.published_video.id)
                                }
                                sx={{ display: 'block' }}
                              >
                                <ListItemText
                                  primary={`${index + 1}.${i + 1}. ${
                                    singleTopic.title
                                  }`}
                                  title={`${index + 1}.${i + 1}. ${
                                    singleTopic.title
                                  }`}
                                  sx={
                                    {
                                      // textOverflow: 'ellipsis',
                                      // overflow: 'hidden',
                                      // whiteSpace: 'nowrap',
                                    }
                                  }
                                  className="custom-list-text"
                                />
                                <Box
                                  sx={{
                                    width: '100%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '1rem',
                                  }}
                                >
                                  <ListItemIcon>
                                    <OndemandVideoIcon />
                                    &nbsp;{' '}
                                    {moment
                                      .utc(
                                        singleTopic.published_video?.duration * 1000
                                      )
                                      .format('HH:mm:ss')}
                                  </ListItemIcon>
                                  <LinearProgress
                                    value={findVideoProgress(
                                      singleTopic.published_video.id,
                                      singleTopic.published_video.duration,
                                      progressData
                                    )}
                                    color="secondary"
                                    variant="determinate"
                                    sx={{ width: '100%' }}
                                  />
                                </Box>
                              </ListItemButton>
                            </>
                          ) : singleTopic.published_document ? (
                            <ListItemButton
                              selected={
                                selectedIndex &&
                                selectedIndex.topicId === singleTopic.id
                              }
                              onClick={() =>
                                viewDocument(singleTopic.published_document.id)
                              }
                              sx={{ display: 'block' }}
                            >
                              <ListItemText
                                primary={`${index + 1}.${i + 1}. ${
                                  singleTopic.title
                                }`}
                                title={`${index + 1}.${i + 1}. ${singleTopic.title}`}
                                sx={
                                  {
                                    // textOverflow: 'ellipsis',
                                    // overflow: 'hidden',
                                    // whiteSpace: 'nowrap',
                                  }
                                }
                                className="custom-list-text"
                              />
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '1rem',
                                }}
                              >
                                <ListItemIcon>
                                  <DocumentScannerOutlinedIcon />
                                  &nbsp; {singleTopic.published_document.title}
                                  .pdf
                                </ListItemIcon>
                              </Box>
                            </ListItemButton>
                          ) : singleTopic.external_video_link ? (
                            <ListItemButton
                              selected={
                                selectedIndex &&
                                selectedIndex.topicId === singleTopic.id
                              }
                              onClick={() => viewExternalVideo(singleTopic.id)}
                              sx={{ display: 'block' }}
                            >
                              <ListItemText
                                primary={`${index + 1}.${i + 1}. ${
                                  singleTopic.title
                                }`}
                                title={`${index + 1}.${i + 1}. ${singleTopic.title}`}
                                sx={
                                  {
                                    // textOverflow: 'ellipsis',
                                    // overflow: 'hidden',
                                    // whiteSpace: 'nowrap',
                                  }
                                }
                                className="custom-list-text"
                              />
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '1rem',
                                }}
                              >
                                <ListItemIcon>
                                  <OpenInNewOutlinedIcon />
                                  &nbsp;
                                  {singleTopic.external_link_title}{' '}
                                </ListItemIcon>
                              </Box>
                            </ListItemButton>
                          ) : (
                            <ListItemButton sx={{ display: 'block' }}>
                              <ListItemText
                                primary={`${index + 1}.${i + 1}. ${
                                  singleTopic.title
                                }`}
                                title={`${index + 1}.${i + 1}. ${singleTopic.title}`}
                                sx={
                                  {
                                    // textOverflow: 'ellipsis',
                                    // overflow: 'hidden',
                                    // whiteSpace: 'nowrap',
                                  }
                                }
                                className="custom-list-text"
                              />
                            </ListItemButton>
                          )}

                          {/* {singleTopic.published_document ? (
                            <ListItemButton
                              selected={
                                selectedIndex &&
                                selectedIndex.topicId === singleTopic.id
                              }
                              onClick={() =>
                                viewDocument(singleTopic.published_document.id)
                              }
                              sx={{ display: 'block' }}
                            >
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '1rem',
                                }}
                              >
                                <ListItemIcon>
                                  <DocumentScannerOutlinedIcon />
                                  &nbsp; {singleTopic.published_document.title}
                                  .pdf
                                </ListItemIcon>
                              </Box>
                            </ListItemButton>
                          ) : null}
                          {singleTopic.external_video_link ? (
                            <ListItemButton
                              selected={
                                selectedIndex &&
                                selectedIndex.topicId === singleTopic.id
                              }
                              onClick={() => viewExternalVideo(singleTopic.id)}
                              sx={{ display: 'block' }}
                            >
                              <Box
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '1rem',
                                }}
                              >
                                <ListItemIcon>
                                  <OpenInNewOutlinedIcon />
                                  &nbsp;
                                  {singleTopic.external_link_title}{' '}
                                </ListItemIcon>
                              </Box>
                            </ListItemButton>
                          ) : null} */}
                        </ListItem>

                        <Divider />
                        {singleTopic.sub_topics.length >= 1
                          ? singleTopic.sub_topics.map((singleSubTopic, ii) => (
                              <List
                                // sx={style}
                                sx={{ pl: 2 }}
                                component="nav"
                                aria-label="mailbox folders"
                                key={singleSubTopic.id}
                              >
                                <ListItem sx={{ display: 'block' }}>
                                  {singleSubTopic.published_video ? (
                                    <>
                                      <ListItemButton
                                        selected={
                                          selectedIndex &&
                                          selectedIndex.subTopicId ===
                                            singleSubTopic.id
                                        }
                                        onClick={() =>
                                          viewVideo(
                                            singleSubTopic.published_video.id
                                          )
                                        }
                                        sx={{ display: 'block' }}
                                      >
                                        <ListItemText
                                          primary={`${index + 1}. ${i + 1}. ${
                                            ii + 1
                                          }. ${singleSubTopic.title}`}
                                          title={`${index + 1}. ${i + 1}. ${
                                            ii + 1
                                          }. ${singleSubTopic.title}`}
                                          sx={{
                                            textOverflow: 'ellipsis',
                                            overflow: 'hidden',
                                            whiteSpace: 'nowrap',
                                          }}
                                          className="custom-list-text"
                                        />

                                        <Box
                                          sx={{
                                            width: '100%',
                                            display: 'flex',
                                            alignItems: 'center',
                                            gap: '1rem',
                                          }}
                                        >
                                          <ListItemIcon>
                                            <OndemandVideoIcon />
                                            &nbsp;{' '}
                                            {moment
                                              .utc(
                                                singleSubTopic.published_video
                                                  ?.duration * 1000
                                              )
                                              .format('HH:mm:ss')}
                                          </ListItemIcon>
                                          <LinearProgress
                                            value={findVideoProgress(
                                              singleSubTopic.published_video.id,
                                              singleSubTopic.published_video
                                                .duration,
                                              progressData
                                            )}
                                            color="secondary"
                                            variant="determinate"
                                            sx={{ width: '100%' }}
                                          />
                                        </Box>
                                      </ListItemButton>
                                    </>
                                  ) : (
                                    <ListItemText
                                      primary={`${index + 1}. ${i + 1}. ${ii + 1}. ${
                                        singleSubTopic.title
                                      }`}
                                      title={`${index + 1}. ${i + 1}. ${ii + 1}. ${
                                        singleSubTopic.title
                                      }`}
                                      className="custom-list-text"
                                    />
                                  )}
                                </ListItem>

                                <Divider />
                              </List>
                            ))
                          : null}
                      </List>
                    ))
                  : null}
              </AccordionDetails>
              {/* </List> */}
            </Accordion>
          ))
        : null}
    </>
  );
}

export default DisplayEnroledCourseCurriculum;
