/* eslint-disable no-unused-vars */
import { Button, Grid, Paper, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useSnackbar } from 'notistack';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useParams, useNavigate } from 'react-router';
import { useResetPasswordMutation } from '../../app/services/AuthApi';
import { PasswordEye } from './student/PasswordEye';

function ResetPasswordPage() {
  const params = useParams();

  const token = params.token;
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [resetPassword, result] = useResetPasswordMutation();

  const dispatch = useDispatch();
  const { isSignedIn } = useSelector((state) => state.auth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const ValidateEmail = (mail) => {
    // eslint-disable-next-line no-useless-escape
    const mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (mail.match(mailFormat)) {
      return true;
    }
    return false;
  };
  const validateData = (authData) => {
    let err = true;

    if (!authData.email || !authData.password || !authData.password_confirmation) {
      enqueueSnackbar('All fields are required', { variant: 'error' });
    } else if (!ValidateEmail(authData.email)) {
      enqueueSnackbar('Please enter a valid Email address', { variant: 'error' });
    } else if (authData.password !== authData.password_confirmation) {
      enqueueSnackbar('Confirm password did not match.', { variant: 'error' });
    } else if (authData.password.length < 8) {
      enqueueSnackbar('Password length must be at least 8 chars', {
        variant: 'error',
      });
    } else {
      err = false;
    }

    return err;
  };

  // Handle Submit of the form
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const Authdata = {
      email: data.get('email'),
      password: data.get('newPassword'),
      password_confirmation: data.get('newConfirmPassword'),
      token,
    };
    const res = validateData(Authdata);
    if (!res) {
      resetPassword(Authdata)
        .unwrap()
        .then(() => {
          enqueueSnackbar('Password Reset Success', {
            variant: 'success',
          });
          navigate('/student-account');
        })
        .catch((error) => {
          enqueueSnackbar('Error Resetting Password', {
            variant: 'error',
          });
        });
    }
  };

  if (isSignedIn) {
    return <Navigate replace to="/" />;
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography component="h1" variant="h5">
              RESET YOUR PASSWORD
            </Typography>
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ m: 5 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <div style={{ position: 'relative', width: '100%' }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="newPassword"
                  label="New Password"
                  type={showPassword ? 'text' : 'password'}
                  id="newPassword"
                  autoComplete="current-password"
                />
                <PasswordEye
                  stylingEye={{
                    position: 'absolute',
                    right: '1rem',
                    top: '2.7rem',
                  }}
                  showPassword={showPassword}
                  setShowPassword={setShowPassword}
                />
              </div>
              <div style={{ position: 'relative', width: '100%' }}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="newConfirmPassword"
                  label="New Confirm Password"
                  type={showConfirmPassword ? 'text' : 'password'}
                  id="newConfirmPassword"
                  autoComplete="current-password"
                />
                <PasswordEye
                  stylingEye={{
                    position: 'absolute',
                    right: '1rem',
                    top: '2.7rem',
                  }}
                  showPassword={showConfirmPassword}
                  setShowPassword={setShowConfirmPassword}
                />
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                data-cy-submit-button
                disabled={result.isLoading || result.isSuccess}
              >
                RESET MY PASSWORD
              </Button>
            </Box>
            <Box mt={2}></Box>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={3} xl={3}></Grid>
      </Grid>
    </div>
  );
}

export default ResetPasswordPage;
