/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import {
  useCourseQuery,
  useCreateSubjectMutation,
  useCreateTopicMutation,
  useDeleteSubjectMutation,
  useDeleteTopicMutation,
  useUpdateSubjectMutation,
  useUpdateTopicMutation,
} from '../../../app/services/CoursesApi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Add,
  DeleteOutline,
  EditNote,
  SaveOutlined,
  SignalCellularNull,
} from '@mui/icons-material';
import EditCourseTopic from './EditCourseTopic';
import { useEffect, useState } from 'react';

function AddCourseSubject(props) {
  const { courseId, nextButton } = props;

  const [createSubject] = useCreateSubjectMutation();
  const [deleteId] = useDeleteSubjectMutation();
  const [createTopic] = useCreateTopicMutation();
  const [selectedSubject, setSelectedSubject] = useState({
    title: '',
    edit: false,
    id: null,
    topics: [],
  });
  const [updateSubject] = useUpdateSubjectMutation();
  const [addChapter, setAddChapter] = useState(false);
  const { data, isLoading, isSuccess } = useCourseQuery({
    id: courseId,
  });
  useEffect(() => {
    if (data?.course?.subjects?.length >= 1 && data?.course?.subjects[0]?.id) {
      nextButton(true);
    } else {
      nextButton(false);
    }
  }, [data?.course]);

  const validateData = (subjectData) => {
    let err = true;

    if (!subjectData.title) {
      err = true;
    } else {
      err = false;
    }

    return err;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const subjectData = {
      course_id: courseId,
      title: data.get('title'),
      description: data.get('description'),
    };

    const res = validateData(subjectData);
    if (!res) {
      createSubject(subjectData)
        .unwrap()
        .then((res) => {
          if (res.subject.id) {
            setAddChapter(false);
          }
        });
    }
  };

  const handleUpdateSubmit = (event) => {
    const subjectData = {
      id: selectedSubject.id,
      title: selectedSubject.title,
      description: selectedSubject.title,
    };

    const res = validateData(subjectData);
    if (!res) {
      updateSubject(subjectData)
        .unwrap()
        .then((res) => {
          if (res.message === 'subject updated successfully') {
            setSelectedSubject({
              ...selectedSubject,
              title: '',
              edit: false,
              id: null,
              topics: [],
            });
          }
        });
    }
  };

  const handleEditButton = (subject) => {
    setSelectedSubject({
      ...selectedSubject,
      title: subject.title,
      edit: true,
      id: subject.id,
      topics: subject.topics,
    });
  };

  const handleSubjectTitleChange = (e) => {
    setSelectedSubject({ ...selectedSubject, title: e.target.value });
  };

  const deleteSubjectWithId = (singleSubject) => {
    deleteId({ id: singleSubject.id })
      .unwrap()
      .then((res) => {
        if (res.message === 'DELETED') {
          setSelectedSubject({
            ...selectedSubject,
            title: '',
            edit: false,
            id: null,
            topics: [],
          });
        }
      });
  };

  return (
    <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
      <>
        <Box>
          <Grid container spacing={2}>
            {data?.course?.subjects?.length > 0
              ? data?.course.subjects.map((singleSubject, subjectIndex) => (
                  <Grid
                    item
                    xl="12"
                    lg="12"
                    md="12"
                    sm="12"
                    xs="12"
                    key={singleSubject.id}
                  >
                    {' '}
                    <div
                      style={{
                        border: '1px solid #cacbcf',
                        background: '#fafafa',
                        borderRadius: '6px',
                        padding: '1rem',
                      }}
                    >
                      <Box style={{ display: 'flex' }} component="form">
                        <TextField
                          required
                          fullWidth
                          size="small"
                          placeholder="What Where How and When"
                          label={`Chapter ${subjectIndex + 1}`}
                          disabled={singleSubject.id !== selectedSubject.id}
                          defaultValue={singleSubject.title}
                          autoFocus
                          onChange={(e) => handleSubjectTitleChange(e)}
                        />
                        <>
                          <div className="font-icon-wrapper">
                            <IconButton
                              onClick={() => deleteSubjectWithId(singleSubject)}
                            >
                              <DeleteOutline />
                            </IconButton>
                          </div>
                          <div className="font-icon-wrapper">
                            {selectedSubject.edit ? (
                              <IconButton onClick={() => handleUpdateSubmit()}>
                                <SaveOutlined />
                              </IconButton>
                            ) : (
                              <IconButton
                                onClick={() => handleEditButton(singleSubject)}
                              >
                                <EditNote />
                              </IconButton>
                            )}
                          </div>
                        </>
                      </Box>
                      <div style={{ marginTop: '1rem' }}>
                        <EditCourseTopic
                          subject={singleSubject}
                          subjectId={singleSubject.id}
                        />
                      </div>
                    </div>
                  </Grid>
                ))
              : null}
            <Grid item xl="12" lg="12" md="12" sm="12" xs="12">
              {addChapter ? (
                <Box
                  component="form"
                  onSubmit={handleSubmit}
                  noValidate
                  sx={{
                    mt: 1,
                    display: 'flex',
                    border: '1px solid #cacbcf',
                    background: '#fafafa',
                    borderRadius: '6px',
                    padding: '1rem',
                  }}
                >
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="title"
                    label={`Chapter`}
                    name="title"
                    autoComplete="title"
                    autoFocus
                  />
                  <div className="font-icon-wrapper" style={{ padding: 'inherit' }}>
                    <IconButton onClick={() => setAddChapter(false)}>
                      <DeleteOutline />
                    </IconButton>
                  </div>
                  <Button
                    type="submit"
                    sx={{ padding: '0px 8px' }}
                    variant="outlined"
                    color="secondary"
                  >
                    Create Chapter
                  </Button>
                </Box>
              ) : (
                <Button
                  type="button"
                  variant="contained"
                  onClick={() => setAddChapter(true)}
                >
                  Add Chapter
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </>
    </Grid>
  );
}

export default AddCourseSubject;
