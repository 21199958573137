/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  Tab,
  Tabs,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Alert,
  LinearProgress,
} from '@mui/material';
import {
  useCreateSubjectMutation,
  useCreateTopicMutation,
  useDeleteSubjectMutation,
  useDeleteTopicMutation,
  useUpdateSubjectMutation,
  useUpdateTopicExternalLinkMutation,
  useUpdateTopicMutation,
} from '../../../app/services/CoursesApi';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {
  Add,
  DeleteOutline,
  EditNote,
  SaveOutlined,
  SignalCellularNull,
} from '@mui/icons-material';
import { useEffect, useRef, useState } from 'react';
import TabPanel from '../../../components/authentication/TabPanel';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DisplayDocument from './DisplayDocument';
import { useSnackbar } from 'notistack';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import HlsJs from '../HlsJs';
import { BaseApi } from '../../../app/services/BaseApi';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const API_URL = `${process.env.REACT_APP_BACKEND_API_PATH}/api`;

function EditCourseTopic(props) {
  const { token } = useSelector((state) => state.auth);

  const { subjectId, subject } = props;
  const [addContent, setAddContent] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState({
    title: '',
    id: null,
    external_link_title: null,
    external_video_link: null,
    video: null,
    document: null,
  });
  const { enqueueSnackbar } = useSnackbar();

  const [createTopic] = useCreateTopicMutation();
  const [deleteTopicId] = useDeleteTopicMutation();
  const dispatch = useDispatch();

  const [updateTopic] = useUpdateTopicMutation();
  const [duration, setSetDuration] = useState(0);
  const [value, setValue] = useState(null);
  const videoRef = useRef();
  const docRef = useRef();
  const [open, setOpen] = useState(false);
  const [addLecture, setAddLecture] = useState(false);
  const [uploadStatus, setUploadStatus] = useState(false);
  const [linkStatus, setLinkStatus] = useState(false);
  const [progress, setProgress] = useState(false);
  const [updateTopicExternalLink, updateRes] = useUpdateTopicExternalLinkMutation();

  const loadedMetaData = (event) => {
    const video = videoRef.current;
    if (!video) return;

    // setSetDuration(Math.floor(video.duration));
    setSelectedTopic({ ...selectedTopic, duration: Math.floor(video.duration) });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddContentButton = (topic) => {
    setSelectedTopic(topic);
    setAddContent(true);
    setUploadStatus(false);
    setProgress(false);
    setLinkStatus(false);
  };

  const handleMinimizeButton = () => {
    setAddContent(false);
    setSelectedTopic({
      ...selectedTopic,
      title: '',
      id: null,
      external_link_title: null,
      external_video_link: null,
      video: null,
      document: null,
    });
    setUploadStatus(false);
    setProgress(false);
    setLinkStatus(false);
  };

  const validateData = (subjectData) => {
    let err = true;

    if (!subjectData.title) {
      err = true;
    } else {
      err = false;
    }

    return err;
  };

  const handleTopicSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const topicData = {
      subject_id: subjectId,
      title: data.get('title'),
      description: data.get('description'),
    };

    const res = validateData(topicData);
    if (!res) {
      createTopic(topicData)
        .unwrap()
        .then((res) => {
          if (res.topic.id) {
            setAddLecture(false);
          }
        });
    }
  };

  const handleEditButton = (topic) => {
    setSelectedTopic({
      ...selectedTopic,
      title: topic.title,
      edit: true,
      id: topic.id,
      external_link_title: topic.external_link_title,
      external_video_link: topic.external_video_link,
      video: topic.video,
      document: topic.document,
    });
  };

  const handleUpdateSubmit = (event) => {
    const topicData = {
      id: selectedTopic.id,
      title: selectedTopic.title,
      description: selectedTopic.title,
    };

    const res = validateData(topicData);
    if (!res) {
      updateTopic(topicData)
        .unwrap()
        .then((res) => {
          if (res.message === 'topic updated successfully') {
            setSelectedTopic({
              ...selectedTopic,
              title: '',
              edit: false,
              id: null,
              external_link_title: null,
              external_video_link: null,
              video: null,
              document: null,
            });
          }
        });
    }
  };

  const handleTopicTitleChange = (e) => {
    setSelectedTopic({ ...selectedTopic, title: e.target.value });
  };

  const onExternalLinkSubmit = (e, topicToUpdate) => {
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if (
      data.get('externalLinkTitle').trim().length === 0 ||
      data.get('externalVideoLink').trim().length === 0
    ) {
      enqueueSnackbar('Link Title or Link cannot be empty or contain only spaces', {
        variant: 'error',
      });
      return;
    }
    const updateData = {
      external_link_title: data.get('externalLinkTitle'),
      external_video_link: data.get('externalVideoLink'),
      id: topicToUpdate.id,
    };

    updateTopicExternalLink(updateData)
      .unwrap()
      .then((res) => {
        if (res.message === 'topic updated successfully') {
          setSelectedTopic({
            ...selectedTopic,
            external_link_title: updateData.external_link_title,
            external_video_link: updateData.external_video_link,
          });
          setLinkStatus('success');
        } else {
          setLinkStatus('error');
        }
      });
  };

  const fileUploadRequest = (uploadData) => {
    return axios.post(
      `${API_URL}/course/subject/topic/video/upload/${uploadData.topicId}`,
      {
        file: uploadData.file,
        file_type: 'topic',
        title: uploadData.title,
        description: uploadData.description,
        duration: uploadData.duration,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${uploadData.token}`,
        },

        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      }
    );
  };

  const UploadVideo = (topicId) => {
    if (subject && selectedTopic) {
      const videoUploadData = {
        file: selectedTopic.video,
        token: token,
        title: selectedTopic.title,
        description: selectedTopic.title,
        duration: selectedTopic?.duration,
        topicId: topicId,
      };

      setAddContent('disable');
      setUploadStatus('processing');

      fileUploadRequest(videoUploadData)
        .then((res) => {
          if (res.data.video.id) {
            setSelectedTopic({
              ...selectedTopic,
              video: res.data.video,
            });
            setAddContent(true);
            dispatch(BaseApi.util.invalidateTags(['Course']));

            setUploadStatus('success');
          } else {
            setAddContent(true);

            setUploadStatus('error');
          }
        })
        .catch((err) => {
          console.log(err);

          setAddContent(true);

          setUploadStatus('error');
        });
    }
  };

  const fileUploadRequest1 = (uploadData) => {
    return axios.post(
      `${API_URL}/course/subject/topic/document/upload/${uploadData.topicId}`,
      {
        file: uploadData.file,
        file_type: 'document',
        title: uploadData.title,
        description: uploadData.description,
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${uploadData.token}`,
        },

        onUploadProgress: (progressEvent) => {
          var percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setProgress(percentCompleted);
        },
      }
    );
  };

  const uploadDocument = (topicId) => {
    if (subject && selectedTopic) {
      const documentUploadData = {
        file: selectedTopic.document,
        token: token,
        title: selectedTopic.title,
        description: selectedTopic.title,
        topicId: topicId,
      };

      setAddContent('disable');

      setUploadStatus('processing');

      fileUploadRequest1(documentUploadData)
        .then((res) => {
          if (res.data.document.id) {
            setSelectedTopic({
              ...selectedTopic,
              document: res.data.document,
            });
            setAddContent(true);
            dispatch(BaseApi.util.invalidateTags(['Course']));

            setUploadStatus('success');
          } else {
            setAddContent(true);

            setUploadStatus('error');
          }
        })
        .catch((err) => {
          console.log(err);
          setAddContent(true);

          setUploadStatus('error');
        });
    }
  };

  const deleteTopicWithId = (topic) => {
    deleteTopicId({ id: topic.id })
      .unwrap()
      .then((res) => {
        if (res.message === 'DELETED') {
          setSelectedTopic({
            ...selectedTopic,
            title: '',
            edit: false,
            id: null,
            external_link_title: null,
            external_video_link: null,
            video: null,
            document: null,
          });
        }
      });
  };

  return (
    <>
      {subject?.topics?.length > 0
        ? subject.topics.map((singleTopic, topicIndex) => (
            <div
              key={singleTopic.id}
              style={{
                border: '1px solid #cacbcf',
                background: '#fff',
                borderRadius: '6px',
                padding: '1rem',
                marginTop: '1rem',
              }}
            >
              <Box style={{ display: 'flex' }} component="form">
                <TextField
                  defaultValue={singleTopic.title}
                  autoFocus
                  required
                  fullWidth
                  size="small"
                  placeholder="What is Herbivorus animal"
                  label={`Lecture ${topicIndex + 1}`}
                  disabled={
                    singleTopic.id !== selectedTopic.id ||
                    addContent === 'disable' ||
                    addContent === true
                  }
                  onChange={(e) => handleTopicTitleChange(e)}
                  InputProps={{
                    endAdornment: !selectedTopic.edit ? (
                      <InputAdornment position="end">
                        <Icon color="success">
                          <CheckCircleIcon twoToneColor="#52c41a" />
                        </Icon>{' '}
                      </InputAdornment>
                    ) : null,
                  }}
                />
                {addContent === 'disable' || addContent === true ? null : (
                  <div className="font-icon-wrapper">
                    <IconButton onClick={() => deleteTopicWithId(singleTopic)}>
                      <DeleteOutline />
                    </IconButton>
                  </div>
                )}
                <div className="font-icon-wrapper">
                  {!addContent ? (
                    selectedTopic.edit ? (
                      <IconButton onClick={() => handleUpdateSubmit()}>
                        <SaveOutlined />
                      </IconButton>
                    ) : (
                      <IconButton onClick={() => handleEditButton(singleTopic)}>
                        <EditNote />
                      </IconButton>
                    )
                  ) : null}
                </div>
                {singleTopic?.id === selectedTopic?.id && addContent ? (
                  <Button
                    sx={{ width: '200px' }}
                    onClick={() => handleMinimizeButton()}
                    disabled={addContent === 'disable'}
                  >
                    Minimize
                  </Button>
                ) : (
                  <Button
                    sx={{ width: '200px' }}
                    onClick={() => handleAddContentButton(singleTopic)}
                    disabled={
                      selectedTopic.id ? singleTopic?.id !== selectedTopic?.id : null
                    }
                  >
                    Add Content
                  </Button>
                )}
              </Box>

              {singleTopic?.id === selectedTopic?.id && addContent ? (
                <div style={{ marginTop: '0.5rem' }}>
                  <Tabs
                    value={false}
                    onChange={(e, newval) => handleChange(e, newval)}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="Account forms"
                  >
                    <Tab
                      label="Upload Video"
                      id="simple-tab-0"
                      aria-controls="simple-tabpanel-0"
                      disabled={
                        selectedTopic.external_video_link !== null ||
                        selectedTopic.document !== null
                      }
                    />
                    <Tab
                      label="Uploaded Video"
                      id="simple-tab-1"
                      aria-controls="simple-tabpanel-0"
                      disabled={
                        selectedTopic.external_video_link !== null ||
                        selectedTopic.document !== null
                      }
                    />
                    <Tab
                      label="External Link"
                      id="simple-tab-2"
                      aria-controls="simple-tabpanel-1"
                      disabled={
                        selectedTopic.video !== null ||
                        selectedTopic.document !== null
                      }
                    />
                    <Tab
                      label="Upload Document"
                      id="simple-tab-3"
                      aria-controls="simple-tabpanel-1"
                      disabled={
                        selectedTopic.video !== null ||
                        selectedTopic.external_video_link !== null
                      }
                    />
                    <Tab
                      label="Uploaded Document"
                      id="simple-tab-4"
                      aria-controls="simple-tabpanel-1"
                      disabled={
                        selectedTopic.video !== null ||
                        selectedTopic.external_video_link !== null
                      }
                    />
                  </Tabs>
                  <TabPanel value={value} index={0}>
                    <Grid container spacing={1}>
                      <Grid item xl="10" lg="10" md="12" sm="12" xs="12">
                        {singleTopic.external_video_link === null &&
                        singleTopic.document === null ? (
                          <form
                            className="form"
                            style={{
                              marginTop: '1rem',
                            }}
                          >
                            <div
                              className="file-upload-wrapper"
                              data-text={
                                selectedTopic.video
                                  ? selectedTopic.video.name
                                  : 'No File Selected'
                              }
                              data-button-text={
                                selectedTopic.video ? 'Change video' : 'Select video'
                              }
                            >
                              <input
                                name="file-upload-field"
                                type="file"
                                accept="video/*"
                                className="file-upload-field"
                                value=""
                                onChange={(e) => {
                                  const selectedFile = e.target.files[0];
                                  if (
                                    selectedFile &&
                                    selectedFile.type.includes('video')
                                  ) {
                                    videoRef.current?.load();
                                    setSelectedTopic({
                                      ...selectedTopic,
                                      video: e.target.files[0],
                                    });
                                  } else {
                                    enqueueSnackbar('Please select a Video file.', {
                                      variant: 'error',
                                    });
                                  }
                                }}
                                disabled={progress > 0 && progress < 100}
                              />
                            </div>
                          </form>
                        ) : null}
                        {progress > 0 ? (
                          <Box sx={{ width: '100%', mt: '4px' }}>
                            <LinearProgressWithLabel
                              value={progress}
                              color="secondary"
                            />
                          </Box>
                        ) : null}

                        {uploadStatus === 'processing' ? (
                          <Alert severity="info">
                            Your video is being uploaded. Please Wait
                          </Alert>
                        ) : null}
                        {uploadStatus === 'error' ? (
                          <Alert severity="error">Error Uploading Video</Alert>
                        ) : null}
                        {uploadStatus === 'success' ? (
                          <Alert severity="success">
                            Your video Uploaded Successfully. May take some time to
                            process.
                          </Alert>
                        ) : null}
                        {selectedTopic.video && !selectedTopic.video.id ? (
                          <Box sx={{ mt: '12px' }}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                setSelectedTopic({
                                  ...selectedTopic,
                                  video: singleTopic.video,
                                  uploadStatus: null,
                                  progress: null,
                                });
                              }}
                              disabled={progress > 0 || progress === 100}
                            >
                              Cancel
                            </Button>
                          </Box>
                        ) : null}
                      </Grid>

                      <Grid item xl="2" lg="2" md="12" sm="12" xs="12">
                        {selectedTopic?.video ? (
                          selectedTopic?.video?.vid_url ? (
                            <HlsJs vidSrc={selectedTopic.video.vid_url} />
                          ) : !selectedTopic?.video?.id ? (
                            <>
                              <video
                                controls
                                ref={videoRef}
                                controlsList="nodownload"
                                onLoadedMetadata={loadedMetaData}
                              >
                                <source
                                  src={URL.createObjectURL(selectedTopic.video)}
                                  type="video/mp4"
                                />
                                Your browser does not support HTML video.
                              </video>

                              <Button
                                variant="contained"
                                onClick={() => UploadVideo(selectedTopic.id)}
                              >
                                Upload Video
                              </Button>
                            </>
                          ) : null
                        ) : null}
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    {selectedTopic?.video?.id && (
                      <Grid container spacing={1}>
                        <Grid item xl="10" lg="10" md="12" sm="12" xs="12">
                          <Box sx={{ display: 'flex', marginLeft: '20px' }}>
                            {selectedTopic?.video?.vid_url ? (
                              <a
                                href={`/course/video/${selectedTopic?.video?.id}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {<PlayCircleOutlineIcon />} &nbsp;
                                {selectedTopic.video.title}
                              </a>
                            ) : (
                              <Typography>
                                {<PlayCircleOutlineIcon />} &nbsp;
                                <Alert severity="info">
                                  {selectedTopic?.video?.title}&nbsp;.Processing
                                  Uploaded Video. You may check the video later by
                                  selecting Action and clicking Edit Course in list
                                  of courses. If it takes long please contact
                                  support.{' '}
                                </Alert>
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <Grid container>
                      <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                        {singleTopic.video === null &&
                        singleTopic.document === null ? (
                          <Box
                            marginTop={'1rem'}
                            component="form"
                            onSubmit={(e) => onExternalLinkSubmit(e, selectedTopic)}
                            noValidate
                          >
                            <TextField
                              required
                              fullWidth
                              size="small"
                              label={`Add Title`}
                              id="externalLinkTitle"
                              name="externalLinkTitle"
                              autoComplete="externalLinkTitle"
                              autoFocus
                              defaultValue={selectedTopic.external_link_title}
                            />
                            <TextField
                              required
                              fullWidth
                              size="small"
                              sx={{ mt: '0.5rem' }}
                              label={`Add Video URL`}
                              id="externalVideoLink"
                              name="externalVideoLink"
                              autoComplete="externalVideoLink"
                              autoFocus
                              defaultValue={selectedTopic.external_video_link}
                            />
                            {linkStatus === 'success' ? (
                              <Alert severity="success">
                                External Link Updated Successfully
                              </Alert>
                            ) : null}
                            {linkStatus === 'error' ? (
                              <Alert severity="error">
                                Error Updating External Link
                              </Alert>
                            ) : null}

                            <Button
                              variant="contained"
                              type="submit"
                              sx={{ mt: '0.5rem', float: 'right' }}
                            >
                              Submit
                            </Button>
                          </Box>
                        ) : null}
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                    <Grid container>
                      <Grid xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid item xl="10" lg="10" md="12" sm="12" xs="12">
                          {singleTopic.video === null &&
                          singleTopic.external_video_link === null ? (
                            <form
                              className="form"
                              style={{
                                marginTop: '1rem',
                              }}
                            >
                              <div
                                className="file-upload-wrapper"
                                data-text={
                                  selectedTopic.document
                                    ? selectedTopic.document.name
                                    : 'No File Selected'
                                }
                                data-button-text={
                                  selectedTopic.document
                                    ? 'Change document'
                                    : 'Select document'
                                }
                              >
                                <input
                                  name="file-upload-field"
                                  type="file"
                                  accept=".pdf"
                                  className="file-upload-field"
                                  value=""
                                  onChange={(e) => {
                                    const selectedFile = e.target.files[0];
                                    if (
                                      selectedFile &&
                                      selectedFile.type.includes('pdf')
                                    ) {
                                      setSelectedTopic({
                                        ...selectedTopic,
                                        document: e.target.files[0],
                                      });
                                    } else {
                                      enqueueSnackbar('Please select a PDF file.', {
                                        variant: 'error',
                                      });
                                    }
                                  }}
                                  disabled={progress > 0 && progress < 100}
                                />
                              </div>
                            </form>
                          ) : null}
                          {progress > 0 ? (
                            <Box sx={{ width: '100%', mt: '4px' }}>
                              <LinearProgressWithLabel
                                value={progress}
                                color="secondary"
                              />
                            </Box>
                          ) : null}

                          {uploadStatus === 'processing' ? (
                            <Alert severity="info">
                              Your document is being uploaded. Please Wait
                            </Alert>
                          ) : null}
                          {uploadStatus === 'error' ? (
                            <Alert severity="error">Error Uploading Document</Alert>
                          ) : null}
                          {uploadStatus === 'success' ? (
                            <Alert severity="success">
                              Your document Uploaded Successfully
                            </Alert>
                          ) : null}
                          {selectedTopic.document && !selectedTopic.document.id ? (
                            <Box sx={{ mt: '12px' }}>
                              <Button
                                variant="contained"
                                onClick={() => {
                                  setSelectedTopic({
                                    ...selectedTopic,
                                    document: singleTopic.document,
                                    uploadStatus: null,
                                    progress: null,
                                  });
                                }}
                                disabled={progress > 0 || progress === 100}
                              >
                                Cancel
                              </Button>
                            </Box>
                          ) : null}
                        </Grid>
                        <Grid item xl="2" lg="2" md="12" sm="12" xs="12">
                          {selectedTopic?.document &&
                          singleTopic?.document !== selectedTopic?.document ? (
                            <>
                              <IconButton
                                aria-label="fingerprint"
                                color="secondary"
                                onClick={handleClickOpen}
                              >
                                <AttachFileIcon /> View File
                              </IconButton>
                              <Dialog
                                open={open}
                                onClose={handleClose}
                                maxWidth="md"
                              >
                                <DialogContent>
                                  <DisplayDocument doc={selectedTopic.document} />
                                </DialogContent>
                                <DialogActions>
                                  <Button onClick={handleClose}>Close</Button>
                                </DialogActions>
                              </Dialog>

                              <Button
                                variant="contained"
                                onClick={() => uploadDocument(selectedTopic.id)}
                              >
                                Upload File
                              </Button>
                            </>
                          ) : null}
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value={value} index={4}>
                    {selectedTopic?.document?.id && (
                      <Grid container spacing={1}>
                        <Grid item xl="10" lg="10" md="12" sm="12" xs="12">
                          <Box sx={{ display: 'flex', marginLeft: '20px' }}>
                            <Typography>{selectedTopic.document.title}</Typography>
                            <IconButton
                              aria-label="fingerprint"
                              color="secondary"
                              onClick={handleClickOpen}
                            >
                              <AttachFileIcon /> View File
                            </IconButton>
                            <Dialog open={open} onClose={handleClose} maxWidth="md">
                              <DialogContent>
                                <DisplayDocument doc={selectedTopic.document} />
                              </DialogContent>
                              <DialogActions>
                                <Button onClick={handleClose}>Close</Button>
                              </DialogActions>
                            </Dialog>
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                  </TabPanel>
                </div>
              ) : null}
            </div>
          ))
        : null}
      {addLecture ? (
        <Box
          component="form"
          onSubmit={handleTopicSubmit}
          noValidate
          sx={{
            mt: 1,
            display: 'flex',
            border: '1px solid #cacbcf',
            background: '#fafafa',
            borderRadius: '6px',
            padding: '1rem',
          }}
        >
          <TextField
            margin="normal"
            required
            fullWidth
            id="title"
            label={`Lecture`}
            name="title"
            autoComplete="title"
            autoFocus
          />
          <div className="font-icon-wrapper" style={{ padding: 'inherit' }}>
            <IconButton onClick={() => setAddLecture(false)}>
              <DeleteOutline />
            </IconButton>
          </div>
          <Button
            type="submit"
            sx={{ padding: '0px 8px' }}
            variant="outlined"
            color="secondary"
          >
            Create Lecture
          </Button>
        </Box>
      ) : !selectedTopic.id ? (
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Button
            type="button"
            variant="contained"
            onClick={() => setAddLecture(true)}
          >
            Add Lecture
          </Button>
        </Box>
      ) : (
        <Alert severity="info">
          Please minimize any open lecture of a chapter to add a new lecture.
        </Alert>
      )}
    </>
  );
}

export default EditCourseTopic;
