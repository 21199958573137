/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import { Clear, ExpandMore, Info } from '@mui/icons-material';
import {
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  AlertTitle,
  TextField,
  InputAdornment,
  IconButton,
  Tooltip,
  styled,
  tooltipClasses,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import ChangeVideoStatus from './ChangeVideoStatus';
import { groupBy, toUpper } from 'lodash';

import { TableVirtuoso } from 'react-virtuoso';
import ChangeVideoDuration from '../ChangeVideoDuration';
import { useDeleteVideoMutation } from '../../../../app/services/CoursesApi';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { useSnackbar } from 'notistack';
const columns = [
  {
    width: 20,
    label: '#',
  },
  {
    width: 120,
    label: 'Uploader Name',
  },
  {
    width: 200,
    label: 'Email',
  },
  {
    width: 120,
    label: 'Phone Number',
  },
  {
    width: 120,
    label: 'Created On',
  },
  {
    width: 120,
    label: 'Video Name',
  },
  {
    width: 200,
    label: 'Status',
  },
  {
    width: 120,
    label: 'Course',
  },
  {
    width: 120,
    label: 'Subject',
  },
  {
    width: 120,
    label: 'Topic',
  },
  {
    width: 120,
    label: 'Sub Topic',
  },
  {
    width: 120,
    label: 'View',
  },
  {
    width: 200,
    label: 'Update Duration',
  },
  {
    width: 120,
    label: 'Delete',
  },
];
const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    minWidth: 520,
  },
}));
const VirtuosoTableComponents = {
  Scroller: React.forwardRef((props, ref) => (
    <TableContainer component={Paper} {...props} ref={ref} />
  )),
  Table: (props) => (
    <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
  ),
  TableHead,
  TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
  TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};

function fixedHeaderContent() {
  return (
    <TableRow>
      {columns.map((column) => (
        <TableCell
          key={column.dataKey}
          variant="head"
          align={column.numeric || false ? 'right' : 'left'}
          style={{ width: column.width }}
          sx={{
            backgroundColor: 'background.paper',
          }}
        >
          {column.label}
        </TableCell>
      ))}
    </TableRow>
  );
}

function generateGroupedVideos(videos) {
  const groupedUsers = groupBy(videos, (video) => video.title[0]);
  const groupCounts = Object.values(groupedUsers).map((users) => users.length);
  const groups = Object.keys(groupedUsers);

  return { groupCounts, groups };
}

function VideoList(props) {
  const { videos } = props;

  const navigate = useNavigate();
  const virtuoso = useRef(null);
  const [deleteVideo, deletionResult] = useDeleteVideoMutation();

  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);

  const [selectedVideo, setSelectedVideo] = useState(false);

  const [inputValue, setInputValue] = useState('');
  const [filteredVideos, setFilteredVideos] = useState(videos ? videos : []);
  const { enqueueSnackbar } = useSnackbar();
  const viewVideo = (id) => {
    navigate(`/videos/${id}`);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const changeStatus = (e, video) => {
    e.stopPropagation();
    setSelectedVideo(video);
    setIsOpen(true);
  };

  const changeDuration = (e, video) => {
    e.stopPropagation();
    setSelectedVideo(video);
    setIsOpen1(true);
  };

  const handleClose1 = () => {
    setIsOpen1(false);
  };

  const { groups, groupCounts } = generateGroupedVideos(videos);

  const onDelete = (id) => {
    const res = deleteVideo({ id });
  };

  const onSearchClick = (inputSearchValue) => {
    if (inputSearchValue.trim().length === 0) {
      enqueueSnackbar('Value cannot be empty or contain only spaces', {
        variant: 'error',
      });

      return;
    } else {
      let finalFilterCourseVideo = videos.filter((elem) =>
        elem?.course?.title.toLowerCase().includes(inputSearchValue.toLowerCase())
      );
      setFilteredVideos([...finalFilterCourseVideo]);
    }
  };

  const onClearSearchClick = () => {
    setInputValue('');

    setFilteredVideos([...videos]);
  };
  return (
    <>
      <ul
        style={{
          marginLeft: '0.5rem',
          paddingLeft: '0',
          listStyle: 'none',
          fontSize: '0.8rem',
          display: 'flex',
        }}
      >
        {groupCounts
          .reduce(
            ({ firstItemsIndexes, offset }, count) => {
              return {
                firstItemsIndexes: [...firstItemsIndexes, offset],
                offset: offset + count,
              };
            },
            { firstItemsIndexes: [], offset: 0 }
          )
          .firstItemsIndexes.map((itemIndex, index) => (
            <li key={index}>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  virtuoso.current.scrollToIndex({
                    index: itemIndex,
                  });
                }}
              >
                {toUpper(groups[index])}
              </a>
              &nbsp;
            </li>
          ))}
      </ul>{' '}
      <div
        style={{
          display: 'flex',
          gap: '0.5rem',
          justifyContent: 'end',
          alignItems: 'center',
        }}
      >
        <TextField
          placeholder="Search videos by course name"
          required
          onChange={(_event) => {
            setInputValue(_event.target.value);
          }}
          value={inputValue}
          InputProps={{
            type: '',
            endAdornment: (
              <InputAdornment position="end">
                {inputValue && (
                  <IconButton
                    onClick={() => onClearSearchClick()}
                    // disabled={!inputValue.length}
                  >
                    <Clear />
                  </IconButton>
                )}
                <IconButton
                  onClick={() => onSearchClick(inputValue)}
                  // disabled={!inputValue.length}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          size="small"
          sx={{ marginRight: '0.5rem' }}
        />
        <HtmlTooltip
          title={
            <React.Fragment>
              <Typography
                component="div"
                variant="h5"
                sx={{ padding: 4, color: '#233c8d' }}
              >
                All uploaded videos
              </Typography>
              <Alert severity="info">
                <>
                  <AlertTitle>
                    All uploaded videos are visible here with their upload status
                    only to admin.
                  </AlertTitle>
                </>
              </Alert>
              <Alert severity="info">
                <>
                  <AlertTitle>Status:</AlertTitle>
                  <AlertTitle>
                    SUBMITTED - Video uploaded and submitted for transcoding.
                  </AlertTitle>
                  <AlertTitle>
                    PROCESSING - Video upload completed but is in the process of
                    transcoding.
                  </AlertTitle>
                  <AlertTitle>
                    COMPLETED - Video upload and transcoding completed but video only
                    visible to admin and uploader(Educator or Institution).
                  </AlertTitle>
                  <AlertTitle>
                    PUBLISHED - Video Visible to Enroled Student, uploader(Educator
                    or Institution) and admin.
                  </AlertTitle>
                  <AlertTitle>
                    BLOCKED - Video blocked for some reason(like wrong video for the
                    course or subtopic, inappropriate video etc). Video only visible
                    to admin and uploader(Educator or Institution).
                  </AlertTitle>
                </>
              </Alert>
              <Alert severity="info">
                <>
                  <AlertTitle>
                    Click on the video status button(displayed with current status)
                    under status column to change the respective video status.
                  </AlertTitle>
                </>
              </Alert>
            </React.Fragment>
          }
        >
          <Info sx={{ marginRight: '0.5rem' }} />
        </HtmlTooltip>
      </div>
      <Paper style={{ height: 400, width: '100%' }}>
        <TableVirtuoso
          data={filteredVideos}
          ref={virtuoso}
          components={VirtuosoTableComponents}
          fixedHeaderContent={fixedHeaderContent}
          itemContent={(index, singleVideo) => (
            <>
              <TableCell>{index + 1}</TableCell>
              <Tooltip title={singleVideo.creator.name}>
                <TableCell
                  sx={{
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    width: '100%',
                    padding: '8px',
                  }}
                >
                  {singleVideo.creator.name}
                </TableCell>
              </Tooltip>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip title={singleVideo.creator.email}>
                  {singleVideo.creator.email}
                </Tooltip>
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip title={singleVideo.creator.phone_number}>
                  {singleVideo.creator.phone_number}
                </Tooltip>
              </TableCell>
              <TableCell>
                {moment(singleVideo.created_at).format('Do MMM YY hh:mm A')}
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip title={singleVideo.title}>{singleVideo.title}</Tooltip>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={(e) => changeStatus(e, singleVideo)}
                >
                  {singleVideo.status}
                </Button>
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip
                  title={
                    <React.Fragment>
                      {singleVideo.course ? singleVideo.course.title : null}
                      {singleVideo.sub_topic
                        ? singleVideo.sub_topic.topic.subject.course.title
                        : null}
                      {singleVideo.subject ? singleVideo.subject.course.title : null}
                      {singleVideo.topic
                        ? singleVideo.topic.subject.course.title
                        : null}
                    </React.Fragment>
                  }
                >
                  {singleVideo.course ? singleVideo.course.title : null}
                  {singleVideo.sub_topic
                    ? singleVideo.sub_topic.topic.subject.course.title
                    : null}
                  {singleVideo.subject ? singleVideo.subject.course.title : null}
                  {singleVideo.topic ? singleVideo.topic.subject.course.title : null}
                </Tooltip>
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip
                  title={
                    <>
                      {singleVideo.sub_topic
                        ? singleVideo.sub_topic.topic.subject.title
                        : null}
                      {singleVideo.subject ? singleVideo.subject.title : null}
                      {singleVideo.topic ? singleVideo.topic.subject.title : null}
                    </>
                  }
                >
                  {singleVideo.sub_topic
                    ? singleVideo.sub_topic.topic.subject.title
                    : null}
                  {singleVideo.subject ? singleVideo.subject.title : null}
                  {singleVideo.topic ? singleVideo.topic.subject.title : null}
                </Tooltip>
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip
                  title={
                    <>
                      {singleVideo.sub_topic
                        ? singleVideo.sub_topic.topic.title
                        : null}
                      {singleVideo.topic ? singleVideo.topic.title : null}
                    </>
                  }
                >
                  {singleVideo.sub_topic ? singleVideo.sub_topic.topic.title : null}
                  {singleVideo.topic ? singleVideo.topic.title : null}
                </Tooltip>
              </TableCell>
              <TableCell
                sx={{
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  width: '100%',
                  padding: '8px',
                }}
              >
                <Tooltip
                  title={
                    <>{singleVideo.sub_topic ? singleVideo.sub_topic.title : null}</>
                  }
                >
                  {singleVideo.sub_topic ? singleVideo.sub_topic.title : null}
                </Tooltip>
              </TableCell>
              <TableCell>
                <Button
                  // sx={{ pl: 4 }}
                  onClick={() => viewVideo(singleVideo.id)}
                  disabled={singleVideo.status === 'PROCESSING'}
                  endIcon={<PlayCircleOutlineIcon />}
                  variant="contained"
                >
                  Play
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={(e) => changeDuration(e, singleVideo)}
                >
                  {singleVideo.duration
                    ? `${moment.utc(singleVideo.duration * 1000).format('HH:mm:ss')}`
                    : 'No duration set'}
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  // sx={{ pl: 4 }}
                  onClick={() => onDelete(singleVideo.id)}
                  endIcon={<DeleteIcon />}
                  variant="contained"
                >
                  Delete
                </Button>
              </TableCell>
            </>
          )}
        />
      </Paper>
      <>
        {selectedVideo ? (
          <Dialog open={isOpen} onClose={handleClose}>
            <DialogTitle> Change {selectedVideo.title} Status</DialogTitle>
            <DialogContent>
              <ChangeVideoStatus video={selectedVideo} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Close</Button>
            </DialogActions>
          </Dialog>
        ) : null}
        {selectedVideo && isOpen1 ? (
          <Dialog open={isOpen1} onClose={handleClose1}>
            <DialogTitle> Change {selectedVideo.title} Duration</DialogTitle>
            <DialogContent>
              <ChangeVideoDuration video={selectedVideo} />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose1}>Close</Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </>
    </>
  );
}

export default VideoList;
