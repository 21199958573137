/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  CssBaseline,
  Grid,
  Paper,
  Typography,
} from '@mui/material';
import EducatorRegistrationTabs from './EducatorRegistrationTabs';
import PasswordReset from '../PasswordReset';
import { useSelector } from 'react-redux';
import Copyright from '../../../components/Copyright';
import EducatorSignUpPage from './EducatorSignUpPage';
import EducatorSignInPage from './EducatorSignInPage';
import { useLocation } from 'react-router-dom';

function EducatorRegistrationPage() {
  const location = useLocation();
  const { forgotPassword } = useSelector((state) => state.auth);

  const [isLogin, setIsLogin] = useState(
    location?.state?.isLogin ? location?.state?.isLogin : false
  );
  return (
    <Grid container component="main" sx={{ height: '100vh' }}>
      <title>Account | {`${process.env.REACT_APP_NAME}`}</title>
      <CssBaseline />
      {!isLogin ? (
        <>
          {' '}
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            sx={{
              background: '#0f435f',
              color: '#fff',
            }}
          >
            <div
              style={{
                background: '#00687b',
                width: '100%',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              <Typography
                sx={{ fontSize: '24px', fontWeight: 700, textAlign: 'center' }}
              >
                Teacher Login
              </Typography>
            </div>
            {forgotPassword ? <PasswordReset /> : <EducatorSignInPage />}
            {!forgotPassword && (
              <Container maxWidth="xs" sx={{ mt: 3, borderRadius: '10px' }}>
                <Box sx={{ background: '#fff', borderRadius: '4px' }}>
                  <Typography
                    sx={{
                      color: '#000',
                      fontSize: '18px',
                      fontWeight: 600,
                      padding: '1rem',
                    }}
                  >
                    Don&apos;t have an account?
                    <Button
                      sx={{ display: 'block' }}
                      onClick={() => {
                        setIsLogin(true);
                      }}
                    >
                      Sign Up Here
                    </Button>
                  </Typography>
                </Box>
              </Container>
            )}
            <Copyright />
          </Grid>
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                'url(https://source.unsplash.com/random/featured/?nature)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light'
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </>
      ) : (
        <>
          <Grid
            item
            xs={12}
            sm={8}
            md={5}
            component={Paper}
            elevation={6}
            square
            sx={{
              background: '#0f435f',
              color: '#fff',
            }}
          >
            {' '}
            <div
              style={{
                background: '#00687b',
                width: '100%',
                paddingTop: '1rem',
                paddingBottom: '1rem',
              }}
            >
              <Typography
                sx={{ fontSize: '24px', fontWeight: 700, textAlign: 'center' }}
              >
                Teacher Registration Form
              </Typography>
            </div>
            {forgotPassword ? <PasswordReset /> : <EducatorSignUpPage />}
            <Container maxWidth="xs" sx={{ mt: 3, borderRadius: '10px' }}>
              <Box sx={{ background: '#fff', borderRadius: '4px' }}>
                <Typography
                  sx={{
                    color: '#000',
                    fontSize: '18px',
                    fontWeight: 600,
                    padding: '1rem',
                  }}
                >
                  Already have an account?
                  <Button
                    sx={{ display: 'block' }}
                    onClick={() => {
                      setIsLogin(false);
                    }}
                  >
                    Login here
                  </Button>
                </Typography>
              </Box>
            </Container>
            <Copyright />
          </Grid>
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              backgroundImage:
                'url(https://source.unsplash.com/random/featured/?nature)',
              backgroundRepeat: 'no-repeat',
              backgroundColor: (t) =>
                t.palette.mode === 'light'
                  ? t.palette.grey[50]
                  : t.palette.grey[900],
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
        </>
      )}
    </Grid>
  );
}

export default EducatorRegistrationPage;
