/* eslint-disable no-unused-vars */
import {
  ArrowDownward,
  Check,
  CheckCircleOutline,
  Close,
  CurrencyRupee,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  useCreateEducatorOnboardPaymentOrderIdMutation,
  useCreateEducatorOnboardWithPaymentMutation,
} from '../../../app/services/EducatorSubscriptionPlansApi';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
const RAZORPAKEY = process.env.REACT_APP_RAZORPAY_KEY;
export const StudentSubscriptionPlans = ({ selectedPaymentDuration }) => {
  const navigate = useNavigate();
  const { user, isSignedIn } = useSelector((state) => state.auth);
  const { enqueueSnackbar } = useSnackbar();
  let [totalCost, setTotalCost] = useState();
  const [createOnboardingId, res] = useCreateEducatorOnboardPaymentOrderIdMutation();

  const [createSubscriptionPlanPayment, result1] =
    useCreateEducatorOnboardWithPaymentMutation();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  function checkIfBoolean(value) {
    return typeof value === 'boolean';
  }
  const plan = {
    plans: [
      {
        name: 'Basic Plan',
        price: {
          annual_monthly: 450,
          annual: 5400,
          monthly: 500,
        },
        discount: {
          annual: 10,
        },
        deatils: `Ideal for new students
            testing the platform's
            capabilities.`,
      },
      {
        name: 'Premium Plan',
        price: {
          annual_monthly: 900,
          annual: 10800,
          monthly: 1000,
        },
        discount: {
          annual: 10,
        },
        deatils: `Ideal for new students
            testing the platform's
            capabilities.`,
      },
      {
        name: 'Unlimited Plan',
        price: {
          annual_monthly: 1350,
          annual: 16200,
          monthly: 1500,
        },
        discount: {
          annual: 10,
        },
        deatils: `Ideal for new students
            testing the platform's
            capabilities.`,
      },
    ],
    features: [
      {
        title: 'Core Feature',
        metaFeature: [
          {
            title: 'Access to Courses',
            basicPlan: 'Standard courses only',
            premiumPlan: 'All courses',
            unlimitedPlan: 'All courses',
          },
          {
            title: 'Video Resolution',
            basicPlan: 'Up to 720p',
            premiumPlan: 'Up to 720p',
            unlimitedPlan: 'Up to 4k',
          },
          {
            title: 'Course Downloads',
            basicPlan: false,
            premiumPlan: false,
            unlimitedPlan: false,
          },
          {
            title: 'Live Interactions',
            basicPlan: false,
            premiumPlan: false,
            unlimitedPlan: false,
          },
        ],
      },
      {
        title: 'Learning Tools',
        metaFeature: [
          {
            title: 'Interactive Quizzes',
            basicPlan: true,
            premiumPlan: true,
            unlimitedPlan: true,
          },
          {
            title: 'Offline Viewing',
            basicPlan: false,
            premiumPlan: false,
            unlimitedPlan: false,
          },
        ],
      },
      {
        title: 'Support and Community',
        metaFeature: [
          {
            title: 'Community Access',
            basicPlan: 'Limited',
            premiumPlan: 'Full access',
            unlimitedPlan: 'Full access',
          },
          {
            title: 'Personalized Course Recommendations',
            basicPlan: false,
            premiumPlan: true,
            unlimitedPlan: true,
          },
        ],
      },
      {
        title: 'Extras',
        metaFeature: [
          {
            title: 'Advanced Course Materials',
            basicPlan: false,
            premiumPlan: true,
            unlimitedPlan: true,
          },
          {
            title: 'Priority Enrollment in New Courses',
            basicPlan: false,
            premiumPlan: false,
            unlimitedPlan: true,
          },
          {
            title: 'Exclusive Webinars and Workshops',
            basicPlan: false,
            premiumPlan: false,
            unlimitedPlan: true,
          },
          {
            title: 'Dedicated Support',
            basicPlan: false,
            premiumPlan: 'Email support',
            unlimitedPlan: 'Email and chat support',
          },
          {
            title: 'Device Support',
            basicPlan: 'Any 1 Device',
            premiumPlan: 'Any 1 Device',
            unlimitedPlan: 'Any 1 Device',
          },
        ],
      },
    ],
  };

  const onDashboardClick = () => {
    navigate('/educator/dashboard');
  };
  const showModal = () => {
    setVisible(true);
  };
  const createOrderId = async () => {
    // createSubscriptionPlan(subscriptionPlanData);

    const createOrderIdRequest = await createOnboardingId({
      amount: totalCost,
      currency: 'INR',
    });

    return createOrderIdRequest.data.orderId;
  };

  const razorPayPoUp = (orderId) => {
    const options = {
      key: RAZORPAKEY, // Enter the Key ID generated from the Dashboard
      amount: totalCost * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: `${process.env.REACT_APP_NAME}`,
      description: 'Transaction',
      image: '{ logo }',
      order_id: orderId,
      handler: async (response) => {
        if (!response.razorpay_signature) {
          showModal();
        } else {
          setLoading(true);

          const subscriptionRes = await createSubscriptionPlanPayment({
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            order_id: orderId,
            amount: totalCost,
            email: user.email,
          });
          setSuccess(true);
        }
      },
      prefill: {
        name: user.name,
        email: user.email,
        contact: user.phone_number,
      },
      notes: {
        address: 'Razorpay Corporate Office',
      },
      theme: {
        color: '#a544a5',
      },
    };
    if (typeof Razorpay !== 'undefined') {
      // eslint-disable-next-line no-undef
      const rzp1 = new Razorpay(options);
      // eslint-disable-next-line func-names
      rzp1.on('payment.failed', function (response) {
        // eslint-disable-next-line no-console
        console.log(response, 'failed');
      });

      rzp1.open();
    }
  };
  const handleRazorPayPayment = async () => {
    const orderId = await createOrderId();
    razorPayPoUp(orderId);
  };

  if (success && result1?.data?.response?.id) {
    return (
      <>
        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
          Successfully Onboarded.
        </Alert>
        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
          Amount Paid: {result1?.data?.response?.paid_amount}
        </Alert>{' '}
        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
          Status: {result1?.data?.response?.status}
        </Alert>
        <Alert icon={<CheckCircleOutline fontSize="inherit" />} severity="success">
          Transaction Id: {result1?.data?.response?.transaction_id}
        </Alert>
        <Button variant="contained" color="secondary" onClick={onDashboardClick}>
          Go To Dashboard{' '}
        </Button>
      </>
    );
  }

  if (res?.isError) {
    enqueueSnackbar(res.error?.data?.message, {
      variant: 'error',
    });
  }
  return (
    <Fragment>
      <Helmet>
        <script src="https://checkout.razorpay.com/v1/checkout.js" />
      </Helmet>
      <Grid
        container
        spacing={2}
        sx={{ position: 'sticky', top: 50, zIndex: 1000, background: '#fff' }}
      >
        <Grid item xl={3} lg={3}></Grid>
        {plan?.plans.map((singlePlan) => (
          <Grid item xl={3} lg={3} key={singlePlan?.name}>
            <Box
              sx={{
                boxShadow: '1px 1px 10px 1px #dcdcdc',
                padding: '1rem',
                borderRadius: '20px',
                background: '#f2fdff',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Typography
                  sx={{
                    fontSize: '20px',
                    fontWeight: 700,
                    color: '#000',
                    textAlign: 'center',
                  }}
                >
                  {singlePlan?.name}
                </Typography>
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    color: '#000',
                    textAlign: 'center',
                  }}
                >
                  {singlePlan?.deatils}
                </Typography>
              </div>
              <div>
                <Typography
                  sx={{
                    fontSize: '18px',
                    fontWeight: 700,
                    color: '#000',
                    textAlign: 'center',
                    marginTop: '1rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CurrencyRupee />{' '}
                  {selectedPaymentDuration == 'Year'
                    ? singlePlan?.price?.annual_monthly
                    : singlePlan?.price?.monthly}
                </Typography>

                <Button
                  variant="contained"
                  sx={{
                    background: '#20b0c5',
                    width: '100%',
                    marginTop: '1rem',
                    ':hover': {
                      background: '#20b0c5',
                    },
                  }}
                  onClick={() => {
                    if (
                      selectedPaymentDuration === 'Year' &&
                      singlePlan?.price?.annual_monthly > 0
                    ) {
                      totalCost = singlePlan?.price?.annual_monthly;
                      setTotalCost(totalCost);
                      isSignedIn
                        ? handleRazorPayPayment()
                        : navigate('/educator-account', {
                            state: location.pathname,
                          });
                    } else if (
                      selectedPaymentDuration === 'Month' &&
                      singlePlan?.price?.monthly > 0
                    ) {
                      totalCost = singlePlan?.price?.monthly;
                      setTotalCost(totalCost);
                      isSignedIn
                        ? handleRazorPayPayment()
                        : navigate('/educator-account', {
                            state: location.pathname,
                          });
                    }
                  }}
                >
                  Get Sarted
                </Button>
              </div>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid container spacing={2} sx={{ marginTop: '1rem' }}>
        {Array.isArray(plan?.features) &&
          plan?.features.length > 0 &&
          plan?.features.map((singleFeature) => (
            <Accordion
              key={singleFeature?.title}
              sx={{ width: '100%' }}
              expanded={true}
            >
              <AccordionSummary
                expandIcon={<ArrowDownward />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography sx={{ fontSize: '20px', fontWeight: 700 }}>
                  {singleFeature?.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: 0 }}>
                {Array.isArray(singleFeature.metaFeature) &&
                  singleFeature.metaFeature.length > 0 &&
                  singleFeature.metaFeature.map((singleMetaFeature) => (
                    <Grid container key={singleMetaFeature?.title}>
                      <Grid item xl={3} lg={3}>
                        <Typography
                          sx={{
                            textAlign: 'start',
                            marginLeft: '1rem',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                          }}
                          title={singleMetaFeature?.title}
                        >
                          {singleMetaFeature?.title}
                        </Typography>
                      </Grid>
                      <Grid item xl={3} lg={3}>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            background: '#f2fdff',
                            marginLeft: '1rem',
                            lineHeight: '30px',
                          }}
                        >
                          {checkIfBoolean(singleMetaFeature?.basicPlan) ? (
                            <span>
                              {singleMetaFeature?.basicPlan === true ? (
                                <Check />
                              ) : (
                                <Close />
                              )}
                            </span>
                          ) : (
                            singleMetaFeature?.basicPlan
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xl={3} lg={3}>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            background: '#f2fdff',
                            marginLeft: '1rem',
                            lineHeight: '30px',
                          }}
                        >
                          {checkIfBoolean(singleMetaFeature?.premiumPlan) ? (
                            <span>
                              {singleMetaFeature?.premiumPlan === true ? (
                                <Check />
                              ) : (
                                <Close />
                              )}
                            </span>
                          ) : (
                            singleMetaFeature?.premiumPlan
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xl={3} lg={3}>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            background: '#f2fdff',
                            marginLeft: '1rem',
                            lineHeight: '30px',
                          }}
                        >
                          {checkIfBoolean(singleMetaFeature?.unlimitedPlan) ? (
                            <span>
                              {singleMetaFeature?.unlimitedPlan === true ? (
                                <Check />
                              ) : (
                                <Close />
                              )}
                            </span>
                          ) : (
                            singleMetaFeature?.unlimitedPlan
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  ))}
              </AccordionDetails>
            </Accordion>
          ))}
      </Grid>
    </Fragment>
  );
};
