import { Box, Grid, Typography } from '@mui/material';
import React, { Fragment } from 'react';

export const RefundPolicy = () => {
  const pageData = [
    {
      title: 'Course Purchase Refunds',
      list: [
        {
          title: '',
          data: `Refunds for purchased courses are available within 3 days of purchase, provided that
          less than 10% of the course has been completed`,
        },
        {
          title: '',
          data: `To request a refund, contact us at support@gurumavin.in with your order details`,
        },
      ],
    },
    {
      title: 'Subscription Refunds',
      list: [
        {
          title: '',
          data: `Subscription fees are non-refundable. You can cancel your subscription at any time, and
          your access will continue until the end of the billing period`,
        },
      ],
    },
    {
      title: 'Exceptions',
      list: [
        {
          title: '',
          data: `Refunds will not be granted if we determine that the request is fraudulent or violates our
          Terms & Conditions`,
        },
      ],
    },
    {
      title: 'Contact Information',
      list: [
        {
          title: '',
          data: `For any questions about this Refund Policy, please contact us at
          support@gurumavin.in`,
        },
      ],
    },
  ];
  return (
    <Fragment>
      <Grid container justifyContent={'center'}>
        <Grid item xl={10} lg={10} md={11} sm={11}>
          <Typography
            sx={{
              color: '#000',
              fontSize: '24px',
              fontWeight: 700,
              textAlign: 'center',
            }}
          >
            Refund Policy
          </Typography>
          <Box sx={{ marginTop: '1rem' }}>
            <Typography
              sx={{
                color: '#000',
                fontSize: '18px',
                fontWeight: 600,
              }}
            >
              Introduction
            </Typography>
            <Typography
              sx={{
                color: '#000',
                fontSize: '16px',
                fontWeight: 500,
                marginTop: '0.5rem',
              }}
            >
              We strive to provide the best learning experience. If you are not
              satisfied with your purchase, please review our refund policy.
            </Typography>
          </Box>
          {pageData.map((singleData, ind) => (
            <Box sx={{ marginTop: '1.5rem' }} key={ind + singleData?.title}>
              <Typography
                sx={{
                  color: '#000',
                  fontSize: '18px',
                  fontWeight: 600,
                }}
              >
                {singleData?.title}
              </Typography>
              {Array.isArray(singleData?.list) &&
                singleData?.list.map((singleList, singleIndex) => (
                  <li
                    style={{
                      color: '#000',
                      fontSize: '16px',
                      fontWeight: 500,
                      marginTop: '0.5rem',
                      marginLeft: '1rem',
                    }}
                    key={singleIndex + ' list'}
                  >
                    {singleList?.title ? (
                      <span
                        style={{ fontWeight: 700 }}
                      >{`${singleList?.title} : `}</span>
                    ) : null}
                    {`${singleList?.data} .`}
                  </li>
                ))}
            </Box>
          ))}
        </Grid>
      </Grid>
    </Fragment>
  );
};
